const initState = {
    fetching: false,
    fetched: false,
    error: null,
    payload: {},
    saved: false,
    message: null,
    kanban: {},
    isDeleted: false,
};

const kanbanADMExportReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SAVE_KANBANADMEXPORT_PENDING':
            return {
                ...state,
                fetching: true,
                saved: false
            };
        case 'SAVE_KANBANADMEXPORT_REJECTED':
            if (action.payload.response.status === 401) {
                localStorage.removeItem('token');
            }

            return {
                ...state,
                error: action.payload.response,
                fetching: false,
                fetched: false,
                saved: false
            };
        case 'SAVE_KANBANADMEXPORT_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                message: action.payload.data.message,
                error: null,
                saved: true
            };
        default:
            return state
    }
}

export default kanbanADMExportReducer 