import Axios from "axios";
import { url } from "../../global";

const saveKanbanHPM = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SAVE_KANBANHPM',
            payload: Axios.post(`${url}/kanban-hpm`, {
                rack_id: data.rack_id,
                rack_name: data.rack_name,
                parts: data.parts
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

export { saveKanbanHPM }