const initState = {
	fetching: false,
	fetched: false,
	error: null,
	payload: {},
	saved: false,
	message: null,
	qrcode: {},
	isDeleted: false,
};

const qrcodeProductionReducer = (state = initState, action) => {
	switch (action.type) {
		case 'QRCODE_PRODUCTION_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'QRCODE_PRODUCTION_REJECTED':

			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}

			return {
				...state,
				error: action.payload.response,
				fetching: false
			};
		case 'QRCODE_PRODUCTION_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				payload: action.payload.data,
				error: null,
				saved: false,
				isDeleted: false
			};
		case 'SAVE_QRCODE_PRODUCTION_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'SAVE_QRCODE_PRODUCTION_REJECTED':
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}

			return {
				...state,
				error: action.payload.response,
				fetching: false,
				fetched: false
			};
		case 'SAVE_QRCODE_PRODUCTION_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				message: action.payload.data.message,
				error: null,
				saved: true
			};
		case 'GET_QRCODE_PRODUCTION_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'GET_QRCODE_PRODUCTION_REJECTED':
			console.log("payload ", action.payload);
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}

			return {
				...state,
				error: action.payload.response,
				fetching: false
			};
		case 'GET_QRCODE_PRODUCTION_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				qrcode: action.payload.data,
				error: null,
				saved: false,
			};
		case 'UPDATE_QRCODE_PRODUCTION_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'UPDATE_QRCODE_PRODUCTION_REJECTED':
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}

			return {
				...state,
				error: action.payload.response,
				fetching: false,
				fetched: false
			};
		case 'UPDATE_QRCODE_PRODUCTION_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				message: action.payload.data.message,
				error: null,
				saved: true
			};
		case 'DELETE_QRCODE_PRODUCTION_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'DELETE_QRCODE_PRODUCTION_REJECTED':
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}

			return {
				...state,
				error: action.payload.response,
				fetching: false
			};
		case 'DELETE_QRCODE_PRODUCTION_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				message: action.payload.data.message,
				error: null,
				isDeleted: true
			};
		case 'SET_PAYLOAD_PRODUCTION':
			return {
				...state,
				payload: action.payload
			}
		case 'GET_FILTER_DATA_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'GET_FILTER_DATA_REJECTED':

			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}

			return {
				...state,
				error: action.payload.response,
				fetching: false
			};
		case 'GET_FILTER_DATA_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				filters: action.payload.data.data,
				error: null,
				saved: false,
				isDeleted: false
			};

		default:
			return state;
	}
};

export default qrcodeProductionReducer;
