const initialState = {
  error: null,
  data: null,
  label: "",
  labelProduct: null,
  labelProductData: {
    part_number: "",
    part_name: "",
    machine_id: "",
    machine_name: "",
    dies_id: "",
    dies_name: "",
    customer_name: "",
    customer_id: "",
    part_type: "",
    part_model: "",
    maker_id: "",
    maker_name: "",
    thickness: "",
    shift_id: "",
    shift_name: "",
    qty: "",
    lot_id: "",
    inspec_date: "",
    prod_date: "",
    copies: "",
  },
};

const labelProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SCAN_LABEL_PRODUCT":
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case "SCAN_LABEL_PRODUCT_FAILED":
      return {
        ...state,
        error: action.payload,
      };
    case "CHANGE_LABEL_PRODUCT":
      return {
        ...state,
        label: action.payload,
      };
    case "FETCH_LABEL_PRODUCT":
      return {
        ...state,
        labelProduct: action.payload,
      };
    case "GET_LABEL_PRODUCT":
      return {
        ...state,
        labelProductData: action.payload,
      };
    case "CLEAR_LOT":
      return {
        labelProductData: {
          part_number: "",
          part_name: "",
          machine_id: "",
          machine_name: "",
          dies_id: "",
          dies_name: "",
          customer_name: "",
          customer_id: "",
          part_type: "",
          part_model: "",
          maker_id: "",
          maker_name: "",
          thickness: "",
          shift_id: "",
          shift_name: "",
          qty: "",
          lot_id: "",
          inspec_date: "",
          prod_date: "",
          copies: "",
        },
      };
    default:
      return state;
  }
};

export default labelProductReducer;
