import Axios from "axios";
import { url } from "../../global";
import moment from "moment";

const fetchDn = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'DN',
            payload: Axios.get(`${url}/delivery-note`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering,
                    printed: filter.printed,
                    filter: filter.filter
                    // startDate: filter.filter.startDate,
                    // endDate: filter.filter.endDate,
                    // shop: filter.filter.shop,
                    // remark: filter.filter.scanned,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const getDn = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_DN',
            payload: Axios.get(`${url}/delivery-note/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const updateDn = (id, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_DN',
            payload: Axios.put(`${url}/delivery-note/${id}`, {
                part_number: data.part_number,
                description: data.description,
                supplier_id: data.supplier_id,
                supplier_name: data.supplier_name,
                qty: data.qty,
                lot_id: data.lot_id,
                type_part: data.type_part,
                coil_id: data.coil_id,
                plant_id: data.plant_id,
                packing_date: moment(data.packing_date).format('YYYY-MM-DD'),
                copies: data.copies
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}


const deleteDn = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_DN',
            payload: Axios.delete(`${url}/delivery-note/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchDn, getDn, updateDn, deleteDn };