import Axios from "axios";
import { url } from "../../global";

const fetchMenu = () => {
    return (dispatch) => {
        dispatch({
            type: 'MENU',
            payload: Axios.get(`${url}/menu`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const saveMenu = (data) => {
    return (dispatch) => {
        dispatch({
            type: 'SAVE_MENU',
            payload: Axios.post(`${url}/menu`, {
                title: data.title,
                parent_id: data.parent_id,
                permission: data.permission,
                url: data.url,
                icon: data.icon,
                order_number: data.order_number
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const getMenu = (id) => {
    return (dispatch) => {
        dispatch({
            type: 'GET_MENU',
            payload: Axios.get(`${url}/menu/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const updateMenu = (id, data) => {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_MENU',
            payload: Axios.put(`${url}/menu/${id}`, {
                title: data.title,
                parent_id: data.parent_id,
                permission: data.permission,
                url: data.url,
                icon: data.icon,
                order_number: data.order_number
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const deleteMenu = (id) => {
    return (dispatch) => {
        dispatch({
            type: 'DELETE_MENU',
            payload: Axios.delete(`${url}/menu/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchMenu, saveMenu, getMenu, updateMenu, deleteMenu }