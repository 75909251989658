import Axios from "axios";
import { url } from "../../global";

const fetchPart = (filter) => {
  return (dispatch, getState) => {
    dispatch({
      type: "PART",
      payload: Axios.get(`${url}/part`, {
        params: {
          page: filter.page,
          perpage: filter.perpage,
          keyword: filter.keyword,
          ordering: filter.ordering,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    });
  };
};

const savePart = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SAVE_PART",
      payload: Axios.post(
        `${url}/part`,
        {
          code: data.code,
          name: data.name,
          stock: data.stock,
          model: data.model,
          type: data.type,
          job_number: data.job_number,
          marking_id: data.marking_id,
          position: data.position,
          std_packing: data.std_packing,
          id_sap: data.id_sap,
          customer_id: data.customer_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      ),
    });
  };
};

const getPart = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: "GET_PART",
      payload: Axios.get(`${url}/part/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    });
  };
};

const updatePart = (id, data) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_PART",
      payload: Axios.put(
        `${url}/part/${id}`,
        {
          code: data.code,
          name: data.name,
          stock: data.stock,
          type: data.type,
          model: data.model,
          job_number: data.job_number,
          marking_id: data.marking_id,
          position: data.position,
          std_packing: data.std_packing,
          id_sap: data.id_sap,
          customer_id: data.customer_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      ),
    });
  };
};

const deletePart = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: "DELETE_PART",
      payload: Axios.delete(`${url}/part/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    });
  };
};

export { fetchPart, savePart, getPart, updatePart, deletePart };
