import React, { Fragment, createRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  Button,
  ModalBody,
  ModalFooter,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { appName, url } from "../global";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import Error403 from "./Error403";
import Axios from "axios";
import { connect } from "react-redux";
import QrReader from "react-qr-reader";
import ScrollToTop from "../components/layout/ScrollToTop";
import Table from "../components/table/Table";
import { saveKanbanADMExport } from "../store/actions/kanbanAdmExportActions";

// sound
import scanKanbanOk from "../sounds/scanKanbanOk.mp3";
import doubleScanKanban from "../sounds/doubleScanKanban.mp3";
import labelTdkCocok from "../sounds/labelTdkCocok.mp3";
import kanbanDnClose from "../sounds/kanbanDnClose.mp3";
import dataSave from "../sounds/dataSave.mp3";
import kanbanDNTidakDitemukan from "../sounds/kanbanDNTidakDitemukan.mp3";
class KanbanADMExportProd extends React.Component {
  state = {
    rack: "",
    kanban: "",
    dn_qrcode: "",
    kanban_dn: "",
    disabled: true,
    kanban_dn_disable: true,
    error: null,
    rack_id: "",
    parts: [],
    qrmodal: false,
    qrmodalrack: false,
    qrmodaldn: false,
    isAudioPlayed: false,
    isAdmin: localStorage.getItem("openLock") === "true",
    isKanbanLock: localStorage.getItem("isKanbanADMExportProdLock"),
    kanbanLockData: [],
    hasLockPage: false,
    lockpageOrdering: {
      type: "locked_at",
      sort: "desc",
    },
    password: "",
    passwordError: "",
    module_qrcode: "",
    module_locked: false,
    module_modal: false,
    module: "",
    module_data: [],
    module_detail_open: false,
    module_detail_show: false,
  };

  source = Axios.CancelToken.source();

  btnSaveRef = createRef();

  handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "dn_qrcode") {
      if (value.length > 70) {
        e.target.value = value.slice(0, 70);
      }
    }

    this.setState({
      ...this.state,
      [id]: e.target.value,
    });
  };

  handleRemovePart = (index) => {
    let parts = this.state.parts;
    parts.splice(index, 1);

    this.setState({
      ...this.state,
      parts,
    });
  };

  handleKeyDownKanban = (e) => {
    if (e.key === "Enter") {
      this.checkPart(e.target.value);
    }
  };

  handleSave = () => {
    this.props.saveKanban(this.state);
  };

  handleReset = () => {
    this.setState({
      rack: "",
      kanban: "",
      kanban_dn: "",
      dn_qrcode: "",
      disabled: true,
      kanban_dn_disable: false,
      error: null,
      rack_id: "",
      parts: [],
    });
  };

  handleScanPopup = () => {
    this.setState({
      ...this.state,
      qrmodal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      ...this.state,
      qrmodal: false,
      module_modal: false,
      module_detail_open: false,
    });
  };

  handleScan = (data) => {
    if (data) {
      setTimeout(() => {
        this.checkPart(data);
        this.setState({
          qrmodal: false,
        });
      }, 1000);
    }
  };

  handleError = (err) => {
    console.error(err);
  };

  handleScanPopupRack = () => {
    this.setState({
      ...this.state,
      qrmodalrack: true,
    });
  };

  handleCloseModalRack = () => {
    this.setState({
      ...this.state,
      qrmodalrack: false,
    });
  };

  handleScanRack = (data) => {
    if (data) {
      setTimeout(() => {
        this.checkRack(data);
      }, 1000);

      this.setState({
        rack: data,
        qrmodalrack: false,
      });
    }
  };

  handleKeyDownModule = (e) => {
    if (e.key === "Enter") {
      this.setState({
        ...this.state,
        module_locked: true,
        kanban_dn_disable: false,
      });
      this.checkModule(true);
    }
  };

  handleScanPopupModule = () => {
    this.setState({
      ...this.state,
      module_modal: true,
    });
  };

  handleCloseModalModule = () => {
    this.setState({
      ...this.state,
      module_modal: false,
    });
  };

  handleScanModule = (data) => {
    if (data) {
      this.setState({
        ...this.state,
        module_locked: true,
      });
      this.checkModule(true);
    }
  };

  handleErrorModule = (err) => {
    console.error(err);
  };

  handleOpenModule = () => {
    this.setState({
      ...this.state,
      module_locked: false,
      module_qrcode: "",
      dn_qrcode: "",
      module: "",
      module_detail_show: false,
      kanban: "",
      error: null,
      kanban_dn_disable: true,
      disabled: true,
    });
    this.moduleQrInput.focus();
  };

  handleKeyDownDn = (e) => {
    if (e.key === "Enter") {
      this.checkDn(e.target.value);
    }
  };

  handleScanPopupDn = () => {
    this.setState({
      ...this.state,
      qrmodaldn: true,
    });
  };

  handleCloseModalDn = () => {
    this.setState({
      ...this.state,
      qrmodaldn: false,
    });
  };

  handleScanDn = (data) => {
    if (data) {
      this.checkDn(data);
    }
  };

  handleErrorDn = (err) => {
    console.error(err);
  };

  audioPlayer = (audio) => {
    new Audio(audio).play();
  };

  checkModule = (module_locked) => {
    Axios.post(
      `${url}/kanban-adm-export-prod/check-module`,
      {
        module_qrcode: this.state.module_qrcode,
        module_locked: module_locked,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        this.setState({
          ...this.state,
          qrmodaldn: false,
          kanban_dn_disable: false,
          error: null,
          module: res.data.data.module,
        });
        this.kanbanDnInput.focus();
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          error: error.response,
          module_locked: false,
          qr_dn: "",
          kanban_dn: "",
          kanban_dn_disable: true,
        });

        this.checkPage();
      });
  };

  checkDn = (id) => {
    Axios.post(
      `${url}/kanban-adm-export-prod/check-dn`,
      {
        dn: id,
        module: this.state.module_qrcode,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        this.audioPlayer(scanKanbanOk);
        this.setState({
          qrmodaldn: false,
          kanban_dn_disable: true,
          error: null,
          disabled: false,
          kanban_dn: res.data.data.dn_number,
        });
        this.kanbanInput.focus();
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          error: error.response,
          qr_dn: "",
          kanban_dn: "",
        });

        this.checkPage();

        if (
          this.state.error &&
          this.state.error.data.errors.kanban_dn[0] ===
            "Delivery Note already inputed!"
        ) {
          this.audioPlayer(doubleScanKanban);
        }
        if (
          this.state.error &&
          this.state.error.data.errors.kanban_dn[0] ===
            "Delivery note not available"
        ) {
          this.audioPlayer(kanbanDNTidakDitemukan);
        }
        if (
          this.state.error &&
          this.state.error.data.errors.kanban_dn[0] === "Kanban Closed!"
        ) {
          this.audioPlayer(kanbanDnClose);
        }
      });
  };

  checkPart = (kanban) => {
    Axios.post(
      `${url}/kanban-adm-export-prod/part`,
      {
        kanban,
        dn_qrcode: this.state.dn_qrcode,
        dn: this.state.kanban_dn,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        let parts = [];
        parts.push(res.data.data);

        this.setState({
          error: null,
          parts: parts,
          qr_dn: "",
          kanban: "",
        });

        this.btnSaveRef.current.click();
        this.kanbanDnInput.focus();
      })
      .catch((err) => {
        this.audioPlayer(labelTdkCocok);
        this.setState({
          ...this.state,
          error: err.response,
          kanban: "",
          disabled: false,
        });
        this.checkPage();
      });
  };

  checkPage = () => {
    Axios.get(`${url}/kanban-adm-export-prod/check-page`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const hasLockPage = res.data.length > 0 ? true : false;
        this.setState({
          ...this.state,
          isKanbanLock: this.state.isAdmin && hasLockPage ? false : true,
          kanbanLockData: {
            data: res.data,
            isModalOpen: hasLockPage,
          },
          hasLockPage: hasLockPage,
        });
        localStorage.setItem(
          "isKanbanADMExportProdLock",
          !this.state.isAdmin && hasLockPage ? res.data[0].lock : false
        );
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Request failed", error);
        }
      });
  };

  handlePasswordInput = (e) => {
    if (e.key === "Enter") {
      this.openPage(this.state.kanbanLockData.data[0].id);
    }
  };

  openPage = (id) => {
    Axios.post(
      `${url}/kanban-adm-export-prod/open-page/${
        this.state.isAdmin ? id : this.state.kanbanLockData.data[0].id
      }`,
      {
        password: this.state.password,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        const hasLockPage = res.data.data.length > 0 ? true : false;
        this.setState({
          ...this.state,
          isKanbanLock: hasLockPage,
          kanbanLockData: {
            data: this.state.kanbanLockData.data.filter(
              (item) => item.id !== id
            ),
            isModalOpen: hasLockPage,
          },
          hasLockPage: hasLockPage,
          passwordError: "",
          error: null,
        });
        localStorage.setItem(
          "isKanbanADMExportProdLock",
          !this.state.isAdmin && hasLockPage ? res.data[0].lock : false
        );
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          passwordError: error.response.data.message,
        });
        if (!this.state.isAdmin) {
          this.passwordRef.focus();
        }
      });
  };

  openPageAll = () => {
    Axios.put(
      `${url}/kanban-adm-export-prod/open-all`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        this.checkPage();
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  handleOpenPage = () => {
    if (this.state.isAdmin) {
      this.openPageAll();
    } else {
      this.openPage(this.state.kanbanLockData.data[0].id);
    }
  };

  handleCloseModalLockPage = () => {
    this.setState({
      ...this.state,
      kanbanLockData: {
        ...this.state.kanbanLockData,
        isModalOpen: !this.state.kanbanLockData.isModalOpen,
      },
    });
  };

  handleSorting = (e) => {
    const type = e.target.id;
    const sort = this.state.lockpageOrdering.sort;
    this.setState({
      ...this.state,
      lockpageOrdering: {
        type: type,
        sort: sort === "asc" ? "desc" : "asc",
      },
    });
  };

  handleOpenModuleDetail = () => {
    Axios.get(
      `${url}/kanban-adm-export-prod/${this.state.module_qrcode
        .split("#")[0]
        .slice(0, -3)}/detail`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        this.setState({
          ...this.state,
          module_data: res.data.data,
          module_detail_open: true,
        });
      })
      .catch((error) => {
        const { toastManager } = this.props;
        // toastManager.add(error.response.data.message, {
        //   appearance: "danger",
        //   autoDismiss: true,
        // });
      });
  };

  componentDidMount = () => {
    this.checkPage();
  };

  componentWillUnmount = () => {
    this.source.cancel();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.fetching !== this.props.fetching &&
      prevProps.saved !== this.props.saved
    ) {
      if (this.props.message === "Kanban Closed!") {
        this.audioPlayer(kanbanDnClose);
      } else {
        this.audioPlayer(dataSave);
      }
      if (this.props.saved) {
        const { toastManager } = this.props;
        toastManager.add(this.props.message, {
          appearance: "success",
          autoDismiss: true,
        });

        this.handleReset();
      }
    }
  };

  render() {
    const { fetching } = this.props;
    const {
      kanban,
      kanban_dn,
      dn_qrcode,
      disabled,
      kanban_dn_disable,
      error,
      parts,
      qrmodal,
      qrmodalrack,
      qrmodaldn,
      kanbanLockData,
      isAdmin,
      passwordError,
      isKanbanLock,
      hasLockPage,
      lockpageOrdering,
      module_qrcode,
      module_locked,
      module_modal,
      module,
      module_data,
      module_detail_open,
    } = this.state;

    const theads = [
      { name: "locked_by", value: `Locked By`, sortable: true },
      { name: "locked_at", value: "Time", sortable: true },
      { name: "message", value: "Reason", sortable: false },
      { name: "password", value: "Password", sortable: false },
      { name: "acton", value: "", sortable: false },
    ];

    const moduleHeads = [
      { name: "part_number", value: `Part Number`, sortable: false },
      { name: "part_name", value: `Part Name`, sortable: false },
      { name: "qty_order", value: `Qty Order`, sortable: false },
      { name: "total_kanban", value: `Total Kanban`, sortable: false },
      {
        name: "qty_order_scanned",
        value: `Total Qty Scanned`,
        sortable: false,
      },
      {
        name: "total_kanban_scanned",
        value: `Total Kanban Scanned`,
        sortable: false,
      },
      { name: "status", value: `Status`, sortable: false },
    ];

    const lockTable =
      hasLockPage &&
      kanbanLockData.data.map((data, index) => {
        return (
          <tr key={index}>
            <td>{data.locked_by}</td>
            <td>{data.locked_at}</td>
            <td>{data.message}</td>
            <td>{data.password}</td>
            <td>
              <Button onClick={() => this.openPage(data.id)}>Open</Button>
            </td>
          </tr>
        );
      });

    if (!localStorage.getItem("token")) return <Redirect to="/login" />;
    if (error && error.status === 500)
      return <Error500 message={error.data.message} />;
    if (error && error.status === 403)
      return <Error403 message={error.data.message} />;
    return (
      <Container fluid className="main-content-container px-4">
        <Loading show={fetching} color="blue" showSpinner={false} />
        <Helmet>
          <title>Scan Kanban ADM Export Production | {appName} </title>
        </Helmet>
        <Row noGutters className="page-header py-4">
          <div className="col-md-12">
            <PageTitle
              sm="4"
              title="Scan Kanban ADM Export Production"
              className="text-sm-left"
            />
          </div>
        </Row>
        <Row>
          {(isKanbanLock || kanbanLockData.isModalOpen) && (
            <Modal
              open={kanbanLockData.isModalOpen}
              toggle={this.handleCloseModalLockPage}
              size={isAdmin ? "lg" : "md"}
            >
              <ModalHeader>Page Lock!</ModalHeader>
              <ModalBody>
                {!isAdmin ? (
                  <>
                    <p>
                      Halaman ini dikunci. Hubungi admin untuk membuka kunci.
                    </p>
                    <div className="form-group">
                      <label className="control-label">
                        {isAdmin ? "Ganti Password" : "Masukkan Password"}
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="password"
                          id="password"
                          ref={(input) => (this.passwordRef = input)}
                          className={`form-control form-control-lg ${passwordError &&
                            "is-invalid"}`}
                          onKeyDown={this.handlePasswordInput}
                          onChange={this.handleChange}
                        />
                        {passwordError && (
                          <div className="invalid-feedback">
                            {passwordError}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Table
                      theads={theads}
                      ordering={lockpageOrdering}
                      handleSorting={this.handleSorting}
                    >
                      {lockTable}
                    </Table>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button theme="primary" onClick={() => this.handleOpenPage()}>
                  {isAdmin ? "Open All" : "Open"}
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {qrmodal && (
            <ScrollToTop>
              <div className="messagebox msgbox-qr">
                <div className="modal-qr">
                  <button
                    onClick={this.handleCloseModal}
                    className="btn btn-default btn-cicle btn-lg p-0 pull-right"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <QrReader
                    delay={500}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: "100%" }}
                    // facingMode={cameramode}
                  />
                </div>
              </div>
              <div className="backdrop"></div>
            </ScrollToTop>
          )}

          {qrmodalrack && (
            <ScrollToTop>
              <div className="messagebox msgbox-qr">
                <div className="modal-qr">
                  <button
                    onClick={this.handleCloseModalRack}
                    className="btn btn-default btn-cicle btn-lg p-0 pull-right"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <QrReader
                    delay={300}
                    onError={this.handleErrorRack}
                    onScan={this.handleScanRack}
                    style={{ width: "100%" }}
                    // facingMode={cameramode}
                  />
                </div>
              </div>
              <div className="backdrop"></div>
            </ScrollToTop>
          )}

          {module_modal && (
            <ScrollToTop>
              <div className="messagebox msgbox-qr">
                <div className="modal-qr">
                  <button
                    onClick={this.handleCloseModalModule}
                    className="btn btn-default btn-cicle btn-lg p-0 pull-right"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <QrReader
                    delay={300}
                    onError={this.handleErrorModule}
                    onScan={this.handleScanModule}
                    style={{ width: "100%" }}
                    // facingMode={cameramode}
                  />
                </div>
              </div>
              <div className="backdrop"></div>
            </ScrollToTop>
          )}

          {module_detail_open && (
            <ScrollToTop>
              <div className="messagebox msgbox-module-details">
                <div className="modal-qr">
                  <div className="modal-head">
                    <h4>Module Detail</h4>
                    <button
                      onClick={this.handleCloseModal}
                      className="btn btn-default btn-cicle btn-lg p-0 pull-right"
                    >
                      <i className="mdi mdi-close"></i>
                    </button>
                  </div>
                  <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
                    {module_data && (
                      <Fragment>
                        <table className="table table-bordered">
                          <tr>
                            <th>Module</th>
                            <th>Customer</th>
                            <th>Qty Order</th>
                            <th>Total Kanban</th>
                            <th>Qty Order Scanned</th>
                            <th>Kanban Scanned</th>
                            <th>Status</th>
                          </tr>
                          <tr>
                            <td>
                              {module_data.module}

                              <p className="text-center">
                                <button
                                  className="btn btn-link btn-sm text-info"
                                  type="button"
                                  onClick={() =>
                                    this.setState({
                                      ...this.state,
                                      module_detail_show: !this.state
                                        .module_detail_show,
                                    })
                                  }
                                >
                                  <i
                                    className={`mdi ${
                                      this.state.module_detail_show
                                        ? "mdi-chevron-down"
                                        : "mdi-chevron-right"
                                    } mr-2`}
                                  ></i>
                                  Show Details
                                </button>
                              </p>
                            </td>
                            <td>{module_data.customer}</td>
                            <td>{module_data.qty_order}</td>
                            <td>{module_data.total_kanban}</td>
                            <td>{module_data.qty_scanned}</td>
                            <td>{module_data.kanban_scanned}</td>
                            <td
                              className={`text-center text-white ${
                                module_data.status === "Open"
                                  ? "bg-danger"
                                  : "bg-success"
                              }`}
                            >
                              {module_data.status}
                            </td>
                          </tr>
                          {this.state.module_detail_show && (
                            <tr>
                              <td colSpan={7}>
                                <Table theads={moduleHeads}>
                                  {module_data.details ? (
                                    module_data.details.map((mod) => {
                                      return (
                                        <tr key={mod.part_number}>
                                          <td>{mod.part_number}</td>
                                          <td>{mod.part_name}</td>
                                          <td>{mod.qty_order}</td>
                                          <td>{mod.total_kanban}</td>
                                          <td>{mod.qty_order_scanned}</td>
                                          <td>{mod.total_kanban_scanned}</td>
                                          <td
                                            className={`text-center text-white ${
                                              mod.status === "Open"
                                                ? "bg-danger"
                                                : "bg-success"
                                            }`}
                                          >
                                            {mod.status}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr colSpan={moduleHeads.length}>
                                      No data
                                    </tr>
                                  )}
                                </Table>
                              </td>
                            </tr>
                          )}
                        </table>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
              <div className="backdrop" onClick={this.handleCloseModal}></div>
            </ScrollToTop>
          )}

          {qrmodaldn && (
            <ScrollToTop>
              <div className="messagebox msgbox-qr">
                <div className="modal-qr">
                  <button
                    onClick={this.handleCloseModalDn}
                    className="btn btn-default btn-cicle btn-lg p-0 pull-right"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <QrReader
                    delay={300}
                    onError={this.handleErrorDn}
                    onScan={this.handleScanDn}
                    style={{ width: "100%" }}
                    // facingMode={cameramode}
                  />
                </div>
              </div>
              <div className="backdrop"></div>
            </ScrollToTop>
          )}

          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-md-12">
                      {isAdmin && hasLockPage && (
                        <Button
                          theme="secondary"
                          className="mb-3"
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              kanbanLockData: {
                                ...this.state.kanbanLockData,
                                isModalOpen: true,
                              },
                            })
                          }
                        >
                          <span className="mdi mdi-lock-open-outline"></span>{" "}
                          Unlock Page
                        </Button>
                      )}

                      <div className="form-group">
                        <label className="control-label">
                          Module No<span className="text-danger">*</span>
                        </label>
                        <div className="input-group mb-3">
                          <input
                            maxLength={70}
                            onKeyDown={this.handleKeyDownModule}
                            value={module_qrcode}
                            ref={(input) => {
                              this.moduleQrInput = input;
                            }}
                            type="text"
                            id="module_qrcode"
                            className={`form-control form-control-lg ${error &&
                              error.data.errors.module &&
                              "is-invalid"}`}
                            readOnly={
                              module_locked || hasLockPage ? true : false
                            }
                            onChange={this.handleChange}
                            placeholder="Module Qr Code"
                            autoFocus
                          />
                          {!hasLockPage && (
                            <div className="input-group-prepend">
                              <button
                                className="btn btn-outline-secondary"
                                onClick={this.handleScanPopupModule}
                                disabled={module_locked}
                                type="button"
                                id="button-addon1"
                              >
                                <i className="mdi mdi-camera mdi-24px"></i>
                              </button>
                            </div>
                          )}
                          {!hasLockPage && module_qrcode && (
                            <button
                              className="btn btn-outline-secondary"
                              onClick={this.handleOpenModuleDetail}
                            >
                              Detail
                            </button>
                          )}
                          {(hasLockPage || module_locked) && (
                            <button
                              className="btn btn-secondary"
                              onClick={this.handleOpenModule}
                            >
                              Reset
                            </button>
                          )}
                          {error && error.data.errors.module && (
                            <div className="invalid-feedback">
                              {error.data.errors.module[0]}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label">
                          Kanban DN<span className="text-danger">*</span>
                        </label>
                        <div className="input-group mb-3">
                          <input
                            maxLength={70}
                            onKeyDown={this.handleKeyDownDn}
                            value={dn_qrcode}
                            ref={(input) => {
                              this.kanbanDnInput = input;
                            }}
                            type="text"
                            id="dn_qrcode"
                            className={`form-control form-control-lg ${error &&
                              error.data.errors.kanban_dn &&
                              "is-invalid"}`}
                            readOnly={
                              kanban_dn_disable || hasLockPage ? true : false
                            }
                            onChange={this.handleChange}
                            placeholder="Kanban DN"
                          />
                          {!hasLockPage && (
                            <div className="input-group-prepend">
                              <button
                                className="btn btn-outline-secondary"
                                onClick={this.handleScanPopupDn}
                                type="button"
                                id="button-addon1"
                              >
                                <i className="mdi mdi-camera mdi-24px"></i>
                              </button>
                            </div>
                          )}
                          {error && error.data.errors.kanban_dn && (
                            <div className="invalid-feedback">
                              {error.data.errors.kanban_dn[0]}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label">
                          Kanban Internal DN
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group mb-3">
                          <input
                            onKeyDown={this.handleKeyDownKanban}
                            value={kanban}
                            ref={(input) => {
                              this.kanbanInput = input;
                            }}
                            type="text"
                            id="kanban"
                            className={`form-control form-control-lg ${error &&
                              error.data.errors.kanban &&
                              "is-invalid"}`}
                            onChange={this.handleChange}
                            placeholder="Kanban Internal DN"
                            readOnly={hasLockPage || disabled ? true : false}
                          />
                          {!hasLockPage && (
                            <div className="input-group-prepend">
                              <button
                                className="btn btn-outline-secondary"
                                onClick={this.handleScanPopup}
                                type="button"
                                id="button-addon1"
                              >
                                <i className="mdi mdi-camera mdi-24px"></i>
                              </button>
                            </div>
                          )}
                          {error && error.data.errors.kanban && (
                            <div className="invalid-feedback">
                              {error.data.errors.kanban[0]}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className={`control-label ${error &&
                            error.data.errors.parts}`}
                        >
                          Parts
                        </label>
                        {error && error.data.errors.parts && (
                          <div className="text-danger">
                            {error.data.errors.parts[0]}
                          </div>
                        )}
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Part Number</th>
                              <th>Part Name</th>
                              <th>Qty</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {parts && parts.length > 0 ? (
                              parts.map((part, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{part.part_no}</td>
                                    <td>{part.part_name}</td>
                                    <td>{part.qty}</td>
                                    <td>
                                      <button
                                        onClick={(index) =>
                                          this.handleRemovePart(index)
                                        }
                                        className="btn text-danger btn-link btn-sm"
                                      >
                                        <i className="mdi mdi-close"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="4" className="text-center">
                                  No data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-md-12 text-right">
                      <hr />
                      {this.props.fetching ? (
                        <button
                          className="btn btn-secondary btn-disabled"
                          type="submit"
                          disabled
                        >
                          <i className="mdi mdi-loading mdi-spin mr-2"></i>
                          Loading...
                        </button>
                      ) : (
                        <button
                          className="btn btn-secondary"
                          type="submit"
                          ref={this.btnSaveRef}
                          disabled={this.props.fetching}
                          onClick={this.handleSave}
                        >
                          Save
                        </button>
                      )}
                      <button
                        className="btn btn-default"
                        type="reset"
                        onClick={this.handleReset}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    saved: state.kanbanADMExport.saved,
    fetching: state.kanbanADMExport.fetching,
    fetched: state.kanbanADMExport.fetched,
    message: state.kanbanADMExport.message,
    error: state.kanbanADMExport.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveKanban: (data) => dispatch(saveKanbanADMExport(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(KanbanADMExportProd));
