const initState = {
	fetching: false,
	fetched: false,
	error: null,
	payload: null,
	saved: false,
	message: null,
	trace: null,
	isDeleted: false,
};

const traceReducer = (state = initState, action) => {
	switch (action.type) {
		case "FETCH_TRACE":
      return {
        ...state,
        trace: action.payload,
      };

		default:
			return state;
	}
};

export default traceReducer;
