import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store, Constants, Dispatcher } from "../../../flux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems(),
      toggle: Store.getToggleMenuState()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(),
      toggle: Store.getToggleMenuState()
    });
  }

  componentDidMount() {
    Dispatcher.dispatch({
      actionType: Constants.GET_MENU,
    });
  }

  handleClick = (menu) => {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_MENU,
      payload: {
        id: menu
      }
    });
  }

  render() {
    const { navItems, toggle } = this.state;
    const filter = navItems.length > 0 && navItems.filter(fil => fil !== null);
    const items = filter && filter.map(data => {
      return {
        title: data.title,
        to: data.url,
        htmlBefore: `<i class="${data.icon}"></i>`,
        htmlAfter: '',
        children: data.children.map(child => {
          return {
            title: child.title,
            to: child.url,
            htmlBefore: `<i class="${child.icon}"></i>`,
            htmlAfter: '',
          }
        })
      }
    })

    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          { items && items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} toggle={toggle} handleClick={(e) => this.handleClick(e)} />
          ))}
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
