import Axios from "axios";
import { url } from "../../global";

const saveKanbanADMExport = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SAVE_KANBANADMEXPORT',
            payload: Axios.post(`${url}/kanban-adm-export`, {
                rack_id: data.rack_id,
                rack_name: data.rack_name,
                parts: data.parts,
                dn_qrcode: data.dn_qrcode,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

export { saveKanbanADMExport }