import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { appName, url } from "../global";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import Error403 from "./Error403";
import { connect } from "react-redux";
import Table from "../components/table/Table";
import Axios from "axios";
import { fetchDNReport } from "../store/actions/dnReportAction";
import fileDownload from "js-file-download";
import RangeDatePicker from "../components/common/RangeDatePicker";
import Modal from "react-bootstrap4-modal";

class DNReport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			detailsLoading: false,
			details: [],
			detailsOpen: false,
			page: 1,
			perpage: 10,
			keyword: null,
			download: false,
			fetching: false,
			error: null,
			ordering: {
				type: "created_at",
				sort: "desc",
			},
			exportIsOpen: false,
			filter: {
				startDate: "",
				endDate: "",
			},
		};

		this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
		this.handleExport = this.handleExport.bind(this)
	}

	handleSorting = (e) => {
		const type = e.target.id;
		const sort = this.state.ordering.sort;
		this.setState({
			...this.state,
			ordering: {
				type: type,
				sort: sort === "asc" ? "desc" : "asc",
			},
		});
	};

	handleChangeKeyword = (e) => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value,
		});
	};

	handleSubmitKeyword = (e) => {
		e.preventDefault();
		this.props.fetchDNReport(this.state);
	};

	handleClickPage = (e) => {
		this.setState({
			...this.state,
			page: e,
		});
	};

	hanldeChangePage = (e) => {
		this.setState({
			...this.state,
			perpage: e.target.value,
		});
	};

	getDetails = async (id) => {
		this.setState({
			...this.state,
			detailsLoading: true,
			detailsOpen: {
				[id]: !this.state.detailsOpen[id] ? true : false,
			},
		});

		await Axios.get(`${url}/dn-report/${id}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		}).then((res) => {
			this.setState({
				...this.state,
				detailsLoading: false,
				details: {
					[id]: res.data.data,
				},
			});
		});
	};

	handleDateRangeChange = (startDate, endDate) => {
		this.setState({
			...this.state,
			filter: {
				...this.state.filter,
				startDate,
				endDate,
			},
		});
	};

	handleOpenExport = () => {
		this.setState({
			...this.state,
			exportIsOpen: true
		})
	}

	handleCloseExport = () => {
		this.setState({
			...this.state,
			exportIsOpen: false,
			filter: {
				startDate: '',
				endDate: '',
			}
		})
	}

	handleExport() {
		this.setState({
			...this.state,
			download: true,
		});

		Axios.post(
			`${url}/dn-report/export`,
			{
				filters: this.state.filter,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				responseType: "blob",
			}
		)
			.then((response) => {
				this.setState({
					...this.state,
					download: false,
				});
				fileDownload(response.data, `dn_report.xlsx`);
			})
			.catch((error) => {
				this.setState({
					...this.state,
					download: false,
				});
				console.log(error.response)
				// this.props.toastManager.add(error.response.data.message, {
				// 	appearance: "error",
				// 	autoDismiss: true,
				// });
			});
	}

	formatedDate = (dateString) => {
		var date = new Date(dateString);
		if (dateString === "") {
			date = new Date();
		}
		return date.toISOString().split("T")[0];
	};

	detailsTable = (id) => {
		return (
			<tr>
				<td colSpan="8" className="py-0">
					<div className="row">
						<div className="col-md-12">
							<table className="table table-bordered">
								<thead>
									<tr>
										<td>Part Number</td>
										<td>Name</td>
										<td>Job Number</td>
										<td>Total Qty</td>
										<td>Total Kanban</td>
										<td>Total Qty Scanned</td>
										<td>Total Kanban Scanned</td>
										<td>Status</td>
									</tr>
								</thead>
								<tbody>
									{this.state.detailsLoading ? (
										<tr>
											<td className="text-center" colSpan="5">
												<i className="mdi mdi-loading mdi-spin mr-2"></i>
												Loading ...
											</td>
										</tr>
									) : this.state.details[id] ? (
										this.state.details[id].map((detail, index) => {
											return (
												<tr key={index}>
													<td>{detail.part_number}</td>
													<td>{detail.part_name}</td>
													<td>{detail.job_number}</td>
													<td>{detail.qty_box}</td>
													<td>{detail.total_kanban}</td>
													<td>{detail.total_qty_scanned}</td>
													<td>{detail.total_kbn_dn_scanned}</td>
													<td className={`text-center text-white ${detail.status === "Open" ? "bg-danger" : "bg-success"}`}>
														{detail.status}
													</td>
												</tr>
											);
										})
									) : (
										<tr>
											<td className="text-center" colSpan="5">
												Data not found
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</td>
			</tr>
		);
	};

	componentDidMount = () => {
		this.props.fetchDNReport(this.state);
	};

	componentWillUpdate(nextProps, nextState) {
		if (this.state.page !== nextState.page) {
			this.props.fetchDNReport(nextState);
		}

		if (this.state.perpage !== nextState.perpage) {
			this.props.fetchDNReport(nextState);
		}

		if (this.state.ordering !== nextState.ordering) {
			this.props.fetchDNReport(nextState);
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.payload !== this.props.payload) {
			const data =
				this.props.payload.data &&
				this.props.payload.data.data.map((item) => {
					return {
						id: item._id,
						isChecked: false,
					};
				});

			this.setState({
				...this.state,
				checked: data,
			});
		}
	};

	render() {
		const { ordering } = this.state;
		const { payload, error, fetching } = this.props;
		if (!localStorage.getItem("token")) return <Redirect to="/login" />;
		if (error && error.status === 500)
			return <Error500 message={error.data.message} />;
		if (error && error.status === 403)
			return <Error403 message={error.data.message} />;
		const theads = [
			{ name: "dn_number", value: `DN Number`, sortable: true },
			{ name: "order_date", value: `Tanggal DN`, sortable: true },
			{ name: "customer_name", value: `Customer`, sortable: true },
			{ name: "qty_order", value: `Qty Order DN`, sortable: true },
			{ name: "total_kanban", value: `Total Kanban`, sortable: true },
			{ name: "total_qty_scanned", value: `Total Qty Scanned`, sortable: true },
			{
				name: "total_kbn_dn_scanned",
				value: `Total Kanban DN Scanned`,
				sortable: true,
			},
			{ name: "status", value: `Status`, sortable: true },
		];

		const reports =
			payload.data &&
			payload.data.data.map((report, index) => {
				return (
					<Fragment key={report.id}>
						<tr>
							<td>
								<strong className="text-semibold">{report.dn_number}</strong>
								<p className="text-center">
									<button
										className="btn btn-link btn-sm text-info"
										type="button"
										onClick={() => this.getDetails(report.id)}
									>
										<i
											className={`mdi ${this.state.detailsOpen[report.id]
												? "mdi-chevron-down"
												: "mdi-chevron-right"
												} mr-2`}
										></i>
										Show Details
									</button>
								</p>
							</td>
							<td>
								{new Date(report.order_date).toLocaleDateString("id-ID", {
									day: "2-digit",
									month: "short",
									year: "numeric",
								})}
							</td>
							<td>{report.customer_name}</td>
							<td>{report.qty_order}</td>
							<td>{report.total_kanban}</td>
							<td>{report.total_qty_scanned}</td>
							<td>{report.total_kbn_dn_scanned}</td>
							<td
								className={`text-center text-white ${report.status === "Open" ? "bg-danger" : "bg-success"
									}`}
							>
								{report.status}
							</td>
						</tr>
						{this.state.detailsOpen[report.id] && this.detailsTable(report.id)}
					</Fragment>
				);
			});

		return (
			<Container fluid className="main-content-container px-4">
				<Loading
					show={fetching}
					color="blue"
					showSpinner={false}
				/>
				<Helmet>
					<title>DN Report | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
					<div className="col-md-8">
						<PageTitle
							sm="12"
							title="Delivery Notes Report"
							className="text-sm-left"
						/>
					</div>
				</Row>
				<Row>
					<Col>
						<Card small className="mb-4">
							<CardBody className="p-0 pb-3">
								<div className="col-md-12 mt-3">
									<div className="row">
										<div className="col-md-8">
											<button
												className="btn btn-secondary mx-2"
												onClick={this.handleOpenExport}
											>
												<i className="mdi mdi-file-excel" /> Export
											</button>
										</div>
										<div className="col-md-4">
											<form onSubmit={this.handleSubmitKeyword}>
												<div className="input-group mb-3">
													<input
														id="keyword"
														type="text"
														className="form-control"
														placeholder="DN Number or Part Number or Job Number"
														aria-label="Example text with button addon"
														aria-describedby="button-addon1"
														onChange={this.handleChangeKeyword}
													/>
													<div className="input-group-prepend">
														<button
															className="btn btn-secondary"
															type="submit"
															id="button-addon1"
														>
															<i className="mdi mdi-magnify" /> Search{" "}
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div className="col-md-12 mt-4 mb-5">
									<Table
										theads={theads}
										ordering={ordering}
										handleSorting={this.handleSorting}
									>
										{fetching ? (
											<tr>
												<td className="text-center" colSpan="16">
													Loading...
												</td>
											</tr>
										) : payload.data && payload.data.data.length > 0 ? (
											reports
										) : (
											<tr>
												<td className="text-center" colSpan="16">
													Data not found
												</td>
											</tr>
										)}
									</Table>
								</div>
								<div className="col-md-12 py-3">
									<div className="row">
										<div className="col-md-10">
											{payload.data && payload.data.total > 1 && (
												<p>
													Showing{" "}
													{payload.data && payload.data.from.toLocaleString()}{" "}
													to {payload.data && payload.data.to.toLocaleString()}{" "}
													of{" "}
													{payload.data && payload.data.total.toLocaleString()}{" "}
													record(s)
												</p>
											)}

											{payload.data && payload.data.total > 1 && (
												<nav aria-label="Page navigation example">
													<ul className="pagination">
														{payload.data.current_page > 1 && (
															<li key="prev" className="page-item">
																<button
																	onClick={this.handleClickPage.bind(
																		null,
																		payload.data.current_page - 1
																	)}
																	className="page-link"
																>
																	Prev
																</button>
															</li>
														)}

														{payload.data.pages.map((page, index) => {
															return (
																<li
																	key={index}
																	className={`page-item ${page === "..." ? "disabled" : ""
																		} ${page === payload.data.current_page
																			? "active"
																			: ""
																		}`}
																>
																	<button
																		onClick={this.handleClickPage.bind(
																			null,
																			page
																		)}
																		className="page-link"
																	>
																		{page}
																	</button>
																</li>
															);
														})}

														{payload.data.current_page <
															payload.data.last_page && (
																<li key="next" className="page-item">
																	<button
																		onClick={this.handleClickPage.bind(
																			null,
																			payload.data.current_page + 1
																		)}
																		className="page-link"
																	>
																		Next
																	</button>
																</li>
															)}
													</ul>
												</nav>
											)}
										</div>
										<div className="col-md-2 text-right">
											<div className="form-group">
												<label className="control-label">
													Showing per page{" "}
												</label>
												<select
													defaultValue="10"
													id="perpage"
													className="form-control custom-select"
													onChange={this.hanldeChangePage}
												>
													<option value="10">10</option>
													<option value="20">20</option>
													<option value="50">50</option>
													<option value="100">100</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>

				{/* modals */}
				<Modal visible={this.state.exportIsOpen} onClickBackdrop={this.handleCloseExport}>
					<div className="modal-header">
						<h5 className="modal-title">Export DN Report</h5>
					</div>
					<div className="modal-body py-0 pt-2 px-4">
						<div className='form-group'>
							<label>Tanggal DN</label>
							<RangeDatePicker onDateRangeChange={this.handleDateRangeChange} />
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.handleCloseExport}>
							Close
						</button>
						{
							this.state.download ? (
								<button className="btn btn-secondary btn-disabled" disabled>
									<i className="mdi mdi-loading mdi-spin"></i> Loading...
								</button>
							) : (
								<button className="btn btn-secondary" onClick={this.handleExport}>
									<i className="mdi mdi-printer"></i> Export
								</button>
							)
						}
					</div>
				</Modal>

			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state,
		payload: state.dnReport.payload,
		error: state.dnReport.error,
		fetching: state.dnReport.fetching,
		message: state.dnReport.message,
		saved: state.dnReport.saved,
		isDeleted: state.dnReport.isDeleted,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchDNReport: (filter) => dispatch(fetchDNReport(filter)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withToastManager(DNReport));
