const initState = {
    fetching: false,
    fetched: false,
    error: null,
    payload: {},
    summary: null,
    saved: false,
    message: null,
    isDeleted: false,
};

const monitoringStockReducer = (state = initState, action) => {
    switch (action.type) {
        case 'MONITORINGSTOCK_PENDING':
            return {
                ...state,
                fetching: true
            };
        case 'MONITORINGSTOCK_REJECTED':

            if (action.payload.response.status === 401) {
                localStorage.removeItem('token');
            }

            return {
                ...state,
                error: action.payload.response,
                fetching: false
            };
        case 'MONITORINGSTOCK_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                payload: action.payload.data,
                error: null,
                saved: false,
                isDeleted: false
            };
        case 'GET_SUMMARY_PENDING':
            return {
                ...state,
                fetching: true
            };
        case 'GET_SUMMARY_REJECTED':

            if (action.payload.response.status === 401) {
                localStorage.removeItem('token');
            }

            return {
                ...state,
                error: action.payload.response,
                fetching: false
            };
        case 'GET_SUMMARY_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                summary: action.payload.data.summary,
                error: null,
                saved: false,
            };
        default:
            return state;
    }
};

export default monitoringStockReducer;
