const initState = {
	fetching: false,
	fetched: false,
	error: null,
	payload: {},
	saved: false,
	message: null,
	menu: {},
	isDeleted: false,
};

const menuReducer = (state = initState, action) => {
	switch (action.type) {
		case 'MENU_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'MENU_REJECTED':

			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}

			return {
				...state,
				error: action.payload.response,
				fetching: false
			};
		case 'MENU_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				payload: action.payload.data,
				error: null,
				saved: false,
				isDeleted: false
			};
		case 'SAVE_MENU_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'SAVE_MENU_REJECTED':
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}

			return {
				...state,
				error: action.payload.response,
				fetching: false,
				fetched: false
			};
		case 'SAVE_MENU_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				message: action.payload.data.message,
				error: null,
				saved: true
			};
		case 'GET_MENU_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'GET_MENU_REJECTED':

			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}

			return {
				...state,
				error: action.payload.response,
				fetching: false
			};
		case 'GET_MENU_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				menu: action.payload.data,
				error: null,
				saved: false,
			};
		case 'UPDATE_MENU_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'UPDATE_MENU_REJECTED':
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}

			return {
				...state,
				error: action.payload.response,
				fetching: false,
				fetched: false
			};
		case 'UPDATE_MENU_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				message: action.payload.data.message,
				error: null,
				saved: true
			};
		case 'DELETE_MENU_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'DELETE_MENU_REJECTED':
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}

			return {
				...state,
				error: action.payload.response,
				fetching: false
			};
		case 'DELETE_MENU_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				message: action.payload.data.message,
				error: null,
				isDeleted: true
			};
		default:
			return state;
	}
}

export default menuReducer