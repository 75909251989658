import Axios from "axios";
import { url } from "../../global";

const fetchSR = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SR',
            payload: Axios.get(`${url}/scan-report`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchSR };