import React, { Component, Fragment } from 'react'
import { Card, CardBody, Container, Row } from 'shards-react'
import PageTitle from '../components/common/PageTitle';
import { connect } from 'react-redux'
import { fetchMenu, saveMenu, getMenu, updateMenu, deleteMenu } from '../store/actions/menuActions';
import { url, appName } from '../global';
import Axios from 'axios';
import Loading from 'react-loading-bar';
import { Helmet } from 'react-helmet';
import { withToastManager } from 'react-toast-notifications';
import { Dispatcher, Constants } from '../flux';
import ScrollToTop from '../components/layout/ScrollToTop';

class Menu extends Component {

    state = {
        parents: [],
        permissions: [],
        id: '',
        title: '',
        parent_id: '',
        permission: '',
        url: '',
        icon: '',
        order_number: '',
        showMsgBox: false,
        deleteId: ''
    }

    componentDidUpdate = (prevProps) => {
       if (prevProps.saved !== this.props.saved) {
            if (this.props.saved) {
                const { toastManager } = this.props;
                toastManager.add(this.props.message, {
                    appearance: 'success',
                    autoDismiss: true
                });
            }
           this.props.fetchMenu()
           Dispatcher.dispatch({
            actionType: Constants.GET_MENU,
          });
       }

        if (prevProps.error !== this.props.error) {
            if (!this.props.fetched) {
                if (this.props.error) {
                    const { toastManager } = this.props;
                    toastManager.add(this.props.error.data.message, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
            }
        }
    
        if (prevProps.isDeleted !== this.props.isDeleted) {
            if (this.props.isDeleted) {
                const { toastManager } = this.props;
                toastManager.add(this.props.message, {
                    appearance: 'success',
                    autoDismiss: true
                });
                this.setState({
                    deleteId: '',
                    showMsgBox: false
                })

                this.props.fetchMenu()
                Dispatcher.dispatch({
                    actionType: Constants.GET_MENU,
                });
            }
        }
    }

    componentWillUpdate = (nextProps) => {
        if (nextProps.menu !== this.props.menu) {
            if (nextProps.menu) {
                const data = nextProps.menu.data
                this.setState({
                    ...this.state,
                    id: data._id ? data._id : '', 
                    title: data.title ? data.title : '', 
                    parent_id: data.parent_id ? data.parent_id : '', 
                    permission: data.permission ? data.permission : '', 
                    url: data.url ? data.url : '', 
                    icon: data.icon ?  data.icon : '', 
                    order_number: data.order_number ? data.order_number : '', 
                })
            }
        }
    }

    getParents() {

        Axios.get(`${url}/menu`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            const parents = res.data.data.map(parent => {
                return {
                    id: parent._id,
                    title: parent.title
                }
            })

            this.setState({
                ...this.state,
                parents
            })
        })
    }

    handleChange = (name) => (event) => {
        this.setState({
            ...this.state,
            [name]: event.target.value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()
        if (!this.state.id) {
            this.props.saveMenu(this.state)
        } else {
            this.props.updateMenu(this.state.id, this.state)
        }

        this.getParents()
        this.getPermissions()
        this.setState({
            ...this.state,
            id: '',
            title: '',
            parent_id: '',
            permission: '',
            url: '',
            icon: '',
            order_number: ''
        })
    }

    handleReset = () => {
        this.setState({
            ...this.state,
            id: '',
            title: '',
            parent_id: '',
            permission: '',
            url: '',
            icon: '',
            order_number: ''
        })
    }

    getPermissions() {

        Axios.get(`${url}/permission/list`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            const permissions = res.data.data.map(permission => {
                return {
                    slug: permission.slug,
                    name: permission.name
                }
            })

            this.setState({
                ...this.state,
                permissions
            })
        })
    }

    handleEdit = (id) => {
        this.props.getMenu(id)
    }

    handleDelete = (id) => {
		this.setState({
			...this.state,
			deleteId: id,
			showMsgBox: true
		});
    }
    
    handleClickYes = () => {

        this.props.deleteMenu(this.state.deleteId);
        this.getParents()
        this.getPermissions()
	}

	handleClickNo = () => {
		this.setState({
			...this.state,
			showMsgBox: false,
			deleteId: null
		});
	}

    componentDidMount() {
        this.props.fetchMenu()
        this.getParents()
        this.getPermissions()
    }
    render() {
        const { payload, fetching } = this.props
        const { 
            parents, 
            permissions, 
            title,
            parent_id,
            permission,
            url,
            icon,
            order_number,
            id,
            showMsgBox
        } = this.state

        return (
            <main className="main-content col mt-5">
                <Loading
                    show={fetching}
                    color="blue"
                    showSpinner={false}
                    />
                <Helmet>
                    <title>Menu | {appName} </title>
                </Helmet>
                <Container fluid className="main-content-container px-4">
                    {
                        showMsgBox &&
                        (
                            <ScrollToTop>
                                <div className="messagebox">
                                    <p className="mb-5">Are you sure want to delete this data?</p>
                                    {
                                        fetching ? 
                                        (
                                            <button className="btn btn-secondary mr-4 btn-disabled" type="submit" disabled><i className="mdi mdi-loading mdi-spin mr-2"></i>Loading...</button>
                                        ) :  (
                                            <button className="btn btn-secondary mr-4" onClick={this.handleClickYes}>Yes</button>
                                        )
                                    }
                                    <button className="btn btn-white" onClick={this.handleClickNo}>No Cancel</button>
                                </div>
                                <div className="backdrop"></div>
                            </ScrollToTop>
                        )
                    }
                    <Row noGutters className="page-header py-4">
                        <PageTitle sm="4" title="Menu"  className="text-sm-left" />
                    </Row>
                    <Row>
                        <div className="col-md-4">

                            <Card small className="mb-4">
                                <CardBody className="p-0 py-3">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="col-12 col-12 px-4 py-2">
                                            <div className="form-group">
                                                <label  className="control-label">Name</label>
                                                <input onChange={this.handleChange('title')} value={title} type="text" className="form-control" id="name" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label">Parent</label>
                                                <select onChange={this.handleChange('parent_id')} value={parent_id} id="parent_id" className="form-control custom-select">
                                                    <option value={null}>No Parent</option>
                                                    {
                                                        parents && parents.map(parent => {
                                                            return <option key={parent.id} value={parent.id}>{parent.title}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label">Permission</label>
                                                <select onChange={this.handleChange('permission')} value={permission} id="permission" className="form-control custom-select">
                                                    <option value={null}>No permission</option>
                                                    {
                                                        permissions && permissions.map(permission => {
                                                            return <option key={permission.slug} value={permission.slug}>{permission.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label  className="control-label">URL</label>
                                                <input onChange={this.handleChange('url')} value={url} type="text" className="form-control" id="url" placeholder="URL" />
                                            </div>
                                            <div className="form-group">
                                                <label  className="control-label">Icon</label>
                                                <input onChange={this.handleChange('icon')} value={icon} type="text" id="icon" className="form-control" placeholder="Icon" />
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label">Order Number</label>
                                                <input onChange={this.handleChange('order_number')} value={order_number} type="number" id="order_number" className="form-control" placeholder="Order Number" />
                                            </div>
                                            <hr/>
                                            <div className="form-group text-right">
                                                
                                                <button className="btn" type="reset" onClick={this.handleReset}>Reset</button>
                                                {
                                                    fetching ? 
                                                    (
                                                        <button className="btn btn-secondary btn-disabled" type="submit" disabled><i className="mdi mdi-loading mdi-spin mr-2"></i>Loading...</button>
                                                    ) :  (
                    
                                                        <button className="btn btn-secondary">{ !id ? 'Save' : 'Update' }</button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-md-8">
                            <Card className="mb-4">
                                <CardBody className="p-0 px-4 py-3 menu-wrapper">
                                { payload.data && payload.data.length > 0 ? (
                                    <ul className="menu-list">
                                        {
                                            payload.data.map(menu => {
                                                return (
                                                    <Fragment key={menu._id}>
                                                        <li>
                                                            <span> <i className={`${menu.icon} mr-2`}></i> {menu.title}</span>
                                                            <span className="float-right">
                                                                <button data-tip="Edit" className="btn btn-sm btn-link text-success px-0 pr-2" onClick={() => this.handleEdit(menu._id)}><i className="mdi mdi-pencil"></i></button>
                                                                <button data-tip="Delete" className="btn btn-sm btn-link text-danger px-0 pr-2" onClick={() => this.handleDelete(menu._id)}><i className="mdi mdi-delete"></i></button>
                                                            </span>
                                                        </li>
                                                        {
                                                            menu.children.length > 0 && <ul className="menu-list">
                                                                { menu.children.map(child => {
                                                                    return (
                                                                        <li key={child._id}>
                                                                            <span><i className={`${child.icon} mr-2`}></i>{child.title}</span>
                                                                            <span className="float-right">
                                                                                <button onClick={() => this.handleEdit(child._id)} data-tip="Edit" className="btn btn-sm btn-link text-success px-0 pr-2"><i className="mdi mdi-pencil"></i></button>
                                                                                <button onClick={() => this.handleDelete(child._id)} data-tip="Delete" className="btn btn-sm btn-link text-danger px-0 pr-2"><i className="mdi mdi-delete"></i></button>
                                                                            </span>
                                                                        </li>
                                                                    )
                                                                }) }
                                                            </ul>
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : (
                                    <span className="empty-menu">
                                        Menu empty!
                                    </span>
                                )
                                }
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        payload: state.menu.payload,
        error: state.menu.error,
		fetching: state.menu.fetching,
		message: state.menu.message,
		saved: state.menu.saved,
        isDeleted: state.menu.isDeleted,
        menu: state.menu.menu
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
        fetchMenu: () => dispatch(fetchMenu()),
        saveMenu: (menu) => dispatch(saveMenu(menu)),
        getMenu: (id) => dispatch(getMenu(id)),
        updateMenu: (id, menu) => dispatch(updateMenu(id, menu)),
        deleteMenu: (id) => dispatch(deleteMenu(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(Menu))