import Axios from "axios";
import moment from "moment";
import { url } from "../../global";

export const saveScanProd = (data, cb) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCHING_START",
    });

    console.log(data);

    await Axios.post(`${url}/label-prod`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        cb(true, res.data.message);
        dispatch({
          type: "CHANGE_PART_ID",
          payload: "",
        });

        dispatch({
          type: "CHANGE_LABEL",
          payload: "",
        });

        dispatch({
          type: "SET_RECEIVING",
          payload: null,
        });

        dispatch({
          type: "SET_PART",
          payload: null,
        });

        dispatch({
          type: "CHANGE_LABEL_AUTOCOMPLETE",
          payload: "",
        });
      })
      .catch((err) => {
        dispatch({
          type: "SCAN_LABEL_PRODUCT_FAILED",
          payload: err.response,
        });
        dispatch({
          type: "CHANGE_LABEL",
          payload: "",
        });
      });
    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};

export const onChange = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "CHANGE_LABEL_PRODUCT",
      payload: data,
    });
  };
};

export const fetchLabelProduct = (filter) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "FETCHING_START",
    });
    const labelProducts = await Axios.get(`${url}/label-prod`, {
      params: {
        page: filter.page,
        perpage: filter.perpage,
        keyword: filter.keyword,
        ordering: filter.ordering,
        start_date: filter.start_date
          ? moment(filter.start_date).format("YYYY-MM-DD")
          : null,
        end_date: filter.end_date
          ? moment(filter.end_date)
              .add(1, "day")
              .format("YYYY-MM-DD")
          : null,
        part_number: filter.part_number ? filter.part_number : null,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    dispatch({
      type: "FETCH_LABEL_PRODUCT",
      payload: {
        ...labelProducts.data.data,
        data: labelProducts.data.data.data.map((item) => {
          return {
            ...item,
            isChecked: false,
          };
        }),
      },
    });

    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};

export const getLabelProd = (id) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCHING_START",
    });
    const labelProducts = await Axios.get(`${url}/label-prod/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    console.log(labelProducts.data.data, "labelProducts");
    dispatch({
      type: "GET_LABEL_PRODUCT",
      payload: {
        ...labelProducts.data.data,
        inspec_date: labelProducts.data.data.inspec_date
          ? new Date(labelProducts.data.data.inspec_date)
          : "",
        prod_date: labelProducts.data.data.prod_date
          ? new Date(labelProducts.data.data.prod_date)
          : "",
      },
    });

    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};

export const onChangeData = (data) => {
  return (dispatch) => {
    dispatch({
      type: "GET_LABEL_PRODUCT",
      payload: data,
    });
  };
};

export const updateLabelProd = (id, data, cb) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCHING_START",
    });

    console.log(data);

    await Axios.put(`${url}/label-prod/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        cb(true, res.data.message);
        dispatch({
          type: "CHANGE_PART_ID",
          payload: "",
        });

        dispatch({
          type: "CHANGE_LABEL",
          payload: "",
        });

        dispatch({
          type: "SET_RECEIVING",
          payload: null,
        });

        dispatch({
          type: "SET_PART",
          payload: null,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SCAN_LABEL_PRODUCT_FAILED",
          payload: err.response,
        });
        dispatch({
          type: "CHANGE_LABEL",
          payload: "",
        });
      });
    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};
