const initState = {
	fetching: false,
	fetched: false,
	error: null,
	payload: {},
	saved: false,
	message: null,
	warehouse: {},
	isDeleted: false,
};

const warehouseReducer = (state = initState, action) => {
	switch (action.type) {
		case 'WAREHOUSE_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'WAREHOUSE_REJECTED':
			
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}
			
			return {
				...state,
				error: action.payload.response,
				fetching: false
			};
		case 'WAREHOUSE_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				payload: action.payload.data,
				error: null,
				saved: false,
				isDeleted: false
			};
		case 'SAVE_WAREHOUSE_PENDING' : 
			return {
				...state,
				fetching: true
			};
		case 'SAVE_WAREHOUSE_REJECTED' :
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}
			
			return {
				...state,
				error: action.payload.response,
				fetching: false,
				fetched: false
			};
		case 'SAVE_WAREHOUSE_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				message: action.payload.data.message,
				error: null,
				saved: true
			};
		case 'GET_WAREHOUSE_PENDING':
			return {
				...state,
				fetching: true
			};
		case 'GET_WAREHOUSE_REJECTED':
			
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}
			
			return {
				...state,
				error: action.payload.response,
				fetching: false
			};
		case 'GET_WAREHOUSE_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				warehouse: action.payload.data,
				error: null,
				saved: false,
			};
		case 'UPDATE_WAREHOUSE_PENDING' : 
			return {
				...state,
				fetching: true
			};
		case 'UPDATE_WAREHOUSE_REJECTED' :
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}
			
			return {
				...state,
				error: action.payload.response,
				fetching: false,
				fetched: false
			};
		case 'UPDATE_WAREHOUSE_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				message: action.payload.data.message,
				error: null,
				saved: true
			};
		case 'DELETE_WAREHOUSE_PENDING' : 
			return {
				...state,
				fetching: true
			};
		case 'DELETE_WAREHOUSE_REJECTED' :
			if (action.payload.response.status === 401) {
				localStorage.removeItem('token');
			}
			
			return {
				...state,
				error: action.payload.response,
				fetching: false
			};
		case 'DELETE_WAREHOUSE_FULFILLED':
			return {
				...state,
				fetching: false,
				fetched: true,
				message: action.payload.data.message,
				error: null,
				isDeleted: true
			};
		default:
			return state;
	}
};

export default warehouseReducer;
