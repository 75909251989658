import Axios from "axios";
import { url } from "../../global";

const fetchModuleReport = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'REPORT',
            payload: Axios.get(`${url}/module-report`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const getModuleReportDetails = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_DETAIL',
            payload: Axios.get(`${url}/module-report/${id}/detail`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchModuleReport, getModuleReportDetails };