import React, { Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormCheckbox,
  DatePicker,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Redirect, Link } from "react-router-dom";
import { appName, url } from "../global";
import { Helmet } from "react-helmet";
import ScrollToTop from "../components/layout/ScrollToTop";
import { withToastManager } from "react-toast-notifications";
import { connect } from "react-redux";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import Table from "../components/table/Table";
import Error403 from "./Error403";
import moment from "moment";
import Axios from "axios";
import Modal from "react-bootstrap4-modal";
import { fetchPreparationDelivery } from "../store/actions/preparationDeliveryActions";

class PreparationDelivery extends React.Component {
  state = {
    search: null,
    page: 1,
    perpage: 10,
    keyword: null,
    alert: true,
    alertMsgBox: false,
    deleteIdPlant: null,
    showMsgBox: false,
    isDeleted: false,
    ordering: {
      type: "plant",
      sort: "asc",
    },
    modal: false,
    file_import: null,
    import: "Choose file...",
    importing: false,
    checked: [],
    start_date: null,
    end_date: null,
    part_number: "",
    detailsLoading: false,
    details: [],
    detailsOpen: false,
    status: false,
  };

  handleSorting = (e) => {
    const type = e.target.id;
    const sort = this.state.ordering.sort;
    this.setState({
      ...this.state,
      ordering: {
        type: type,
        sort: sort === "asc" ? "desc" : "asc",
      },
    });
  };

  handleChangeKeyword = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  handleSubmitKeyword = (e) => {
    e.preventDefault();
    this.props.fetchPreparationDelivery(this.state);
  };

  handleClickPage = (e) => {
    this.setState({
      ...this.state,
      page: e,
    });
  };

  hanldeChangePage = (e) => {
    this.setState({
      ...this.state,
      perpage: e.target.value,
    });
  };

  handleClickDelete = (id) => {
    this.setState({
      ...this.state,
      deleteIdPlant: id,
      showMsgBox: true,
    });
  };

  handleClickYes = () => {
    this.setState({
      ...this.state,
      alertMsgBox: true,
      showMsgBox: false,
      isDeleted: true,
    });

    this.props.deletePlant(this.state.deleteIdPlant);
  };

  handleClickNo = () => {
    this.setState({
      ...this.state,
      showMsgBox: false,
      deleteIdPlant: null,
    });
  };

  componentWillUpdate(nextProps, nextState) {
    if (this.state.page !== nextState.page) {
      this.props.fetchPreparationDelivery(nextState);
    }

    if (this.state.perpage !== nextState.perpage) {
      this.props.fetchPreparationDelivery(nextState);
    }

    if (this.state.ordering !== nextState.ordering) {
      this.props.fetchPreparationDelivery(nextState);
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.error !== this.props.error) {
      if (!this.props.fetched) {
        if (this.props.error) {
          const { toastManager } = this.props;
          toastManager.add(this.props.error.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    }

    if (prevProps.isDeleted !== this.props.isDeleted) {
      if (this.props.isDeleted) {
        const { toastManager } = this.props;
        toastManager.add(this.props.message, {
          appearance: "success",
          autoDismiss: true,
        });
        this.props.fetchPreparationDelivery(this.state);
      }
    }

    if (prevProps.labelProduct !== this.props.labelProduct) {
      const data =
        this.props.labelProduct &&
        this.props.dn.data.map((item) => {
          return {
            id: item._id,
            part_number: item.part_number,
            description: item.description,
            qty: item.qty,
            lot_id: item.lot_id,
            isChecked: false,
            copies: item.copies,
          };
        });

      this.setState({
        ...this.state,
        checked: data,
      });
    }
  };

  handleModal = () => {
    this.setState({
      ...this.state,
      modal: true,
    });
  };

  modalBackdropClicked = () => {
    this.setState({
      ...this.state,
      modal: false,
    });
  };

  componentDidMount = () => {
    this.props.fetchPreparationDelivery(this.state);
  };

  handleCheckAll = () => {
    let checked = this.state.checked;
    checked.forEach((check) => (check.isChecked = true));
    this.setState({ checked: checked });
  };

  handleUnCheckAll = () => {
    let checked = this.state.checked;
    checked.forEach((check) => (check.isChecked = false));
    this.setState({ checked: checked });
  };

  handleToggleCheck = () => {
    let checked = this.state.checked;

    checked.forEach((checked) => {
      checked.isChecked = checked.isChecked ? false : true;
    });

    this.setState({ checked: checked });
  };

  handleCheckBox = (event) => {
    let checked = this.state.checked;

    checked.forEach((checked) => {
      if (checked.id === event.target.value)
        checked.isChecked = event.target.checked;
    });

    this.setState({
      ...this.state,
      checked: checked,
    });
  };

  detailsTable = (id) => {
    const { status } = this.state;
    return (
      <tr>
        <td colSpan="16" className="py-0">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td className="no-wrap">Supplier Part Number</td>
                    <td className="no-wrap">AHM Part Number</td>
                    <td className="no-wrap">Part Name</td>
                    {status ? (
                      <Fragment>
                        <td className="no-wrap">Qty</td>
                        <td className="no-wrap">Lot Coil ID</td>
                        <td className="no-wrap">Prod Date</td>
                        <td className="no-wrap">QR</td>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <td className="no-wrap">Qty DN</td>
                        <td className="no-wrap">Qty DN Received</td>
                        <td className="no-wrap">Qty Sum DI Ori</td>
                        <td className="no-wrap">PO Item</td>
                      </Fragment>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {this.state.detailsLoading ? (
                    <tr>
                      <td className="text-center" colSpan="5">
                        <i className="mdi mdi-loading mdi-spin mr-2"></i>
                        Loading ...
                      </td>
                    </tr>
                  ) : this.state.details[id] ? (
                    this.state.details[id].map((detail, index) => {
                      return (
                        <tr key={index}>
                          <td className="no-wrap">
                            {detail.supplier_part_number}
                          </td>
                          <td className="no-wrap">{detail.ahm_part_number}</td>
                          <td className="no-wrap">{detail.part_number_desc}</td>
                          {status ? (
                            <Fragment>
                              <td className="no-wrap">{detail.qty}</td>
                              <td className="no-wrap">{detail.lot_coil_id}</td>
                              <td className="no-wrap">
                                {detail.prod_date
                                  ? moment(detail.prod_date).format(
                                      "DD/MM/YYYY"
                                    )
                                  : null}
                              </td>
                              <td className="no-wrap">{detail.qrcode}</td>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <td className="no-wrap">{detail.qty_dn}</td>
                              <td className="no-wrap">
                                {detail.qty_dn_received}
                              </td>
                              <td className="no-wrap">
                                {detail.qty_sum_di_ori}
                              </td>
                              <td className="no-wrap">{detail.po_item}</td>
                            </Fragment>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan="5">
                        Data not found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  handleExport = async () => {
    const { toastManager } = this.props;
    this.setState({
      ...this.state,
      download: true,
    });

    await Axios.post(
      `${url}/prep-del/export`,
      {
        check: this.state.checked,
      },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => {
        this.setState({
          ...this.state,
          download: false,
        });

        const file = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);

        this.props.fetchPreparationDelivery(this.state);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          ...this.state,
          download: false,
        });

        if (error.response) {
          toastManager.add(error.response.statusText, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  handleChangeDate = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleClearKeyword = (e) => {
    this.setState({
      ...this.state,
      start_date: null,
      end_date: null,
    });
    this.props.fetchPreparationDelivery({
      ...this.state,
      start_date: null,
      end_date: null,
    });
  };

  handleChangeFileImport = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.setState({
        ...this.state,
        import: file.name,
        file_import: file,
      });
    }
  };

  handleImport = () => {
    const { toastManager } = this.props;

    this.setState({
      ...this.state,
      importing: true,
    });

    const fd = new FormData();
    fd.append("file", this.state.file_import);

    Axios.post(`${url}/prep-del/import`, fd, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Conten-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        this.setState({
          ...this.state,
          modal: false,
          importing: false,
          file_import: "",
          import: "Choose file...",
        });

        toastManager.add(res.data.message, {
          appearance: "success",
          autoDismiss: true,
        });

        this.fileImport.value = "";
        this.props.fetchPreparationDelivery(this.state);
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          modal: false,
          importing: false,
          file_import: "",
          import: "Choose file...",
        });

        this.fileImport.value = "";

        // toastManager.add(error.response.data.message, {
        //   appearance: "error",
        //   autoDismiss: true,
        // });
      });
  };

  handleClearPartNumber = () => {
    this.setState({
      ...this.state,
      part_number: "",
    });
    this.props.fetchPreparationDelivery({
      ...this.state,
      part_number: "",
    });
  };

  handleModal = () => {
    console.log("I am clicked");
    this.setState({
      ...this.state,
      modal: true,
    });
  };

  modalBackdropClicked = () => {
    this.setState({
      ...this.state,
      modal: false,
      modalPrint: false,
    });
  };

  getDetails = async (id) => {
    this.setState({
      ...this.state,
      detailsLoading: true,
      detailsOpen: {
        [id]: !this.state.detailsOpen[id] ? true : false,
      },
    });

    await Axios.get(`${url}/prep-del/get`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        dn_id: id,
      },
    }).then((res) => {
      this.setState({
        ...this.state,
        detailsLoading: false,
        details: {
          [id]: res.data.data,
        },
      });
    });
  };

  handleChangeTab = (value) => {
    this.setState({
      ...this.state,
      status: value,
    });
    this.props.fetchPreparationDelivery({ ...this.state, status: value });
  };

  render() {
    const { dn, error, fetching } = this.props;
    const { start_date, end_date } = this.state;

    console.log(dn, "dn");

    if (!localStorage.getItem("token")) return <Redirect to="/login" />;
    if (error && error.status === 500)
      return <Error500 message={error.data.message} />;
    if (error && error.status === 403)
      return <Error403 message={error.data.message} />;

    const { ordering } = this.state;

    const theads = [
      { name: "delivery_note_no", value: "DN No", sortable: true },
      { name: "delivery_note_date", value: "DN Date", sortable: true },
      { name: "po_number", value: "PO No", sortable: true },
      { name: "gate_desc", value: "Gate", sortable: true },
      { name: "plant_desc", value: "Plan", sortable: true },
      { name: "supplier_desc", value: "Suppler", sortable: true },
      { name: "police_truck_no", value: "Truck Plate", sortable: true },
    ];

    const dns =
      dn &&
      dn.data.map((item, index) => {
        const checked = this.state.checked[index];
        return (
          <Fragment key={item._id}>
            <tr>
              <td className="no-wrap">
                {/* <FormCheckbox
                  checked={checked && checked.isChecked ? true : false}
                  value={item._id}
                  onChange={this.handleCheckBox}
                >*/}
                <span className="text-primary">
                  <strong>{item.delivery_note_no}</strong>
                </span>
                {/*</FormCheckbox> */}
                <p>
                  <button
                    className="btn btn-link btn-sm text-info p-0"
                    type="button"
                    onClick={() => this.getDetails(item._id)}
                  >
                    <i
                      className={`mdi ${
                        this.state.detailsOpen[item._id]
                          ? "mdi-chevron-down"
                          : "mdi-chevron-right"
                      } mr-2`}
                    ></i>
                    Show Details
                  </button>
                </p>
              </td>
              <td className="no-wrap">
                {item.delivery_note_date
                  ? moment(item.delivery_note_date).format("DD/MM/YYYY")
                  : null}
              </td>
              <td className="no-wrap">{item.po_number}</td>
              <td className="no-wrap">{item.gate_desc}</td>
              <td className="no-wrap">{item.plant_desc}</td>
              <td className="no-wrap">{item.supplier_desc}</td>
              <td className="no-wrap">{item.police_truck_no}</td>
            </tr>
            {this.state.detailsOpen[item._id] && this.detailsTable(item._id)}
          </Fragment>
        );
      });

    return (
      <Container fluid className="main-content-container px-4">
        <Loading show={fetching} color="blue" showSpinner={false} />
        <Helmet>
          <title>Preparation Delivery | {appName} </title>
        </Helmet>
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Preparation Delivery"
            className="text-sm-left"
          />
        </Row>
        <Modal
          visible={this.state.modal}
          onClickBackdrop={this.modalBackdropClicked}
        >
          <div className="modal-header">
            <h5 className="modal-title">Import DN</h5>
          </div>
          <div className="modal-body py-0 pt-2 px-4">
            <div className="row">
              <div className="custom-file">
                <input
                  id="import"
                  type="file"
                  className="custom-file-input"
                  onChange={this.handleChangeFileImport}
                  accept=".xlsx, .xls, .csv"
                  ref={(ref) => (this.fileImport = ref)}
                />
                <label
                  className="custom-file-label"
                  htmlFor="customFile2"
                  id="placeholderCustomFile2"
                >
                  {this.state.import}
                </label>
              </div>
              <div className="mb-3 mt-2">
                <div>
                  <small>
                    *) File format is xlsx, xlsx, or csv (semicolon separator
                    ';')
                    <br />
                    *) Warning! existing data will be overwritted, be carefully!{" "}
                    <br />
                    *) Max file size 1MBs <br />
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              onClick={this.modalBackdropClicked}
            >
              Close
            </button>
            {this.state.importing ? (
              <button
                type="button"
                className="btn btn-secondary btn-disabled"
                disabled
              >
                <i className="mdi mdi-loading mdi-spin"></i> Processing...
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.handleImport}
              >
                Import
              </button>
            )}
          </div>
        </Modal>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="col-md-12 mt-4">
                  <div className="row">
                    <div className="col-md-8">
                      <Link
                        to="/preparation-delivery/scan"
                        className="btn btn-secondary mb-2"
                      >
                        <i className="mdi mdi-plus"></i> Scan DN
                      </Link>

                      {/* <button
                        className="btn btn-secondary mx-2"
                        onClick={this.handleCheckAll}
                      >
                        <i className="mdi mdi-check-box-outline" /> Check all
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={this.handleUnCheckAll}
                      >
                        <i className="mdi mdi-checkbox-blank-outline" /> Uncheck
                        all
                      </button>
                      <button
                        className="btn btn-secondary mx-2"
                        onClick={this.handleToggleCheck}
                      >
                        <i className="mdi mdi-toggle-switch" /> Toggle Check
                      </button> */}

                      <button
                        className="btn btn-secondary mx-2 mb-2"
                        onClick={this.handleExport}
                      >
                        <i className="mdi mdi-download" /> Export All
                      </button>
                      <button
                        className="btn btn-secondary mb-2"
                        onClick={this.handleModal}
                      >
                        <i className="mdi mdi-upload" /> Import DN
                      </button>
                    </div>
                    <div className="col-md-4 text-right">
                      <form onSubmit={this.handleSubmitKeyword}>
                        <div className="input-group mb-3">
                          <input
                            id="keyword"
                            type="text"
                            className="form-control"
                            placeholder=""
                            aria-label="Example text with button addon"
                            aria-describedby="button-addon1"
                            onChange={this.handleChangeKeyword}
                          />
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-secondary"
                              type="submit"
                              id="button-addon1"
                            >
                              <i className="mdi mdi-magnify" /> Search{" "}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* <div className="row mb-4">
                    <div className="col-md-12">
                      <button
                        className="btn btn-secondary"
                        onClick={this.handleExport}
                      >
                        <i className="mdi mdi-download" /> Export All
                      </button>
                      <button
                        className="btn btn-secondary mx-2"
                        onClick={this.handleModal}
                      >
                        <i className="mdi mdi-upload" /> Import DN
                      </button>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-gruop">
                        <label className="mb-2">Filter by Date</label>
                        <div className="row">
                          <div className="col-md-2 col-sm-12 mr-md-2 mb-2">
                            <DatePicker
                              placeholderText="From"
                              selected={start_date}
                              onChange={(e) =>
                                this.handleChangeDate("start_date", e)
                              }
                              className="w-100"
                            />
                          </div>
                          <div className="col-md-2 col-sm-12 mb-2">
                            <DatePicker
                              placeholderText="To"
                              selected={end_date}
                              onChange={(e) =>
                                this.handleChangeDate("end_date", e)
                              }
                              className="w-100"
                            />
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <button
                              className="btn btn-primary mr-2"
                              onClick={this.handleSubmitKeyword}
                            >
                              <i className="mdi mdi-filter" /> Filter
                            </button>
                            {(start_date || end_date) && (
                              <button
                                className="btn btn-secondary"
                                onClick={this.handleClearKeyword}
                              >
                                <i className="mdi mdi-close" /> Clear Filter
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#unprinted"
                        role="tab"
                        aria-controls="unprinted"
                        aria-selected="true"
                        onClick={() => this.handleChangeTab(false)}
                      >
                        Uncompleted DN
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#printed"
                        role="tab"
                        aria-controls="printed"
                        aria-selected="false"
                        onClick={() => this.handleChangeTab(true)}
                      >
                        Completed DN
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="unprinted"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <Table
                        theads={theads}
                        ordering={ordering}
                        handleSorting={this.handleSorting}
                      >
                        {fetching ? (
                          <tr>
                            <td className="text-center" colSpan="8">
                              Loading...
                            </td>
                          </tr>
                        ) : dn && dn.data.length > 0 ? (
                          dn && dns
                        ) : (
                          <tr>
                            <td className="text-center" colSpan="8">
                              Data not found
                            </td>
                          </tr>
                        )}
                      </Table>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="printed"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <Table
                        theads={theads}
                        ordering={ordering}
                        handleSorting={this.handleSorting}
                      >
                        {fetching ? (
                          <tr>
                            <td className="text-center" colSpan="8">
                              Loading...
                            </td>
                          </tr>
                        ) : dn && dn.data.length > 0 ? (
                          dn && dns
                        ) : (
                          <tr>
                            <td className="text-center" colSpan="8">
                              Data not found
                            </td>
                          </tr>
                        )}
                      </Table>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 py-3">
                  <div className="row">
                    <div className="col-md-10">
                      {dn && dn.total > 1 && (
                        <p>
                          Showing {dn && dn.from.toLocaleString()} to{" "}
                          {dn && dn.to.toLocaleString()} of{" "}
                          {dn && dn.total.toLocaleString()} record(s)
                        </p>
                      )}

                      {dn && dn.total > 1 && (
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            {dn && dn.current_page > 1 && (
                              <li key="prev" className="page-item">
                                <button
                                  onClick={this.handleClickPage.bind(
                                    null,
                                    dn && dn.current_page - 1
                                  )}
                                  className="page-link"
                                >
                                  Prev
                                </button>
                              </li>
                            )}

                            {dn &&
                              dn.pages.map((page, index) => {
                                return (
                                  <li
                                    key={index}
                                    className={`page-item ${
                                      page === "..." ? "disabled" : ""
                                    } ${
                                      page === dn && dn.current_page
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <button
                                      onClick={this.handleClickPage.bind(
                                        null,
                                        page
                                      )}
                                      className="page-link"
                                    >
                                      {page}
                                    </button>
                                  </li>
                                );
                              })}

                            {dn && dn.current_page < dn && dn.last_page && (
                              <li key="next" className="page-item">
                                <button
                                  onClick={this.handleClickPage.bind(
                                    null,
                                    dn && dn.current_page + 1
                                  )}
                                  className="page-link"
                                >
                                  Next
                                </button>
                              </li>
                            )}
                          </ul>
                        </nav>
                      )}
                    </div>
                    <div className="col-md-2 text-right">
                      <div className="form-group">
                        <label className="control-label">
                          Showing per page{" "}
                        </label>
                        <select
                          defaultValue="10"
                          id="perpage"
                          className="form-control custom-select"
                          onChange={this.hanldeChangePage}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    dn: state.preparationDelivery.data,
    error: state.preparationDelivery.error,
    fetching: state.loading.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPreparationDelivery: (filter) =>
      dispatch(fetchPreparationDelivery(filter)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(PreparationDelivery));
