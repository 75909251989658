import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, FormSelect } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { appName, url } from '../global';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Error403 from './Error403';
import { connect } from 'react-redux';
import { fetchOrderADMSystemReport, fetchSummary } from '../store/actions/orderADMSystemReportAction';
import Table from '../components/table/Table';
import RangeDatePicker from '../components/common/RangeDatePicker';
import Modal from 'react-bootstrap4-modal';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import _ from 'underscore'

class OrderADMSystemReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            perpage: 10,
            keyword: null,
            fetching: false,
            download: false,
            error: null,
            ordering: {
                type: 'created_at',
                sort: 'desc'
            },
            exportIsOpen: false,
            filter: {
                startDate: '',
                endDate: '',
            }
        };
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleExport = this.handleExport.bind(this)
    }

    handleSorting = (e) => {
        const type = e.target.id;
        const sort = this.state.ordering.sort;
        this.setState({
            ...this.state,
            ordering: {
                type: type,
                sort: sort === 'asc' ? 'desc' : 'asc'
            }
        });
    }

    handleChangeKeyword = (e) => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    }

    handleSubmitKeyword = (e) => {
        e.preventDefault();
        this.props.fetchOrderADMSystemReport({...this.state, page: 1});
    }

    handleClickPage = (e) => {
        this.setState({
            ...this.state,
            page: e
        });
    }

    hanldeChangePage = (e) => {
        this.setState({
            ...this.state,
            perpage: e.target.value
        });
        this.props.fetchOrderADMSystemReport({...this.state, page: 1});
    }

    handleOpenExport = () => {
        this.setState({
            ...this.state,
            exportIsOpen: true
        })
    }

    handleCloseExport = () => {
        this.setState({
            ...this.state,
            exportIsOpen: false,
            filter: {
                startDate: '',
                endDate: '',
            }
        })
    }

    // handleDateRangeChange = (startDate, endDate) => {
    //     this.setState({
    //         ...this.state,
    //         filter: {
    //             ...this.state.filter,
    //             startDate,
    //             endDate
    //         }
    //     });

    //     if (this.state.filter.startDate) {
	// 		this.props.fetchOrderADMSystemReport({ ...this.state, filter: { ...this.state.filter, endDate } })
	// 	}
    // }

    handleDateRangeChange = (startDate, endDate) => {
        this.setState((prevState) => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                startDate: startDate || prevState.filter.startDate, // Keep existing startDate if not updated
                endDate: endDate || prevState.filter.endDate,       // Keep existing endDate if not updated
            }
        }), () => {
            // Check if both dates are set before proceeding
            if (this.state.filter.startDate && this.state.filter.endDate) {
                this.props.fetchOrderADMSystemReport({ ...this.state });
            }
        });
    };

    handleExport() {
        this.setState({
            ...this.state,
            download: true
        });

        Axios.get(`${url}/monitoring-order/export`, {
            params: {
                filter: this.state.filter,
                // startDate: this.state.filter.startDate,
                // endDate: this.state.filter.endDate,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            responseType: 'blob',
        }).then(response => {
            this.setState({
                ...this.state,
                download: false
            });
            fileDownload(response.data, `monitoring_order_report.xlsx`);
        }).catch(error => {
            this.setState({
                ...this.state,
                download: false
            });
            console.log(error.response, 'ini error')
            // this.props.toastManager.add(error.response.data.message, {
			// 	appearance: 'error',
			// 	autoDismiss: true
			// });
        })
    }

    formatedDate = (dateString) => {
        var date = new Date(dateString);
        if (dateString === '') {
            date = new Date();
        }
        return date.toISOString().split('T')[0];
    }

    componentDidMount = () => {
        this.props.fetchOrderADMSystemReport(this.state);
        this.props.fetchSummary(this.state);
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.page !== nextState.page) {
            this.props.fetchOrderADMSystemReport(nextState);
        }

        if (this.state.perpage !== nextState.perpage) {
            this.props.fetchOrderADMSystemReport(nextState);
        }

        if (this.state.ordering !== nextState.ordering) {
            this.props.fetchOrderADMSystemReport(nextState);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.payload !== this.props.payload) {
            const data = this.props.payload.data && this.props.payload.data.data.map(item => {
                return {
                    id: item._id,
                    isChecked: false
                }
            })

            this.setState({
                ...this.state,
                checked: data
            })
        }
    }

    render() {
        const { ordering } = this.state
        const { payload, error, fetching, summary } = this.props;
        if (!localStorage.getItem('token')) return <Redirect to="/login" />
        if (error && error.status === 500) return <Error500 message={error.data.message} />
        if (error && error.status === 403) return <Error403 message={error.data.message} />
        const theads = [
            { name: 'delivery_note_id', 'value': `DN`, sortable: true },
            { name: 'date', 'value': `DN Date`, sortable: true },
            { name: 'job_number', 'value': `Module Number`, sortable: true },
            { name: 'material_number', 'value': `Part Number`, sortable: true },
            { name: 'sequence', 'value': `Sequence`, sortable: true },
            { name: 'qty_box', 'value': `Qty`, sortable: true },
            { name: 'plan_actual', 'value': `Plan/Actual`, sortable: false },
            { name: 'scan_shopping_at', 'value': `Shopping Check`, sortable: true },
            { name: 'scan_qc_at', 'value': `Quality Check`, sortable: true },
            { name: 'preparation_delivery', 'value': `Preparation Delivery`, sortable: true },
            { name: 'status', 'value': `Status`, sortable: false },
        ];

        const getStatusClassName = (status) => {
            switch (status) {
                case 'Close':
                    return 'status-close';
                case 'Waiting Preparation Delivery':
                    return 'status-waiting-preparation-delivery';
                case 'Waiting Shopping Check':
                    return 'status-waiting-shopping-check';
                case 'Waiting Quality Check':
                    return 'status-waiting-quality-check';
                case 'Close With Delay':
                    return 'status-close-with-delay';
                case 'Delay':
                    return 'status-delay';
                default:
                    return '';
            }
        };

        const reports = payload.data &&
        payload.data.data.map((report, index) => {
                return (
                    <React.Fragment key={index}>
                        <tr>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{report.dn_number}</td>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{new Date(report.date).toLocaleDateString("id-ID", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            })}</td>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{report.module}</td>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{report.part_number}</td>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{report.sequence}</td>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{report.qty}</td>
                            <td>PLAN</td>
                            <td style={{ verticalAlign: 'middle' }}>{new Date(report.date).toLocaleDateString("id-ID", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            })}</td>
                            <td style={{ verticalAlign: 'middle' }}>{new Date(report.date).toLocaleDateString("id-ID", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            })}</td>
                            <td style={{ verticalAlign: 'middle' }}>{new Date(report.date).toLocaleDateString("id-ID", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            })}</td>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }} className={getStatusClassName(report.status)}>{report.status}</td>
                        </tr>
                        <tr>
                            <td>ACTUAL</td>
                            <td style={{ verticalAlign: 'middle' }}>{report.shopping_check}</td>
                            <td style={{ verticalAlign: 'middle' }}>{report.quality_check}</td>
                            <td style={{ verticalAlign: 'middle' }}>{report.preparation_delivery}</td>
                        </tr>
                    </React.Fragment>    
                )
        })

        return (

            <Container fluid className="main-content-container px-4">
                <Loading
                    show={fetching}
                    color="blue"
                    showSpinner={false}
                />
                <Helmet>
                    <title>Order ADM System Report | {appName} </title>
                </Helmet>
                <Row noGutters className="page-header py-4">
                    <div className="col-md-8">
                        <PageTitle sm="12" title="Order ADM System Report" className="text-sm-left" />
                    </div>
                </Row>
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardBody className="p-0 pb-3">
                                <div className="card-custom">
                                    <div className="card-custom-content">
                                        <h1 className="card-custom-title">DN Outstanding</h1>
                                        <div className="card-custom-description">
                                            <div class="card-custom-value">
                                            <span>{summary && summary.delivery_notes ? summary.delivery_notes.outstanding : 0}</span>/{summary && summary.delivery_notes ? summary.delivery_notes.total : 0}
                                            </div>
                                            <i className="mdi mdi-file-document" />
                                        </div>
                                    </div>
                                    <div className="card-custom-content">
                                        <h1 className="card-custom-title">Module Outstanding</h1>
                                        <div className="card-custom-description">
                                            <div class="card-custom-value">
                                            <span>{summary && summary.modules ? summary.modules.outstanding : 0}</span>/{summary && summary.modules ? summary.modules.total : 0}
                                            </div>
                                            <i className="mdi mdi-format-list-checkbox" />
                                        </div>
                                    </div>
                                    <div className="card-custom-content">
                                        <h1 className="card-custom-title">Shopping Check</h1>
                                        <div className="card-custom-description">
                                            <div class="card-custom-value">
                                            <span>{summary && summary.scans ? summary.scans.shopping_check : 0}</span>/{summary && summary.scans ? summary.scans.total : 0}
                                            </div>
                                            <i className="mdi mdi-file-document-box-outline" />
                                        </div>
                                    </div>
                                    <div className="card-custom-content">
                                        <h1 className="card-custom-title">Quality Check Process</h1>
                                        <div className="card-custom-description">
                                            <div class="card-custom-value">
                                            <span>{summary && summary.scans ? summary.scans.quality_check : 0}</span>/{summary && summary.scans ? summary.scans.total : 0}
                                            </div>
                                            <i className="mdi mdi-file-document-box" />
                                        </div>
                                    </div>
                                    <div className="card-custom-content">
                                        <h1 className="card-custom-title">Preparation Delivery</h1>
                                        <div className="card-custom-description">
                                            <div class="card-custom-value">
                                            <span>{summary && summary.scans ? summary.scans.preparation_delivery : 0}</span>/{summary && summary.scans ? summary.scans.total : 0}
                                            </div>
                                            <i className="mdi mdi-dump-truck" />
                                        </div>
                                    </div>
                                    <div className="card-custom-content">
                                        <h1 className="card-custom-title">Delay</h1>
                                        <div className="card-custom-description">
                                            <div class="card-custom-value">
                                            <span>{summary && summary.scans ? summary.scans.delays : 0}</span>
                                            </div>
                                            <i className="mdi mdi-close" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 mt-3'>
                                    <div className='row justify-content-between'>
                                        <div className='col-md-4 d-flex'>
                                            <div className="d-flex flex-column justify-content-center w-100">
                                                <label className="mb-1">Delivery Date</label>
                                                <RangeDatePicker onDateRangeChange={this.handleDateRangeChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 d-flex align-items-center justify-content-end">
                                            <button className="btn btn-secondary" onClick={this.handleOpenExport}>
                                                <i className="mdi mdi-file-excel" /> Export
                                            </button>
                                        </div>
                                        <div className="col-md-4 d-flex align-items-center justify-content-end">
                                            <form onSubmit={this.handleSubmitKeyword} className="w-100">
                                                <div className="input-group">
                                                    <input
                                                        id="keyword"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        aria-label="Search input with button"
                                                        aria-describedby="button-addon1"
                                                        onChange={this.handleChangeKeyword}
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-secondary"
                                                            type="submit"
                                                            id="button-addon1"
                                                        >
                                                            <i className="mdi mdi-magnify" /> Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-4 mb-5">
									<Table
										theads={theads}
										ordering={ordering}
										handleSorting={this.handleSorting}
                                        headerStyle={headerStyle}
									>
										{fetching ? (
											<tr>
												<td className="text-center" colSpan="16">
													Loading...
												</td>
											</tr>
										) : payload.data && payload.data.data.length > 0 ? (
											reports
										) : (
											<tr>
												<td className="text-center" colSpan="16">
													Data not found
												</td>
											</tr>
										)}
									</Table>
								</div>
                                <div className="col-md-12 py-3">
									<div className="row">
										<div className="col-md-10">
											{payload.data && payload.data.total > 1 && (
												<p>
													Showing{" "}
													{payload.data && payload.data.from.toLocaleString()}{" "}
													to {payload.data && payload.data.to.toLocaleString()}{" "}
													of{" "}
													{payload.data && payload.data.total.toLocaleString()}{" "}
													record(s)
												</p>
											)}

											{payload.data && payload.data.total > 1 && (
												<nav aria-label="Page navigation example">
													<ul className="pagination">
														{payload.data.current_page > 1 && (
															<li key="prev" className="page-item">
																<button
																	onClick={this.handleClickPage.bind(
																		null,
																		payload.data.current_page - 1
																	)}
																	className="page-link"
																>
																	Prev
																</button>
															</li>
														)}

														{payload.data.pages.map((page, index) => {
															return (
																<li
																	key={index}
																	className={`page-item ${page === "..." ? "disabled" : ""
																		} ${page === payload.data.current_page
																			? "active"
																			: ""
																		}`}
																>
																	<button
																		onClick={this.handleClickPage.bind(
																			null,
																			page
																		)}
																		className="page-link"
																	>
																		{page}
																	</button>
																</li>
															);
														})}

														{payload.data.current_page <
															payload.data.last_page && (
																<li key="next" className="page-item">
																	<button
																		onClick={this.handleClickPage.bind(
																			null,
																			payload.data.current_page + 1
																		)}
																		className="page-link"
																	>
																		Next
																	</button>
																</li>
															)}
													</ul>
												</nav>
											)}
										</div>
										<div className="col-md-2 text-right">
											<div className="form-group">
												<label className="control-label">
													Showing per page{" "}
												</label>
												<select
													defaultValue="10"
													id="perpage"
													className="form-control custom-select"
													onChange={this.hanldeChangePage}
												>
													<option value="10">10</option>
													<option value="20">20</option>
													<option value="50">50</option>
													<option value="100">100</option>
												</select>
											</div>
										</div>
									</div>
								</div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* modals */}
                <Modal visible={this.state.exportIsOpen} onClickBackdrop={this.handleCloseExport}>
                    <div className="modal-header">
                        <h5 className="modal-title">Export</h5>
                    </div>
                    <div className="modal-body py-0 pt-2 px-4">
                        <div className='form-group'>
                            <label>Date Range</label>
                            <RangeDatePicker onDateRangeChange={this.handleDateRangeChange} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.handleCloseExport}>
                            Close
                        </button>
                        {
                            this.state.download ? (
                                <button className="btn btn-secondary btn-disabled" disabled>
                                    <i className="mdi mdi-loading mdi-spin"></i> Loading...
                                </button>
                            ) : (
                                <button className="btn btn-secondary" onClick={this.handleExport}>
                                    <i className="mdi mdi-printer"></i> Export
                                </button>
                            )
                        }
                    </div>
                </Modal>

            </Container>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        payload: state.orderADMSystemReport.payload,
        error: state.orderADMSystemReport.error,
        fetching: state.orderADMSystemReport.fetching,
        summary: state.orderADMSystemReport.summary,
        message: state.orderADMSystemReport.message,
        saved: state.orderADMSystemReport.saved,
        isDeleted: state.orderADMSystemReport.isDeleted
    }
}

const headerStyle = { backgroundColor: "#038cfc", color: "#fff" }; // Blue background with white text

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrderADMSystemReport: (filter) => dispatch(fetchOrderADMSystemReport(filter)),
        fetchSummary: (filter) => dispatch(fetchSummary(filter)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(OrderADMSystemReport));