import React from 'react';

class Table extends React.Component {
    render() {

        const { theads, ordering, handleSorting, style, headerStyle } = this.props;

        const tHead = theads.map((head, index) => {
            return (
                <th scope="col" key={index} className={head.className && head.className} id={head.name} style={headerStyle} onClick={handleSorting}>
                    {head.value}
                    {
                        head.sortable && (
                            <div className={`table-sorting ${ordering.type === head.name && 'active'}`} >
                                {
                                    ordering.type === head.name ?
                                        (<i className={`mdi mdi-${ordering.sort === 'asc' ? 'sort-ascending' : 'sort-descending'}`} id={head.name}></i>)
                                        :
                                        (<i className="mdi mdi-sort" id={head.name}></i>)
                                }

                            </div>
                        )
                    }
                </th>
            )
        })

        return (
            <div className="row">
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table table-bordered table-custom" style={style && style}>
                            <thead>
                                <tr>
                                    {tHead}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.children}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        )
    }
}

export default Table;