import Axios from "axios";
import { url } from "../../global";
import moment from "moment";

export const scan = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCHING_START",
    });

    const dataParsed = data.split("|");

    if (dataParsed.length > 10) {
      const dataCollect = {
        lot_id_coil: dataParsed[0],
        spec: dataParsed[1],
        size_1: dataParsed[2],
        size_2: dataParsed[3],
        size_3: dataParsed[4],
        customer_name: dataParsed[5],
        part_name: dataParsed[6],
        part_no: dataParsed[7],
        qty: dataParsed[9],
        weight: dataParsed[10],
      };

      await Axios.post(`${url}/scan-receiving`, dataCollect, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          dispatch({
            type: "SCAN_RECEIVING",
            payload: res.data.data,
          });

          dispatch({
            type: "CHANGE_LABEL",
            payload: "",
          });
        })
        .catch((err) => {
          dispatch({
            type: "SCAN_RECEIVING_FAILED",
            payload: err.response,
          });
          dispatch({
            type: "CHANGE_LABEL",
            payload: "",
          });
        });
    } else {
      dispatch({
        type: "SCAN_RECEIVING_FAILED",
        payload: {
          data: {
            errors: {
              lot_id_coil: ["Bad QR Format"],
            },
          },
        },
      });

      dispatch({
        type: "CHANGE_LABEL",
        payload: "",
      });
    }
    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};

export const onChange = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "CHANGE_LABEL",
      payload: data,
    });
  };
};

export const onChangeAutocomplete = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "CHANGE_LABEL_AUTOCOMPLETE",
      payload: data,
    });
  };
};

export const onChangePartId = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "CHANGE_PART_ID",
      payload: data,
    });
  };
};

export const fetchReceiving = (filter) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "FETCHING_START",
    });
    const receivings = await Axios.get(`${url}/receiving`, {
      params: {
        page: filter.page,
        perpage: filter.perpage,
        keyword: filter.keyword,
        ordering: filter.ordering,
        start_date: filter.start_date
          ? moment(filter.start_date).format("YYYY-MM-DD")
          : null,
        end_date: filter.end_date
          ? moment(filter.end_date)
              .add(1, "day")
              .format("YYYY-MM-DD")
          : null,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    console.log(receivings.data.data, "data");
    dispatch({
      type: "FETCH_RECEIVING",
      payload: receivings.data.data,
    });

    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};

export const getLot = (data, cb, isSpecial = false) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCHING_START",
    });

    const dataParsed = data.split("|");

    if (dataParsed.length > 10 || isSpecial) {
      await Axios.get(`${url}/get-lot`, {
        params: {
          lot_id_coil: dataParsed[0],
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          cb(true);
          dispatch({
            type: "SET_RECEIVING",
            payload: res.data.data,
          });
          dispatch({
            type: "CLEAR_LOT",
          });
          if (isSpecial) {
            dispatch({
              type: "SET_LOT_AUTOCOMPLETE",
              payload: [],
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "SCAN_RECEIVING_FAILED",
            payload: err.response,
          });
          dispatch({
            type: "CHANGE_LABEL",
            payload: "",
          });
        });
    } else {
      dispatch({
        type: "SCAN_RECEIVING_FAILED",
        payload: {
          data: {
            errors: {
              lot_id_coil: ["Bad QR Format"],
            },
          },
        },
      });

      dispatch({
        type: "CHANGE_LABEL",
        payload: "",
      });
    }

    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};

export const getPart = (value, cb) => {
  return async (dispatch, getState) => {
    dispatch({
      type: "FETCHING_START",
    });

    await Axios.get(`${url}/get-part`, {
      params: {
        code: value,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res, "res");
        console.log(getState(), "getState");
        cb(true, res.data.data);
        dispatch({
          type: "SET_PART",
          payload: res.data.data,
        });
        dispatch({
          type: "GET_LABEL_PRODUCT",
          payload: {
            ...getState().labelProduct.labelProductData,
            part_number: res.data.data.code,
            part_name: res.data.data.name,
            part_type: res.data.data.type,
            part_model: res.data.data.model,
            lot_id: getState().receiving.selectedReceiving.lot_id_coil,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SCAN_RECEIVING_FAILED",
          payload: err.response,
        });
        dispatch({
          type: "CHANGE_LABEL",
          payload: "",
        });
      });

    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};

export const getLotAutocomplete = (data, cb) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCHING_START",
    });

    await Axios.get(`${url}/get-lot-like`, {
      params: {
        lot_id_coil: data,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        cb(true);
        dispatch({
          type: "SET_LOT_AUTOCOMPLETE",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SCAN_RECEIVING_FAILED",
          payload: err.response,
        });
        dispatch({
          type: "CHANGE_LABEL",
          payload: "",
        });
      });

    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};
