import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, FormCheckbox, FormSelect } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { Redirect } from 'react-router-dom';
import { appName, customerStyles, url } from '../global';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../components/layout/ScrollToTop';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Table from '../components/table/Table';
import Modal from 'react-bootstrap4-modal';
import AsyncSelect from "react-select/async";
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Error403 from './Error403';
import fileDownload from 'js-file-download';
import { deleteQrcodeProduction, fetchQrcodeProduction, getFilterData, setQrProductionPayload } from '../store/actions/qrcodeProductionAction';
import RangeDatePicker from '../components/common/RangeDatePicker';
import moment from 'moment';

class QrProduction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			search: null,
			page: 1,
			perpage: 10,
			keyword: null,
			alert: true,
			alertMsgBox: false,
			deleteIdQrcode: null,
			showMsgBox: false,
			isDeleted: false,
			ordering: {
				type: 'prod_date',
				sort: 'desc'
			},
			modal: false,
			file_import: '',
			import: 'Choose file...',
			importing: false,
			checked: [],
			download: false,
			modalPrint: false,
			template: 'ADM',
			downloadLoading: false,
			printed: false,
			model: null,
			type: null,
			customer_id: null,
			startDate: null,
			endDate: null,
			isShowModalGenerate: false,
			generating: false,
			generateParams: {
				id_sap: null,
				supplier_code: null,
				supplier_name: null,
				plant: 'HPM',
				order_from: null,
				order_to: null,
			},
			printParams: {
				isNew: false,
				paper: 'A4',
				customer: 'HPM',
				id: ''
			},
			detail: {
				id: null,
				data: [],
				loading: false,
				open: false,
				page: 1,
				perpage: 10,
				keyword: null,
				ordering: {
					type: 'created_at',
					sort: 'asc'
				},
				checked: [],
				print: false,
				printParams: {
					isNew: false,
					paper: 'Thermal',
					customer: 'HPM',
					id: ''
				},
			}
		}

		this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
		this.handleProdDateRangeChange = this.handleProdDateRangeChange.bind(this);
	}

	copyDebounceTimer = null;

	handleSorting = (e) => {
		const type = e.target.id;
		const sort = this.state.ordering.sort;
		this.setState({
			...this.state,
			ordering: {
				type: type,
				sort: sort === 'asc' ? 'desc' : 'asc'
			}
		});
	}

	handleDetailSorting = (e) => {
		const type = e.target.id;
		const sort = this.state.detail.ordering.sort;
		this.setState({
			...this.state,
			detail: {
				...this.state.detail,
				ordering: {
					type: type,
					sort: sort === 'asc' ? 'desc' : 'asc'
				}
			}
		});
	}

	handleChangeKeyword = (e) => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
	}

	handleSubmitKeyword = (e) => {
		e.preventDefault();
		this.props.fetchQrcode(this.state);
	}

	handleClickPage = (e) => {
		this.setState({
			...this.state,
			page: e
		});
	}

	handleClickDetailPage = (e, id) => {
		this.setState({
			...this.state,
			detail: {
				...this.state.detail,
				page: e
			}
		});
		this.getDetails(id);
	}

	hanldeChangePage = (e) => {
		this.setState({
			...this.state,
			perpage: e.target.value
		});
	}

	handleChangeDetailPage = (e, id) => {
		this.setState({
			...this.state,
			detail: {
				...this.state.detail,
				perpage: e.target.value
			}
		});

		this.getDetails(id);
	}

	handleClickDelete = (id) => {
		this.setState({
			...this.state,
			deleteIdQrcode: id,
			showMsgBox: true
		});
	}

	handleClickYes = () => {

		this.setState({
			...this.state,
			alertMsgBox: true,
			showMsgBox: false,
			isDeleted: true
		});

		this.props.deleteQrcode(this.state.deleteIdQrcode);
	}

	handleClickNo = () => {
		this.setState({
			...this.state,
			showMsgBox: false,
			deleteIdQrcode: null
		});
	}

	handlePopupPrint = (e, id = '') => {
		this.setState({
			...this.state,
			modalPrint: true,
			...(id && { printParams: { ...this.state.printParams, id } })
		});
	}

	handlePopupPrintDetail = (e, id = '') => {
		this.setState(prevState => {
			const updatedDetail = {
				...prevState.detail,
				print: true,
			};

			if (id) {
				updatedDetail.printParams = { ...prevState.detail.printParams, id };
			}

			return { detail: updatedDetail };
		});
	}

	componentWillUpdate(nextProps, nextState) {
		if (this.state.page !== nextState.page) {
			this.props.fetchQrcode(nextState);
		}

		if (this.state.perpage !== nextState.perpage) {
			this.props.fetchQrcode(nextState);
		}

		if (this.state.ordering !== nextState.ordering) {
			this.props.fetchQrcode(nextState);
		}

		if (this.state.printed !== nextState.printed) {
			this.props.fetchQrcode(nextState);
		}

		// if (this.state.detail.open !== nextState.detail.open) {
		// 	this.getDetails()
		// }

		// if (this.state.detail.page !== nextState.detail.page) {
		// 	this.getDetails()
		// }
	}

	componentDidUpdate = (prevProps, prevState) => {

		if (prevProps.error !== this.props.error) {
			if (!this.props.fetched) {
				if (this.props.error) {
					const { toastManager } = this.props;
					toastManager.add(this.props.error.data.message, {
						appearance: 'error',
						autoDismiss: true
					});
				}
			}
		}

		if (prevProps.isDeleted !== this.props.isDeleted) {
			if (this.props.isDeleted) {
				const { toastManager } = this.props;
				toastManager.add(this.props.message, {
					appearance: 'success',
					autoDismiss: true
				});
				this.props.fetchQrcode(this.state);
			}
		}

		if (prevProps.payload !== this.props.payload) {
			const data = this.props.payload.data && this.props.payload.data.data.map(item => {
				return {
					id: item._id,
					part_number: item.part_number,
					description: item.description,
					qty: item.qty,
					lot_id: item.lot_id,
					isChecked: false,
					copies: item.copies,
					print_status: item.print_status,
				}
			})

			this.setState({
				...this.state,
				checked: data
			})

		}
	}

	handleModal = () => {
		this.setState({
			...this.state,
			modal: true
		})
	}

	modalBackdropClicked = () => {
		this.setState({
			...this.state,
			modal: false,
			modalPrint: false,
			isShowModalGenerate: false,
			generateParams: {
				...this.state.generateParams,
				order_from: null,
				order_to: null,
			},
			printParams: {
				...this.state.printParams,
				id: '',
			},
			detail: {
				...this.state.detail,
				print: false,
				printParams: {
					...this.state.detail.printParams,
					id: ''
				}
			},
		});
	}

	componentDidMount = () => {
		this.props.fetchQrcode(this.state)
		this.props.getFilterData()
	}

	handleChangeFileImport = (e) => {

		if (e.target.files.length > 0) {
			const file = e.target.files[0]
			this.setState({
				...this.state,
				import: file.name,
				file_import: file
			})
		}

	}

	handleImport = () => {
		const { toastManager } = this.props;

		this.setState({
			...this.state,
			importing: true
		});

		const fd = new FormData;
		fd.append('file', this.state.file_import);

		Axios.post(`${url}/qr-code-production/import`, fd,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
					'Conten-Type': 'multipart/form-data'
				}
			}).then(res => {

				this.setState({
					...this.state,
					modal: false,
					importing: false,
					file_import: '',
					import: 'Choose file...'
				});

				toastManager.add(res.data.message, {
					appearance: 'success',
					autoDismiss: true
				});

				this.fileImport.value = ''
				this.props.fetchQrcode(this.state);

			})
			.catch(error => {

				this.setState({
					...this.state,
					modal: false,
					importing: false,
					file_import: '',
					import: 'Choose file...'
				});

				this.fileImport.value = ''

				toastManager.add(error.response.data.message, {
					appearance: 'error',
					autoDismiss: true
				});

			});
	}

	handleCheckBox = (event) => {

		let checked = this.state.checked

		checked.forEach(checked => {
			if (checked.id === event.target.value)
				checked.isChecked = event.target.checked
		})

		this.setState({
			...this.state,
			checked: checked
		})
	}

	handleDetailCheckBox = (event) => {
		let checked = this.state.detail.checked
		checked.forEach(c => {
			if (c.id === event.target.value)
				c.isChecked = event.target.checked
		})

		this.setState({
			...this.state,
			detail: {
				...this.state.detail,
				checked
			}
		})
	}

	handleCopies = (event) => {
		const { toastManager, payload, setPayload } = this.props;
		let id = event.target.dataset.value;
		let copies = event.target.value;
		const newPayload = payload.data.data.map((data) => data._id === id ? data.copies = copies : data)

		setPayload({ ...payload, newPayload })
		clearTimeout(this.copyDebounceTimer);
		this.copyDebounceTimer = setTimeout(() => {
			Axios.put(`${url}/qr-code-production/${id}`, {
				copies
			}, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			}).then(res => {
				toastManager.add(res.data.message, {
					appearance: 'success',
					autoDismiss: true
				});
			}).catch(error => {
				toastManager.add(error.response.data.message, {
					appearance: 'error',
					autoDismiss: true
				});
			})

			let checked = this.state.checked
			checked.forEach(checked => {
				if (checked.id === id)
					checked.copies = copies
			})

			this.setState({ ...this.state, checked: checked })
		}, 500)
	}

	handleCheckAll = () => {
		let checked = this.state.checked
		checked.forEach(check => check.isChecked = true)
		this.setState({ checked: checked })
	}

	handleUnCheckAll = () => {
		let checked = this.state.checked
		checked.forEach(check => check.isChecked = false)
		this.setState({ checked: checked })
	}

	handleToggleCheck = () => {

		let checked = this.state.checked

		checked.forEach(checked => {
			checked.isChecked = checked.isChecked ? false : true
		})

		this.setState({ checked: checked })
	}

	handleChangeTemplate = (e) => {
		this.setState({
			...this.state,
			template: e.target.value
		})
	}

	handleChangeSelect = (e) => {
		const { name, value } = e.target;
		const newState = {
			...this.state,
			[name]: value !== '' ? value : null
		};
		this.setState(newState);
		this.props.fetchQrcode(newState);
	}

	handlePrintSelect = (e) => {
		const { name, value } = e.target;
		const newState = {
			...this.state,
			printParams: {
				...this.state.printParams,
				[name]: value !== '' ? value : null
			}
		};
		this.setState(newState);
	}

	handlePrintDetailSelect = (e) => {
		const { name, value } = e.target;
		const newState = {
			...this.state,
			detail: {
				...this.state.detail,
				printParams: {
					...this.state.detail.printParams,
					[name]: value !== '' ? value : null
				}
			}
		};
		this.setState(newState);
	}

	handleChangePrintCheckbox = (e, item) => {
		this.setState({
			...this.state,
			printParams: {
				...this.state.printParams,
				[item]: !this.state.printParams[item]
			}
		});
	}

	handleDateRangeChange(startDate, endDate) {
		const newState = {
			...this.state,
			startDate,
			endDate
		}
		this.setState(newState);
		if (this.state.startDate) {
			this.props.fetchQrcode(newState);
		}
	}

	handleProdDateRangeChange(startDate, endDate) {
		const newState = {
			...this.state,
			generateParams: {
				...this.state.generateParams,
				order_from: moment(startDate).format('Y-mM-D'),
				order_to: moment(endDate).format('Y-mM-D')
			}
		}
		this.setState(newState);
	}

	handlePrint = async () => {
		const { toastManager } = this.props;
		this.setState({
			...this.state,
			download: true
		});

		await Axios.post(`${url}/qr-code-production/print`, {
			check: this.state.checked,
			isNew: this.state.printParams.isNew,
			customer: this.state.printParams.customer,
			paper: this.state.printParams.paper,
			id: this.state.printParams.id
		}, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			}
		}).then(response => {
			this.modalBackdropClicked();
			this.setState({
				...this.state,
				download: false
			});

			const file = new Blob([response.data], { type: 'application/pdf' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);

			this.setState({
				...this.state,
				modalPrint: false
			});

			this.props.fetchQrcode(this.state);

		}).catch(error => {

			this.setState({
				...this.state,
				download: false
			});

			if (error.response) {

				toastManager.add(error.response.statusText, {
					appearance: 'error',
					autoDismiss: true
				});
			}


		})
	}

	handlePrintDetail = async () => {
		const { toastManager } = this.props;
		this.setState({
			...this.state,
			download: true
		});

		await Axios.post(`${url}/qr-code-production/detail/print`, {
			check: this.state.detail.checked,
			customer: this.state.detail.printParams.customer,
			paper: this.state.detail.printParams.paper,
			id: this.state.detail.printParams.id,
		}, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			}
		}).then(response => {
			this.modalBackdropClicked();
			this.setState({
				...this.state,
				download: false
			});

			const file = new Blob([response.data], { type: 'application/pdf' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);

			this.setState({
				...this.state,
				detail: {
					...this.state.detail,
					print: false
				}
			});

			this.props.fetchQrcode(this.state);

		}).catch(error => {

			this.setState({
				...this.state,
				download: false
			});

			if (error.response) {

				toastManager.add(error.response.statusText, {
					appearance: 'error',
					autoDismiss: true
				});
			}
		});
	}


	handleDownloadTemplate = async () => {
		const { toastManager } = this.props;
		this.setState({
			...this.state,
			downloadLoading: true
		});

		await Axios.get(`${url}/qr-code-production/download-template`, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then(response => {

			fileDownload(response.data, 'template_qr.xlsx');

			this.setState({
				...this.state,
				downloadLoading: false
			});


		}).catch(error => {

			this.setState({
				...this.state,
				downloadLoading: false
			});


			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});

		})
	}

	handleChangeTab = (value) => {
		this.setState({
			...this.state,
			printed: value,
			page: 1,
		})
	}

	handleGenerateQrcode = (e) => {
		const { toastManager } = this.props
		const fd = new FormData;
		fd.append('order_from', this.state.generateParams.order_from && moment(this.state.generateParams.order_from, 'YYYY-MM-D').format('YYYY-MM-DD'));
		fd.append('order_to', this.state.generateParams.order_to && moment(this.state.generateParams.order_to, 'YYYY-MM-D').format('YYYY-MM-DD'));

		this.setState({
			...this.state,
			generating: true
		});
		Axios.post(`${url}/qr-code-production/generate`, fd,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
					'Conten-Type': 'multipart/form-data'
				}
			}).then(({ data }) => {
				this.modalBackdropClicked();
				if (data.type === 'success') {
					this.props.fetchQrcode(this.state);
					toastManager.add(data.message, {
						appearance: 'success',
						autoDismiss: true
					});

				} else {
					toastManager.add(data.message, {
						appearance: 'warning',
						autoDismiss: true
					});
				}
			}).catch((error) => {
				toastManager.add(error.response.data.message, {
					appearance: 'error',
					autoDismiss: true
				});
				this.modalBackdropClicked();
			}).finally(() => {
				this.setState({
					...this.state,
					generating: false
				});
			})
	}

	// handleDetail = (id) => {
	// 	this.setState({
	// 		...this.state,
	// 		detail: {
	// 			...this.state.detail,
	// 			id: id,
	// 			loading: true,
	// 			open: {
	// 				[id]: !this.state.detail.open[id] ? true : false
	// 			}
	// 		}
	// 	})
	// }

	getDetails = async (id) => {
		this.setState({
			...this.state,
			detail: {
				...this.state.detail,
				loading: true,
				open: {
					[id]: !this.state.detail.open[id] ? true : false
				}
			}
		})
		const params = {
			page: this.state.detail.page,
			perpage: this.state.detail.perpage,
			keyword: this.state.detail.keyword,
			ordering: this.state.detail.ordering,
		}
		await Axios.get(`${url}/qr-code-production/${id}/detail`, {
			params: {
				...params
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		}).then(res => {
			const data = { [id]: res.data.data }
			const checked = res.data.data && res.data.data.data && res.data.data.data.map(item => {
				return {
					id: item._id,
					code: item.code,
					isChecked: false
				}
			});

			this.setState({
				...this.state,
				detail: {
					...this.state.detail,
					loading: false,
					data,
					checked
				}
			})
		})
	}

	detailsTable = (id) => {
		const theads = [
			{ name: 'code', 'value': `Qr Code`, sortable: true },
			{ name: 'generated_at', 'value': `Generated At`, sortable: false },
			{ name: 'scanned', 'value': `Scan Status`, sortable: false },
			{ name: 'options', 'value': '', sortable: false }
		]

		const { ordering, data, loading, page, checked } = this.state.detail;
		const isSelected = checked && checked.filter(item => item.isChecked === true).length > 0;
		const details = data[id] && data[id].data.map((detail, index) => {
			const checked = this.state.detail.checked[index];
			return (
				<tr key={index}>
					<td>
						<FormCheckbox
							checked={checked && checked.isChecked ? true : false}
							value={detail._id}
							onChange={this.handleDetailCheckBox}
						>
							{detail.code}
						</FormCheckbox>
					</td>
					<td>{detail.qrcode.generated_at_formatted}</td>
					<td className={detail.scanned === true || detail.scan_qc_status === true ? 'bg-success' : ''}>{detail.scanned === true || detail.scan_qc_status === true ? 'Scanned' : ''}</td>
					<td className="text-center">
						<div className="btn-group">
							<button type="button" className="btn btn-lg p-0 m-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i className="mdi mdi-dots-vertical"></i>
							</button>
							<div className="dropdown-menu dropdown-menu-right">
								<button className='dropdown-item' onClick={(e) => this.handlePopupPrintDetail(e, detail._id)}><i className="mdi mdi-printer mr-2" />Print</button>
							</div>
						</div>
					</td>
				</tr>
			)
		})

		const detailsData = data[id];
		return (
			<tr>
				<td colSpan="18" className="py-0">
					<div className="row">
						<div className="col-12">
							<button className="btn btn-secondary mx-2" disabled={!isSelected} onClick={this.handlePopupPrintDetail}>
								<i className="mdi mdi-printer" /> Print selected
							</button>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<Table theads={theads} ordering={ordering} handleSorting={this.handleDetailSorting}>
								{
									loading ?
										(
											<tr>
												<td className="text-center" colSpan="4">
													<i className="mdi mdi-loading mdi-spin mr-2"></i>
													Loading ...
												</td>
											</tr>
										)
										:
										data && data[id].data ? details : (
											<tr>
												<td className="text-center" colSpan="4">Data not found</td>
											</tr>
										)
								}
							</Table>
						</div>
					</div>
					{/* <div className="row">
						<div className="col-md-12 py-3">
							<div className="row">
								<div className="col-md-10">
									{detailsData && detailsData && detailsData.total > 1 && (
										<p>Showing {detailsData && detailsData && detailsData.from.toLocaleString()} to {detailsData && detailsData && detailsData.to.toLocaleString()} of {detailsData && detailsData && detailsData && detailsData.total.toLocaleString()} record(s)</p>
									)}

									{
										detailsData && detailsData && detailsData && detailsData.total > 1 && (
											<nav aria-label="Page navigation example">
												<ul className="pagination">

													{detailsData && detailsData.current_page > 1 && <li key="prev" className="page-item"><button onClick={this.handleClickDetailPage.bind(null,detailsData && detailsData.current_page - 1)} className="page-link">Prev</button></li>}

													{
														detailsData && detailsData.pages.map((page, index) => {
															return (

																<li key={index} className={`page-item ${page === '...' ? 'disabled' : ''} ${page === detailsData && detailsData.current_page ? 'active' : ''}`}><button onClick={this.handleClickDetailPage.bind(null,page, id)} className="page-link">{page}</button></li>

															)
														})
													}

													{detailsData && detailsData.current_page < detailsData && detailsData.last_page && <li key="next" className="page-item"><button onClick={this.handleClickDetailPage.bind(null,detailsData && detailsData.current_page + 1, id)} className="page-link">Next</button></li>}


												</ul>
											</nav>
										)
									}
								</div>
								<div className="col-md-2 text-right">
									<div className="form-group">
										<label className="control-label">Showing per page </label>
										<select
											defaultValue="10"
											id="perpage"
											className="form-control custom-select"
											onChange={(e) => this.handleChangeDetailPage(e, id)}
										>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="50">50</option>
											<option value="100">100</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div> */}
				</td>
			</tr>
		)
	}

	render() {
		const { payload, error, fetching, filters } = this.props;
		if (!localStorage.getItem('token')) return <Redirect to="/login" />
		if (error && error.status === 500) return <Error500 message={error.data.message} />
		if (error && error.status === 403) return <Error403 message={error.data.message} />

		const isAllowedPrint = !(this.state.checked && this.state.checked.filter(item => item.isChecked === true && item.print_status === false).length > 0);
		const isSelected = this.state.checked && this.state.checked.filter(item => item.isChecked === true).length > 0;

		const { ordering, model, type, customer_id } = this.state;
		const theads = [
			{ name: 'material_number', 'value': `Material`, sortable: true },
			{ name: 'part_number', 'value': `Supplier Part Number`, sortable: true },
			{ name: 'description', 'value': 'Part Description', sortable: true },
			{ name: 'supplier_name', 'value': 'Supplier', sortable: true },
			{ name: 'customer', 'value': 'Customer', sortable: false },
			{ name: 'qty', 'value': 'Qty', sortable: true },
			{ name: 'std_packing', 'value': 'Standard Packing', sortable: false },
			{ name: 'total_label', 'value': 'Total Label', sortable: true },
			{ name: 'total_scanned', 'value': 'Total Scan', sortable: false },
			{ name: 'scan_remaining', 'value': 'Scan Remaining', sortable: false },
			{ name: 'reprint', value: 'Total Print', sortable: true },
			{ name: 'uom', 'value': 'UoM', sortable: true },
			{ name: 'part_group', 'value': 'Part Group', sortable: true },
			{ name: 'type_part', 'value': 'Part Type', sortable: true },
			{ name: 'production_order', 'value': 'No. Production Order', sortable: true },
			{ name: 'prod_date', 'value': 'Production Date', sortable: true },
			{ name: 'coil_id', 'value': 'Coil ID', sortable: true },
			{ name: 'copies', 'value': 'Copies', sortable: true },
			{ name: 'status', 'value': 'Status', sortable: false },
			{ name: 'options', 'value': '', sortable: false }
		];

		const qrcodes = payload.data && payload.data.data.map((qrcode, index) => {
			const checked = this.state.checked[index]
			return (
				<Fragment key={qrcode._id}>
					<tr>
						<td>
							<FormCheckbox checked={checked && checked.isChecked ? true : false} value={qrcode._id} onChange={this.handleCheckBox}>{qrcode.material_number}</FormCheckbox>
							<p className="text-center">
								<button className="btn btn-link btn-sm text-info" type="button" onClick={() => this.getDetails(qrcode._id)}><i className={`mdi ${this.state.detail.open[qrcode._id] ? 'mdi-chevron-down' : 'mdi-chevron-right'} mr-2`}></i>Show Details</button>
							</p>
						</td>
						<td>{qrcode.part_number}</td>
						<td>{qrcode.description}</td>
						<td>{qrcode.supplier && qrcode.supplier.name}</td>
						<td>{qrcode.part && qrcode.part.customer && qrcode.part.customer.code}</td>
						<td>{qrcode.qty}</td>
						<td>{qrcode.part && qrcode.part.std_packing}</td>
						<td>{qrcode.total_label}</td>
						<td>{qrcode.total_scanned}</td>
						<td>{qrcode.scan_remaining}</td>
						<td>{qrcode.reprint}</td>
						<td>{qrcode.uom}</td>
						<td>{qrcode.part && qrcode.part.model}</td>
						<td>{qrcode.part && qrcode.part.type}</td>
						<td>{qrcode.production_order}</td>
						<td>{qrcode.prod_date_formatted}</td>
						<td>{qrcode.coil_id}</td>
						<td style={{ width: '100px' }}><input data-value={qrcode._id} type="number" disabled={qrcode.print_status} value={qrcode.copies ? qrcode.copies : 1} onChange={this.handleCopies} className="form-control" /></td>
						<td className={qrcode.duplicate_from ? 'bg-warning' : ''}>{qrcode.duplicate_from ? 'Re-Copy' : ''}</td>
						<td className="text-center">
							<div className="btn-group">
								<button type="button" className="btn btn-lg p-0 m-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i className="mdi mdi-dots-vertical"></i>
								</button>
								<div className="dropdown-menu dropdown-menu-right">
									{!qrcode.print_status && (
										<button className='dropdown-item' onClick={(e) => this.handlePopupPrint(e, qrcode._id)}><i className="mdi mdi-reload mr-2" />Reprint</button>
									)}
									<Link to={`/qr-code-production/edit/${qrcode._id}`} className="dropdown-item text-success"><i className="mdi mdi-pencil mr-2"></i>Edit</Link>
									<button className="dropdown-item text-danger" type="button" onClick={() => this.handleClickDelete(qrcode._id)}><i className="mdi mdi-close mr-2"></i>Delete</button>
								</div>
							</div>
						</td>
					</tr>
					{
						this.state.detail.open[qrcode._id] && (this.detailsTable(qrcode._id))
					}
				</Fragment>
			);
		});


		return (
			<Container fluid className="main-content-container px-4">
				<Loading
					show={fetching}
					color="blue"
					showSpinner={false}
				/>
				<Helmet>
					<title>Qrcode Production | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
					<PageTitle sm="4" title="Qrcode by Production" className="text-sm-left" />
				</Row>

				<Modal visible={this.state.detail.print} onClickBackdrop={this.modalBackdropClicked}>
					<div className="modal-header">
						<h5 className="modal-title">Print QR Code</h5>
					</div>
					<div className="modal-body py-0 pt-2 px-6">
						<div className="form-group">
							<label htmlFor="customer">Customer</label>
							<FormSelect name="customer" id="customer" onChange={this.handlePrintDetailSelect} value={this.state.detail.printParams.customer}>
								<option value='HPM'>HPM</option>
								<option value='ADM'>ADM</option>
								<option value='ADM EXPORT'>ADM EXPORT</option>
							</FormSelect>
						</div>
						<div className="form-group">
							<label htmlFor="paper">Paper</label>
							<FormSelect name="paper" id="paper" onChange={this.handlePrintDetailSelect} value={this.state.detail.printParams.paper}>
								<option value='Thermal'>Thermal</option>
								<option value='A4'>A4</option>
							</FormSelect>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{
							this.state.download ? (
								<button className="btn btn-secondary btn-disabled" disabled>
									<i className="mdi mdi-loading mdi-spin"></i> Loading...
								</button>
							) : (
								<button className="btn btn-secondary" onClick={this.handlePrintDetail}>
									<i className="mdi mdi-printer"></i> Print
								</button>
							)
						}
					</div>
				</Modal>

				<Modal visible={this.state.modalPrint} onClickBackdrop={this.modalBackdropClicked}>
					<div className="modal-header">
						<h5 className="modal-title">Print QR Codes</h5>
					</div>
					<div className="modal-body py-0 pt-2 px-6">
						<div className="form-group">
							<label htmlFor="customer">Customer</label>
							<FormSelect name="customer" id="customer" onChange={this.handlePrintSelect} value={this.state.printParams.customer}>
								<option value='HPM'>HPM</option>
								<option value='ADM'>ADM</option>
								<option value='ADM EXPORT'>ADM EXPORT</option>
							</FormSelect>
						</div>
						<div className="form-group">
							<label htmlFor="paper">Paper</label>
							<FormSelect name="paper" id="paper" onChange={this.handlePrintSelect} value={this.state.printParams.paper}>
								<option value='A4'>A4</option>
								<option value='Thermal'>Thermal</option>
							</FormSelect>
						</div>
						<div className="form-group">
							<FormCheckbox onChange={(e) => this.handleChangePrintCheckbox(e, 'isNew')} checked={this.state.printParams.isNew}>Print New QR Code</FormCheckbox>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{
							this.state.download ? (
								<button className="btn btn-secondary btn-disabled" disabled>
									<i className="mdi mdi-loading mdi-spin"></i> Loading...
								</button>
							) : (
								<button className="btn btn-secondary" onClick={this.handlePrint}>
									<i className="mdi mdi-printer"></i> Print
								</button>
							)
						}
					</div>
				</Modal>


				<Modal visible={this.state.modal} onClickBackdrop={this.modalBackdropClicked}>
					<div className="modal-header">
						<h5 className="modal-title">Import qrcodes</h5>
					</div>
					<div className="modal-body py-0 pt-2 px-4">
						<div className="row">
							<div className="custom-file">
								<input
									id="import"
									type="file"
									className="custom-file-input"
									onChange={this.handleChangeFileImport}
									accept=".xlsx, .xls, .csv"
									ref={ref => this.fileImport = ref}
								/>
								<label
									className="custom-file-label"
									htmlFor="customFile2"
									id="placeholderCustomFile2"
								>
									{this.state.import}
								</label>
							</div>
							<div className="mb-3">
								{
									this.state.downloadLoading ? (
										<button className="btn text-success btn-disabled" disabled onClick={this.handleDownloadTemplate}><i className="mdi mdi-loading mdi-spin mr-2"></i>Loading...</button>
									) : (
										<button className="btn text-success" onClick={this.handleDownloadTemplate}><i className="mdi mdi-download mr-2"></i>Download upload template</button>
									)
								}

								<div>
									<small>
										*) File format is xlsx, xlsx, or csv (semicolon separator ';')<br />
										*) Warning! existing data will be overwritted, be carefully! <br />
										*) Max file size 1MBs <br />
									</small>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{
							this.state.importing ? (
								<button type="button" className="btn btn-secondary btn-disabled" disabled>
									<i className="mdi mdi-loading mdi-spin"></i> Processing...
								</button>
							) : (
								<button type="button" className="btn btn-secondary" onClick={this.handleImport}>
									Import
								</button>
							)
						}

					</div>
				</Modal>

				<Modal visible={this.state.isShowModalGenerate} onClickBackdrop={this.modalBackdropClicked}>
					<div className="modal-header">
						<h5 className="modal-title">Generate QR Code</h5>
					</div>
					<div className="modal-body py-0 pt-2 px-6">
						<div className="form-group">
							<div className="control-label">
								Production Date<span className='text-danger'>*</span>
							</div>
							<RangeDatePicker onDateRangeChange={this.handleProdDateRangeChange} />
						</div>
						<p>If left blank, it will generate material with today's production date.</p>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{this.state.generating ? (
							<button type="button" className="btn btn-secondary btn-disabled" disabled>
								<i className="mdi mdi-loading mdi-spin"></i> Processing...
							</button>
						) : (
							<button type='button' className="btn btn-secondary" onClick={this.handleGenerateQrcode}>Generate</button>
						)}
					</div>
				</Modal>
				<Row>
					{
						this.state.showMsgBox &&
						(
							<ScrollToTop>
								<div className="messagebox">
									<p className="mb-5">Are you sure want to delete this data?</p>
									<button className="btn btn-secondary mr-4" onClick={this.handleClickYes}>Yes</button>
									<button className="btn btn-default" onClick={this.handleClickNo}>No Cancel</button>
								</div>
								<div className="backdrop"></div>
							</ScrollToTop>
						)
					}
					<Col>
						<Card small className="mb-4">
							<CardBody className="p-0 pb-3">
								<div className="col-md-12 mt-4">
									<div className="row">
										<div className="col-md-8">
											<button className="btn btn-secondary" onClick={this.handleModal}>
												<i className="mdi mdi-upload" /> Import
											</button>
											<button className="btn btn-secondary mx-2" onClick={this.handleCheckAll}>
												<i className="mdi mdi-check-box-outline" /> Check all
											</button>
											<button className="btn btn-secondary" disabled={!isSelected} onClick={this.handleUnCheckAll}>
												<i className="mdi mdi-checkbox-blank-outline" /> Uncheck all
											</button>
											<button className="btn btn-secondary mx-2" onClick={this.handleToggleCheck}>
												<i className="mdi mdi-toggle-switch" /> Toggle Check
											</button>
											<button className="btn btn-secondary mx-2" disabled={!isSelected || !isAllowedPrint} onClick={this.handlePopupPrint}>
												<i className="mdi mdi-printer" /> Print selected
											</button>
											<button className="btn btn-secondary" onClick={() => this.setState({ ...this.state, isShowModalGenerate: true })}><i className="mdi mdi-sync" /> Generate</button>

										</div>
										<div className="col-md-4 text-right">
											<form onSubmit={this.handleSubmitKeyword}>
												<div className="input-group mb-3">
													<input
														id="keyword"
														type="text"
														className="form-control"
														placeholder=""
														aria-label="Example text with button addon"
														aria-describedby="button-addon1"
														onChange={this.handleChangeKeyword}
													/>
													<div className="input-group-prepend">
														<button
															className="btn btn-secondary"
															type="submit"
															id="button-addon1"
														>
															<i className="mdi mdi-magnify" /> Search{' '}
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<div className="d-flex align-items-center" style={{ gap: '1rem' }}>
												<div className='form-group'>
													<label>Production Date</label>
													<RangeDatePicker onDateRangeChange={this.handleDateRangeChange} />
												</div>
												<div className="form-group">
													<label>Customer</label>
													<FormSelect name="customer_id" onChange={this.handleChangeSelect} value={customer_id || ""}>
														<option value=''>All</option>
														{filters && filters.customers.map((cust) =>
															cust && (
																<option value={cust._id} key={cust._id}>{`${cust.code} - ${cust.name}`}</option>
															)
														)}
													</FormSelect>
												</div>
												<div className="form-group">
													<label>Type</label>
													<FormSelect name="type" onChange={this.handleChangeSelect} value={type || ""}>
														<option value=''>All</option>
														{filters && filters.types && filters.types.map((type) =>
															type && (
																<option value={type} key={type}>{type}</option>
															)
														)}
													</FormSelect>
												</div>
												<div className="form-group">
													<label>Model</label>
													<FormSelect name="model" onChange={this.handleChangeSelect} value={model || ""}>
														<option value=''>All</option>
														{filters && filters.models && filters.models.map((mod) =>
															mod && (
																<option value={mod} key={mod}>{mod}</option>
															)
														)}
													</FormSelect>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12 mt-3">

									<ul className="nav nav-tabs" id="myTab" role="tablist">
										<li className="nav-item">
											<a className="nav-link active" id="home-tab" data-toggle="tab" href="#unprinted" role="tab" aria-controls="unprinted" aria-selected="true" onClick={() => this.handleChangeTab(false)}>Unprinted</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="profile-tab" data-toggle="tab" href="#printed" role="tab" aria-controls="printed" aria-selected="false" onClick={() => this.handleChangeTab(true)}>Printed</a>
										</li>

									</ul>
									<div className="tab-content" id="myTabContent">
										<div className="tab-pane fade show active" id="unprinted" role="tabpanel" aria-labelledby="home-tab">
											<Table theads={theads} ordering={ordering} handleSorting={this.handleSorting}>
												{
													fetching ?
														(
															<tr>
																<td className="text-center" colSpan="18">Loading...</td>
															</tr>
														)
														:
														payload.data && payload.data.data.length > 0 ? qrcodes : (
															<tr>
																<td className="text-center" colSpan="18">Data not found</td>
															</tr>
														)}
											</Table>
										</div>
										<div className="tab-pane fade" id="printed" role="tabpanel" aria-labelledby="profile-tab">
											<Table theads={theads} ordering={ordering} handleSorting={this.handleSorting}>
												{
													fetching ?
														(
															<tr>
																<td className="text-center" colSpan="18">Loading...</td>
															</tr>
														)
														:
														payload.data && payload.data.data.length > 0 ? qrcodes : (
															<tr>
																<td className="text-center" colSpan="18">Data not found</td>
															</tr>
														)}
											</Table>
										</div>
									</div>

								</div>

								<div className="col-md-12 py-3">
									<div className="row">
										<div className="col-md-10">
											{payload.data && payload.data.total > 1 && (
												<p>Showing {payload.data && payload.data.from.toLocaleString()} to {payload.data && payload.data.to.toLocaleString()} of {payload.data && payload.data.total.toLocaleString()} record(s)</p>
											)}

											{
												payload.data && payload.data.total > 1 && (
													<nav aria-label="Page navigation example">
														<ul className="pagination">

															{payload.data.current_page > 1 && <li key="prev" className="page-item"><button onClick={this.handleClickPage.bind(null, payload.data.current_page - 1)} className="page-link">Prev</button></li>}

															{
																payload.data.pages.map((page, index) => {
																	return (

																		<li key={index} className={`page-item ${page === '...' ? 'disabled' : ''} ${page === payload.data.current_page ? 'active' : ''}`}><button onClick={this.handleClickPage.bind(null, page)} className="page-link">{page}</button></li>

																	)
																})
															}

															{payload.data.current_page < payload.data.last_page && <li key="next" className="page-item"><button onClick={this.handleClickPage.bind(null, payload.data.current_page + 1)} className="page-link">Next</button></li>}


														</ul>
													</nav>
												)
											}
										</div>
										<div className="col-md-2 text-right">
											<div className="form-group">
												<label className="control-label">Showing per page </label>
												<select
													defaultValue="10"
													id="perpage"
													className="form-control custom-select"
													onChange={this.hanldeChangePage}
												>
													<option value="10">10</option>
													<option value="20">20</option>
													<option value="50">50</option>
													<option value="100">100</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container >
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state,
		payload: state.qrcodeProduction.payload,
		error: state.qrcodeProduction.error,
		fetching: state.qrcodeProduction.fetching,
		message: state.qrcodeProduction.message,
		saved: state.qrcodeProduction.saved,
		isDeleted: state.qrcodeProduction.isDeleted,
		filters: state.qrcodeProduction.filters,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchQrcode: (filter) => dispatch(fetchQrcodeProduction(filter)),
		deleteQrcode: (id) => dispatch(deleteQrcodeProduction(id)),
		setPayload: (data) => dispatch(setQrProductionPayload(data)),
		getFilterData: () => dispatch(getFilterData()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(QrProduction));
