import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, FormCheckbox, FormRadio, FormSelect } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { Redirect } from 'react-router-dom';
import { appName, url } from '../global';
import { Helmet } from 'react-helmet';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Table from '../components/table/Table';
import Axios from 'axios';
import Error403 from './Error403';
import { fetchModuleReport, getModuleReportDetails } from '../store/actions/moduleReportAction';

class ModuleReport extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			search: null,
			page: 1,
			perpage: 10,
			keyword: null,
			alert: true,
			alertMsgBox: false,
			showMsgBox: false,
			ordering: {
				type: 'created_at',
				sort: 'desc'
			},
			modal: false,
			checked: [],
			details: [],
			totalDelete: 0,
			deleteData: [],
			detailsLoading: false,
			detailsOpen: false,
		}
	}

	handleSorting = (e) => {
		const type = e.target.id;
		const sort = this.state.ordering.sort;
		this.setState({
			...this.state,
			ordering: {
				type: type,
				sort: sort === 'asc' ? 'desc' : 'asc'
			}
		});
	}

	handleChangeKeyword = (e) => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
	}

	handleSubmitKeyword = (e) => {
		e.preventDefault();
		this.props.fetchReport(this.state);
	}

	handleClickPage = (e) => {
		this.setState({
			...this.state,
			page: e
		});
	}

	hanldeChangePage = (e) => {
		this.setState({
			...this.state,
			perpage: e.target.value
		});
	}

	componentWillUpdate(nextProps, nextState) {
		if (this.state.page !== nextState.page) {
			this.props.fetchReport(nextState);
		}

		if (this.state.perpage !== nextState.perpage) {
			this.props.fetchReport(nextState);
		}

		if (this.state.ordering !== nextState.ordering) {
			this.props.fetchReport(nextState);
		}

		if (this.state.printed !== nextState.printed) {
			this.props.fetchReport(nextState);
		}
	}

	componentDidUpdate = (prevProps, prevState) => {

		if (prevProps.error !== this.props.error) {
			if (!this.props.fetched) {
				if (this.props.error) {
					const { toastManager } = this.props;
					toastManager.add(this.props.error.data.message, {
						appearance: 'error',
						autoDismiss: true
					});
				}
			}
		}

		if (prevProps.isDeleted !== this.props.isDeleted) {
			if (this.props.isDeleted) {
				const { toastManager } = this.props;
				toastManager.add(this.props.message, {
					appearance: 'success',
					autoDismiss: true
				});
				this.props.fetchReport(this.state);
			}
		}

		if (prevProps.payload !== this.props.payload) {
			const data = this.props.payload.data && this.props.payload.data.data.map(item => {
				return {
					id: item._id,
					isChecked: false
				}
			})

			this.setState({
				...this.state,
				checked: data
			})

		}

	}

	handleModal = () => {
		this.setState({
			...this.state,
			modal: true
		})
	}

	modalBackdropClicked = () => {
		this.setState({
			...this.state,
			modal: false,
			modalPrint: false,
			modalDelete: false,
			deleteData: [],
		});
	}

	componentDidMount = () => {
		this.props.fetchReport(this.state)
	}

	handleCheckBox = (event) => {

		let checked = this.state.checked

		checked.forEach(checked => {
			if (checked.id === event.target.value)
				checked.isChecked = event.target.checked
		})

		this.setState({
			...this.state,
			checked: checked
		})

	}

	getDetails = async (id) => {
		this.setState({
			...this.state,
			detailsLoading: true,
			detailsOpen: {
				[id]: !this.state.detailsOpen[id] ? true : false,
			},
		});

		await Axios.get(`${url}/module-report/${id}/detail`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		}).then((res) => {
			this.setState({
				...this.state,
				detailsLoading: false,
				details: {
					[id]: res.data.data,
				},
			});
		});
	};

	detailsTable = (id) => {
		return (
			<tr>
				<td colSpan="18" className="py-0">
					<div className="row">
						<div className="col-md-12">
							<table className="table table-bordered">
								<thead>
									<tr>
										<td>Part Number</td>
										<td>Name</td>
										<td>Qty Order</td>
										<td>Total Kanban</td>
										<td>Total Qty Scanned</td>
										<td>Total Kanban Scanned</td>
										<td>Status</td>
									</tr>
								</thead>
								<tbody>
									{
										this.state.detailsLoading ? (
											<tr>
												<td className="text-center" colSpan="5">
													<i className="mdi mdi-loading mdi-spin mr-2"></i>
													Loading ...
												</td>
											</tr>
										) : (
											this.state.details[id] ? this.state.details[id].map((detail, index) => {
												return (
													<tr key={index}>
														<td>{detail.part_number}</td>
														<td>{detail.part_name}</td>
														<td>{detail.qty_order}</td>
														<td>{detail.total_kanban}</td>
														<td>{detail.qty_order_scanned}</td>
														<td>{detail.total_kanban_scanned}</td>
														<td className={`text-center text-white ${detail.status === "Open" ? "bg-danger" : "bg-success"}`}>
															{detail.status}
														</td>
													</tr>
												)
											}) : (
												<tr>
													<td className="text-center" colSpan="5">
														Data not found
													</td>
												</tr>
											)
										)
									}
								</tbody>
							</table>
						</div>
					</div>
				</td>
			</tr>
		)

	}

	render() {

		const { payload, error, fetching } = this.props;
		if (!localStorage.getItem('token')) return <Redirect to="/login" />
		if (error && error.status === 500) return <Error500 message={error.data.message} />
		if (error && error.status === 403) return <Error403 message={error.data.message} />

		const { ordering, dn_type, filter } = this.state;
		const theads = [
			{ name: 'module', 'value': `Module`, sortable: false },
			{ name: 'customer', 'value': `Customer`, sortable: false },
			{ name: 'qty_order', 'value': `Qty Order`, sortable: false },
			{ name: 'total_kanban', 'value': `Total Kanban`, sortable: false },
			{ name: 'qty_order_scanned', 'value': `Total Qty Scanned`, sortable: false },
			{ name: 'total_kanban_scanned', 'value': `Total Kanban Scanned`, sortable: false },
			{ name: 'status', 'value': `Status`, sortable: false },
		];

		const modules = payload.data && payload.data.data.map((module, index) => {
			return (
				<Fragment key={module.module}>
					<tr>
						<td>
							<span>
								<strong>{module.module}</strong>
							</span>
							<p className="text-center">
								<button className="btn btn-link btn-sm text-info" type="button" onClick={() => this.getDetails(module.module)}><i className={`mdi ${this.state.detailsOpen[module.module] ? 'mdi-chevron-down' : 'mdi-chevron-right'} mr-2`}></i>Show Details</button>
							</p>
						</td>
						<td>{module.customer}</td>
						<td>{module.qty_order}</td>
						<td>{module.total_kanban}</td>
						<td>{module.qty_order_scanned}</td>
						<td>{module.total_kanban_scanned}</td>
						<td className={`text-center text-white ${module.status === "Open" ? "bg-danger" : "bg-success"}`}>
							{module.status}
						</td>
					</tr>
					{
						this.state.detailsOpen[module.module] && (
							this.detailsTable(module.module)
						)
					}
				</Fragment>
			);
		});


		return (
			<Container fluid className="main-content-container px-4">
				<Loading
					show={fetching}
					color="blue"
					showSpinner={false}
				/>
				<Helmet>
					<title>Module Report | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
					<PageTitle sm="4" title="Module Report" className="text-sm-left" />
				</Row>
				<Row>
					<Col>
						<Card small className="mb-4">
							<CardBody className="p-0 pb-3">
								<div className="col-md-12 mt-4">
									<div className="row">
										<div className="col-md-8">
											<div className="row">
												<div className="col-12">

												</div>
											</div>
											<div className="row mt-2">
												<div className="col-12">
													<div className="d-flex align-items-center" style={{ gap: '1rem' }}>

													</div>
												</div>
											</div>

										</div>
										<div className="col-md-4 text-right">
											<form onSubmit={this.handleSubmitKeyword}>
												<div className="input-group mb-3">
													<input
														id="keyword"
														type="text"
														className="form-control"
														placeholder=""
														aria-label="Example text with button addon"
														aria-describedby="button-addon1"
														onChange={this.handleChangeKeyword}
													/>
													<div className="input-group-prepend">
														<button
															className="btn btn-secondary"
															type="submit"
															id="button-addon1"
														>
															<i className="mdi mdi-magnify" /> Search{' '}
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div className="col-md-12 mt-3">
									<Table theads={theads} ordering={ordering} handleSorting={this.handleSorting}>
										{
											fetching ?
												(
													<tr>
														<td className="text-center" colSpan="16">Loading...</td>
													</tr>
												)
												:
												payload.data && payload.data.data.length > 0 ? modules : (
													<tr>
														<td className="text-center" colSpan="16">Data not found</td>
													</tr>
												)}
									</Table>


								</div>
								<div className="col-md-12 py-3">
									<div className="row">
										<div className="col-md-10">
											{payload.data && payload.data.total > 1 && (
												<p>Showing {payload.data && payload.data.from.toLocaleString()} to {payload.data && payload.data.to.toLocaleString()} of {payload.data && payload.data.total.toLocaleString()} record(s)</p>

											)}

											{
												payload.data && payload.data.total > 1 && (
													<nav aria-label="Page navigation example">
														<ul className="pagination">

															{payload.data.current_page > 1 && <li key="prev" className="page-item"><button onClick={this.handleClickPage.bind(null, payload.data.current_page - 1)} className="page-link">Prev</button></li>}

															{
																payload.data.pages.map((page, index) => {
																	return (

																		<li key={index} className={`page-item ${page === '...' ? 'disabled' : ''} ${page === payload.data.current_page ? 'active' : ''}`}><button onClick={this.handleClickPage.bind(null, page)} className="page-link">{page}</button></li>

																	)
																})
															}

															{payload.data.current_page < payload.data.last_page && <li key="next" className="page-item"><button onClick={this.handleClickPage.bind(null, payload.data.current_page + 1)} className="page-link">Next</button></li>}


														</ul>
													</nav>
												)
											}
										</div>
										<div className="col-md-2 text-right">
											<div className="form-group">
												<label className="control-label">Showing per page </label>
												<select
													defaultValue="10"
													id="perpage"
													className="form-control custom-select"
													onChange={this.hanldeChangePage}
												>
													<option value="10">10</option>
													<option value="20">20</option>
													<option value="50">50</option>
													<option value="100">100</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		...state,
		payload: state.moduleReport.payload,
		details: state.moduleReport.details,
		error: state.moduleReport.error,
		fetching: state.moduleReport.fetching,
		message: state.moduleReport.message,
		saved: state.moduleReport.saved,
		isDeleted: state.moduleReport.isDeleted
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchReport: (filter) => dispatch(fetchModuleReport(filter)),
		fetchDetails: (module) => dispatch(getModuleReportDetails(module))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(ModuleReport));
