const initState = {
	fetching: false,
	fetched: false,
	error: null,
	saved: false,
    message: null,
    payload: {}
};

const scanReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SAVE_SCAN_PENDING' : 
			return {
				...state,
                fetching: true,
                saved: false
			};
        case 'SAVE_SCAN_REJECTED' :
            if (action.payload.response.status === 401) {
                localStorage.removeItem('token');
            }
            
            return {
                ...state,
                error: action.payload.response,
                payload: {},
                fetching: false,
                fetched: false
            };
        case 'SAVE_SCAN_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                message: action.payload.data.message,
                payload: action.payload.data.data,
                error: null,
                saved: true
            };
        default:
            return state;
    }
}

export default scanReducer;