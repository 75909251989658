import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";

let _store = {
  menuVisible: false,
  // navItems: getSidebarNavItems(),
  navItems: [],
  // navItems: [],
  toggle: {}
};

class Store extends EventEmitter {

  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.getMenu = this.getMenu.bind(this)

    Dispatcher.register(this.registerToActions.bind(this));
  }


  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      case Constants.GET_MENU:
        this.getMenu();
        break;
      case Constants.TOGGLE_MENU:
        this.toggleMenu(payload.id);
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  async getMenu() {
    _store.navItems = await getSidebarNavItems()
    this.emit(Constants.CHANGE)
  }

  toggleMenu = (id) => {
    if (_store.toggle[id]) {
          _store.toggle[id] = false
        } else {
          _store.toggle[id] = true
        }
    this.emit(Constants.CHANGE)
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    return _store.navItems
  }

  getToggleMenuState() {
    return _store.toggle
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
