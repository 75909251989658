import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, FormSelect } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { appName, url } from '../global';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Error403 from './Error403';
import { connect } from 'react-redux';
import { fetchMonitoringStock, fetchSummary } from '../store/actions/monitoringStockAction';
import Table from '../components/table/Table';
import RangeDatePicker from '../components/common/RangeDatePicker';
import Modal from 'react-bootstrap4-modal';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import _ from 'underscore'

class MonitoringStock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            perpage: 10,
            keyword: null,
            fetching: false,
            download: false,
            error: null,
            ordering: {
                type: 'created_at',
                sort: 'desc'
            },
            exportIsOpen: false,
            filter: {
                startDate: '',
                endDate: '',
            }
        };
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleExport = this.handleExport.bind(this)
    }

    handleSorting = (e) => {
        const type = e.target.id;
        const sort = this.state.ordering.sort;
        this.setState({
            ...this.state,
            ordering: {
                type: type,
                sort: sort === 'asc' ? 'desc' : 'asc'
            }
        });
    }

    handleChangeKeyword = (e) => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    }

    handleSubmitKeyword = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            page: 1,
        })

        this.props.fetchMonitoringStock(this.state);
    }

    handleClickPage = (e) => {
        this.setState({
            ...this.state,
            page: e
        });
    }

    hanldeChangePage = (e) => {
        this.setState({
            ...this.state,
            perpage: e.target.value
        });
    }

    handleOpenExport = () => {
        this.setState({
            ...this.state,
            exportIsOpen: true
        })
    }

    handleCloseExport = () => {
        this.setState({
            ...this.state,
            exportIsOpen: false,
            filter: {
                startDate: '',
                endDate: '',
            }
        })
    }

    handleDateRangeChange = (startDate, endDate) => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                startDate,
                endDate
            }
        });

        if (this.state.filter.startDate) {
            this.props.fetchMonitoringStock({ ...this.state, filter: { ...this.state.filter, endDate } })
            this.props.fetchSummary({ ...this.state, filter: { ...this.state.filter, endDate } })
        }
    }

    handleExport() {
        this.setState({
            ...this.state,
            download: true
        });

        Axios.get(`${url}/monitoring-stock/export`, {
            params: {
                filter: this.state.filter,
                // startDate: this.state.filter.startDate,
                // endDate: this.state.filter.endDate,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            responseType: 'blob',
        }).then(response => {
            this.setState({
                ...this.state,
                download: false
            });
            fileDownload(response.data, `monitoring_stock_report.xlsx`);
        }).catch(error => {
            this.setState({
                ...this.state,
                download: false
            });
            console.log(error.response, 'ini error')
            // this.props.toastManager.add(error.response.data.message, {
            // 	appearance: 'error',
            // 	autoDismiss: true
            // });
        })
    }

    formatedDate = (dateString) => {
        var date = new Date(dateString);
        if (dateString === '') {
            date = new Date();
        }
        return date.toISOString().split('T')[0];
    }

    componentDidMount = () => {
        this.props.fetchMonitoringStock(this.state);
        this.props.fetchSummary(this.state);
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.page !== nextState.page) {
            this.props.fetchMonitoringStock(nextState);
        }

        if (this.state.perpage !== nextState.perpage) {
            this.props.fetchMonitoringStock(nextState);
        }

        if (this.state.ordering !== nextState.ordering) {
            this.props.fetchMonitoringStock(nextState);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.payload !== this.props.payload) {
            const data = this.props.payload.data && this.props.payload.data.data.map(item => {
                return {
                    id: item._id,
                    isChecked: false
                }
            })

            this.setState({
                ...this.state,
                checked: data
            })
        }
    }

    render() {
        const { ordering } = this.state
        const { payload, error, fetching, summary } = this.props;
        if (!localStorage.getItem('token')) return <Redirect to="/login" />
        if (error && error.status === 500) return <Error500 message={error.data.message} />
        if (error && error.status === 403) return <Error403 message={error.data.message} />
        const theads = [
            { name: 'code', 'value': `Part No`, sortable: true },
            { name: 'id_sap', 'value': `SAP ID`, sortable: true },
            { name: 'name', 'value': `Part Name`, sortable: true },
            { name: 'supplier_name', 'value': `Supplier`, sortable: false },
            { name: 'qty_kanban', 'value': `Qty Kanban`, sortable: false },
            { name: 'actual_stock', 'value': `Actual Stok`, sortable: false },
        ];

        const reports = payload.data &&
            payload.data.data.map((report, index) => {
                return (
                    <React.Fragment key={index}>
                        <tr>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{report.part_number}</td>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{report.sap_id}</td>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{report.part_name}</td>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{report.supplier}</td>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{report.qty_kanban}</td>
                            <td rowSpan={2} style={{ verticalAlign: 'middle' }}>{report.actual_stock}</td>

                        </tr>
                        <tr>

                        </tr>
                    </React.Fragment>
                )
            })
        if (!Array.isArray(summary)) {
            console.error('Summary is not an array or is undefined:', summary);
            return null;
        }

        return (

            <Container fluid className="main-content-container px-4">
                <Loading
                    show={fetching}
                    color="blue"
                    showSpinner={false}
                />
                <Helmet>
                    <title>Monitoring Stock | {appName} </title>
                </Helmet>
                <Row noGutters className="page-header py-4">
                    <div className="col-md-8">
                        <PageTitle sm="12" title="Monitoring Stock" className="text-sm-left" />
                    </div>
                </Row>
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardBody className="p-0 pb-3">

                                <div className="row p-3">
                                    {summary.length > 0 && summary.map((item, index) => (
                                        <div key={index} className="col-lg-3 mb-2 mt-3">
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    {item.part_number}
                                                </div>
                                                <div className="card-body" style={{ backgroundColor: 'yellow', textAlign: 'center' }}>
                                                    <h1>{item.total_qty_box}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                
                                <div className="col-md-12 mt-4">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <button className="btn btn-secondary mx-2" onClick={this.handleOpenExport}>
                                                <i className="mdi mdi-file-excel" /> Export
                                            </button>
                                        </div>
                                        <div className="col-md-4 text-right">
                                            <form onSubmit={this.handleSubmitKeyword}>
                                                <div className="input-group mb-3">
                                                    <input
                                                        id="keyword"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        aria-label="Example text with button addon"
                                                        aria-describedby="button-addon1"
                                                        onChange={this.handleChangeKeyword}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <button
                                                            className="btn btn-secondary"
                                                            type="submit"
                                                            id="button-addon1"
                                                        >
                                                            <i className="mdi mdi-magnify" /> Search{' '}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-4 mb-5">
                                    <Table
                                        theads={theads}
                                        ordering={ordering}
                                        handleSorting={this.handleSorting}
                                        headerStyle={headerStyle}
                                    >
                                        {fetching ? (
                                            <tr>
                                                <td className="text-center" colSpan="16">
                                                    Loading...
                                                </td>
                                            </tr>
                                        ) : payload.data && payload.data.data.length > 0 ? (
                                            reports
                                        ) : (
                                            <tr>
                                                <td className="text-center" colSpan="16">
                                                    Data not found
                                                </td>
                                            </tr>
                                        )}
                                    </Table>
                                </div>
                                <div className="col-md-12 py-3">
                                    <div className="row">
                                        <div className="col-md-10">
                                            {payload.data && payload.data.total > 1 && (
                                                <p>
                                                    Showing{" "}
                                                    {payload.data && payload.data.from.toLocaleString()}{" "}
                                                    to {payload.data && payload.data.to.toLocaleString()}{" "}
                                                    of{" "}
                                                    {payload.data && payload.data.total.toLocaleString()}{" "}
                                                    record(s)
                                                </p>
                                            )}

                                            {payload.data && payload.data.total > 1 && (
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination">
                                                        {payload.data.current_page > 1 && (
                                                            <li key="prev" className="page-item">
                                                                <button
                                                                    onClick={this.handleClickPage.bind(
                                                                        null,
                                                                        payload.data.current_page - 1
                                                                    )}
                                                                    className="page-link"
                                                                >
                                                                    Prev
                                                                </button>
                                                            </li>
                                                        )}

                                                        {payload.data.pages.map((page, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className={`page-item ${page === "..." ? "disabled" : ""
                                                                        } ${page === payload.data.current_page
                                                                            ? "active"
                                                                            : ""
                                                                        }`}
                                                                >
                                                                    <button
                                                                        onClick={this.handleClickPage.bind(
                                                                            null,
                                                                            page
                                                                        )}
                                                                        className="page-link"
                                                                    >
                                                                        {page}
                                                                    </button>
                                                                </li>
                                                            );
                                                        })}

                                                        {payload.data.current_page <
                                                            payload.data.last_page && (
                                                                <li key="next" className="page-item">
                                                                    <button
                                                                        onClick={this.handleClickPage.bind(
                                                                            null,
                                                                            payload.data.current_page + 1
                                                                        )}
                                                                        className="page-link"
                                                                    >
                                                                        Next
                                                                    </button>
                                                                </li>
                                                            )}
                                                    </ul>
                                                </nav>
                                            )}
                                        </div>
                                        <div className="col-md-2 text-right">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    Showing per page{" "}
                                                </label>
                                                <select
                                                    defaultValue="10"
                                                    id="perpage"
                                                    className="form-control custom-select"
                                                    onChange={this.hanldeChangePage}
                                                >
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* modals */}
                <Modal visible={this.state.exportIsOpen} onClickBackdrop={this.handleCloseExport}>
                    <div className="modal-header">
                        <h5 className="modal-title">Export</h5>
                    </div>
                    <div className="modal-body py-0 pt-2 px-4">
                        <div className='form-group'>
                            <label>Date Range</label>
                            <RangeDatePicker onDateRangeChange={this.handleDateRangeChange} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.handleCloseExport}>
                            Close
                        </button>
                        {
                            this.state.download ? (
                                <button className="btn btn-secondary btn-disabled" disabled>
                                    <i className="mdi mdi-loading mdi-spin"></i> Loading...
                                </button>
                            ) : (
                                <button className="btn btn-secondary" onClick={this.handleExport}>
                                    <i className="mdi mdi-printer"></i> Export
                                </button>
                            )
                        }
                    </div>
                </Modal>

            </Container>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        payload: state.monitoringStock.payload,
        error: state.monitoringStock.error,
        fetching: state.monitoringStock.fetching,
        summary: state.monitoringStock.summary,
        message: state.monitoringStock.message,
        saved: state.monitoringStock.saved,
        isDeleted: state.monitoringStock.isDeleted
    }
}

const headerStyle = { backgroundColor: "#038cfc", color: "#fff" }; // Blue background with white text

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMonitoringStock: (filter) => dispatch(fetchMonitoringStock(filter)),
        fetchSummary: (filter) => dispatch(fetchSummary(filter)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(MonitoringStock));