import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,FormRadio,
  FormCheckbox,
  DatePicker, Modal, ModalBody, ModalHeader,ModalFooter, Button
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Redirect, Link } from "react-router-dom";
import { appName, url } from "../global";
import { Helmet } from "react-helmet";
import ScrollToTop from "../components/layout/ScrollToTop";
import { withToastManager } from "react-toast-notifications";
import { connect } from "react-redux";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import Table from "../components/table/Table";
import Error403 from "./Error403";
import moment from "moment";
import { fetchLabelProduct } from "../store/actions/labelProdActions";
import Axios from "axios";

class LabelProd extends React.Component {
  state = {
    reprintModal: 0,
    search: null,
    page: 1,
    partName:'',
    number_print:0,
    maxNumber:0,
    partID:'',
    reprintLabelId:'',
    perpage: 10,
    keyword: null,
    alert: true,
    alertMsgBox: false,
    deleteIdPlant: null,
    showMsgBox: false,
    selectedPrint: 'last',
    isDeleted: false,
    ordering: {
      type: "plant",
      sort: "asc",
    },
    modal: false,
    file_import: null,
    import: "Choose file...",
    importing: false,
    checked: [],
    start_date: null,
    end_date: null,
    part_number: "",
  };

  handleSorting = (e) => {
    const type = e.target.id;
    const sort = this.state.ordering.sort;
    this.setState({
      ...this.state,
      ordering: {
        type: type,
        sort: sort === "asc" ? "desc" : "asc",
      },
    });
  };

  handleChangeKeyword = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };
  
  
  handleChangeNumber = (val) => {
    this.setState({
      ...this.state,
      number_print: val,
    });
  };

  handleSubmitKeyword = (e) => {
    e.preventDefault();
    this.props.fetchLabelProduct(this.state);
  };

  handleClickPage = (e) => {
    this.setState({
      ...this.state,
      page: e,
    });
  };

  hanldeChangePage = (e) => {
    this.setState({
      ...this.state,
      perpage: e.target.value,
    });
  };

  handleClickDelete = (id) => {
    this.setState({
      ...this.state,
      deleteIdPlant: id,
      showMsgBox: true,
    });
  };

  handleClickYes = () => {
    this.setState({
      ...this.state,
      alertMsgBox: true,
      showMsgBox: false,
      isDeleted: true,
    });

    this.props.deletePlant(this.state.deleteIdPlant);
  };

  handleClickNo = () => {
    this.setState({
      ...this.state,
      showMsgBox: false,
      deleteIdPlant: null,
    });
  };

  componentWillUpdate(nextProps, nextState) {
    if (this.state.page !== nextState.page) {
      this.props.fetchLabelProduct(nextState);
    }

    if (this.state.perpage !== nextState.perpage) {
      this.props.fetchLabelProduct(nextState);
    }

    if (this.state.ordering !== nextState.ordering) {
      this.props.fetchLabelProduct(nextState);
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.error !== this.props.error) {
      if (!this.props.fetched) {
        if (this.props.error) {
          const { toastManager } = this.props;
          toastManager.add(this.props.error.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    }

    if (prevProps.isDeleted !== this.props.isDeleted) {
      if (this.props.isDeleted) {
        const { toastManager } = this.props;
        toastManager.add(this.props.message, {
          appearance: "success",
          autoDismiss: true,
        });
        this.props.fetchQrcode(this.state);
      }
    }

    if (prevProps.labelProduct !== this.props.labelProduct) {
      const data =
        this.props.labelProduct &&
        this.props.labelProduct.data.map((item) => {
          return {
            id: item._id,
            part_name: item.part_name,
            part_number: item.part_number,
            description: item.description,
            qty: item.qty,
            lot_id: item.lot_id,
            isChecked: false,
            copies: item.copies,
            last_seq_no: item.last_seq_no,
          };
        });

      this.setState({
        ...this.state,
        checked: data,
      });
    }
  };

  handleModal = () => {
    this.setState({
      ...this.state,
      modal: true,
    });
  };

  modalBackdropClicked = () => {
    this.setState({
      ...this.state,
      modal: false,
    });
  };

  componentDidMount = () => {
    this.props.fetchLabelProduct(this.state);
  };

  handleCheckAll = () => {
    let checked = this.state.checked;
    checked.forEach((check) => (check.isChecked = true));
    this.setState({ checked: checked });
  };

  handleUnCheckAll = () => {
    let checked = this.state.checked;
    checked.forEach((check) => (check.isChecked = false));
    this.setState({ checked: checked });
  };

  handleToggleCheck = () => {
    let checked = this.state.checked;

    checked.forEach((checked) => {
      checked.isChecked = checked.isChecked ? false : true;
    });

    this.setState({ checked: checked });
  };

  handleCheckBox = (event) => {
    let checked = this.state.checked;

    checked.forEach((checked) => {
      if (checked.id === event.target.value)
        checked.isChecked = event.target.checked;
    });

    this.setState({
      ...this.state,
      checked: checked,
    });
  };

  handleCopies = (event) => {
    const { toastManager } = this.props;

    Axios.put(
      `${url}/label-prod/${event.target.dataset.value}`,
      {
        copies: event.target.value,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        toastManager.add(res.data.message, {
          appearance: "success",
          autoDismiss: true,
        });

        this.props.fetchLabelProduct(this.state);
      })
      .catch((error) => {
        toastManager.add(error.response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });

    let checked = this.state.checked;

    checked.forEach((checked) => {
      if (checked.id === event.target.dataset.value)
        checked.copies = event.target.value;
    });

    this.setState({
      ...this.state,
      checked: checked,
    });
  };
  changePrintMode= async (mode) => {
    var showcustom = 0
    if(mode == "last")
    {
      showcustom = false;
    } else if(mode == 'custom'){
      showcustom = true;
    }
    this.setState({
      ...this.state,
      selectedPrint: mode,
      showcustom:showcustom
    });
  }
  handleReprintModal= async () => {
    var selected = []
    const { toastManager } = this.props;
    this.state.checked.map((check) => {   
      if(check.isChecked == true)
      {
        selected = check
      }  
      
    })
   
    if(selected.length == 0)
    {
      toastManager.add("Label Belum dipilih", {
        appearance: "error",
        autoDismiss: true,
      });
      this.setState({
        ...this.state,
        reprintModal: false,})
    }else{
      this.setState({
        ...this.state,
        reprintModal: !this.state.reprintModal,
        reprintLabelId: selected.id,
        partID: selected.part_number,
        partName: selected.part_name,
        maxNumber:selected.last_seq_no
      });
    }
   
    
  //  console.log(this.state)
  }

  reprintlable =  () => {
    const { toastManager } = this.props;
    if( parseInt(this.state.number_print) >  parseInt(this.state.maxNumber)  )
    {
      return  toastManager.add( "Angka reprint melebihi maksimal copies", {
        appearance: "error",
        autoDismiss: true,
      });
    }

     Axios.post(
      `${url}/label-prod/reprint`,
      {
        id: this.state.reprintLabelId,
        mode: this.state.selectedPrint,
        custom_number: this.state.number_print
      },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => {
       

        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);

        this.props.fetchLabelProduct(this.state);
      })
      .catch((error) => {
        console.log(error);
      

        if (error.response) {
          toastManager.add(error.response.statusText, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };
  handlePrint = async () => {
    const { toastManager } = this.props;
    this.setState({
      ...this.state,
      download: true,
    });

    await Axios.post(
      `${url}/label-prod/print`,
      {
        check: this.state.checked,
      },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => {
        this.setState({
          ...this.state,
          download: false,
        });

        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);

        this.props.fetchLabelProduct(this.state);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          ...this.state,
          download: false,
        });

        if (error.response) {
          toastManager.add(error.response.statusText, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  handleChangeDate = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleClearKeyword = (e) => {
    this.setState({
      ...this.state,
      start_date: null,
      end_date: null,
    });
    this.props.fetchLabelProduct({
      ...this.state,
      start_date: null,
      end_date: null,
    });
  };

  handleClearPartNumber = () => {
    this.setState({
      ...this.state,
      part_number: "",
    });
    this.props.fetchLabelProduct({
      ...this.state,
      part_number: "",
    });
  };

  render() {
    const { labelProduct, error, fetching } = this.props;
    const { start_date, end_date, part_number } = this.state;

    if (!localStorage.getItem("token")) return <Redirect to="/login" />;
    if (error && error.status === 500)
      return <Error500 message={error.data.message} />;
    if (error && error.status === 403)
      return <Error403 message={error.data.message} />;

    const { ordering } = this.state;
    const theads = [
      { name: "part_id", value: "Part ID", sortable: true },
      { name: "part_name", value: "Part Name", sortable: true },
      { name: "thickness", value: "Thickness", sortable: true },
      { name: "prod_date", value: "Prod Date", sortable: true },
      { name: "maker_name", value: "Maker", sortable: true },
      { name: "lot_id", value: "Lot ID", sortable: true },
      { name: "part_type", value: "Item Type", sortable: true },
      { name: "part_model", value: "Item Module", sortable: true },
      { name: "inspect_date", value: "Inspect Date", sortable: true },
      { name: "qty", value: "Qty", sortable: true },
      { name: "customer_name", value: "Customer ID", sortable: true },
      { name: "inspector", value: "inspector", sortable: true },
      { name: "copies", value: "Copies", sortable: true },
      { name: "_id", value: "Option", sortable: false },
    ];

    const labelProducts =
      labelProduct &&
      labelProduct.data.map((receive, index) => {
        const checked = this.state.checked[index];
        return (
          <tr key={receive._id}>
            <td>
              <FormCheckbox
                checked={checked && checked.isChecked ? true : false}
                value={receive._id}
                onChange={this.handleCheckBox}
              >
                <span className="text-primary">
                  <strong>{receive.part_number}</strong>
                </span>
              </FormCheckbox>
            </td>
            <td>{receive.part_name}</td>
            <td>{receive.thickness}</td>
            <td>{moment(receive.prod_date).format("DD/MM/YYYY")}</td>
            <td>{receive.maker_name}</td>
            <td>{receive.lot_id}</td>
            <td>{receive.part_type}</td>
            <td>{receive.part_model}</td>
            <td>{moment(receive.inspect_date).format("DD/MM/YYYY")}</td>
            <td>{receive.qty}</td>
            <td>{receive.customer_name}</td>
            <td>{receive.inspector}</td>
            <td style={{ width: "100px" }}>
              <input
                data-value={receive._id}
                type="number"
                value={receive.copies ? receive.copies : 1}
                onChange={this.handleCopies}
                className="form-control"
              />
            </td>
            <td className="text-center">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-lg p-0 m-0"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <Link
                    to={`/label-prod/edit/${receive._id}`}
                    className="dropdown-item text-success"
                  >
                    <i className="mdi mdi-pencil mr-2"></i>Edit
                  </Link>
                </div>
              </div>
            </td>
          </tr>
        );
      });

    return (
      <Container fluid className="main-content-container px-4">
        <Loading show={fetching} color="blue" showSpinner={false} />
        <Helmet>
          <title>Label Prod | {appName} </title>
        </Helmet>
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Label Prod" className="text-sm-left" />
        </Row>

        <Row>
          {this.state.showMsgBox && (
            <ScrollToTop>
              <div className="messagebox">
                <p className="mb-5">Are you sure want to delete this data?</p>
                <button
                  className="btn btn-secondary mr-4"
                  onClick={this.handleClickYes}
                >
                  Yes
                </button>
                <button
                  className="btn btn-default"
                  onClick={this.handleClickNo}
                >
                  No Cancel
                </button>
              </div>
              <div className="backdrop"></div>
            </ScrollToTop>
          )}
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="col-md-12 mt-4">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-12 mb-2">
                          <Link to="/label-prod/scan" className="btn btn-secondary">
                            <i className="mdi mdi-plus"></i> Scan Label Prod
                          </Link>

                          <button
                            className="btn btn-secondary mx-2"
                            onClick={this.handleCheckAll}
                          >
                            <i className="mdi mdi-check-box-outline" /> Check all
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={this.handleUnCheckAll}
                          >
                            <i className="mdi mdi-checkbox-blank-outline" /> Uncheck
                            all
                          </button>
                        </div>

                        <div className="col-md-12">
                          <button
                            className="btn btn-secondary"
                            onClick={this.handleToggleCheck}
                          >
                            <i className="mdi mdi-toggle-switch" /> Toggle Check
                          </button>
                          <button
                            className="btn btn-secondary mx-2"
                            onClick={this.handlePrint}
                          >
                            <i className="mdi mdi-printer" /> Print selected
                          </button>
                          
                          <button
                            className="btn btn-secondary mx-2"
                            onClick={this.handleReprintModal}
                          >
                            <i className="mdi mdi-printer" /> Reprint selected
                          </button>
                        </div>
                      </div>
                    </div>
                  <Modal size="lg" open={this.state.reprintModal} toggle={this.handleReprintModal}>
                    <ModalHeader>Reprint Label Produksi</ModalHeader>
                      <ModalBody>
                        <Container className="dr-example-container">

                          <Row>
                            <Col> <label>Part Name</label> : <b>{this.state.partName}</b></Col>
                            <Col> <label>Part ID</label> : <b>{this.state.partID}</b></Col>
                          </Row>
                          <hr/>
                          <Row>
                            <Col>  
                              <FormRadio
                                inline
                                name="printSelector"
                                checked={this.state.selectedPrint === "last"}
                                onChange={() => {
                                  this.changePrintMode('last');
                                }}
                              >
                                Last Print
                              </FormRadio>
                              </Col>
                          </Row>
                             
                          <Row>
                            <Col>  
                            <FormRadio
                                inline
                                name="printSelector"
                                checked={this.state.selectedPrint === "custom"}
                                onChange={() => {
                                  this.changePrintMode('custom');
                                }}

                              >
                                Custom Print 
                            </FormRadio>
                            <br/>
                            </Col>
                          </Row>
                            
                          <Row>
                            <Col style={{ display:this.state.showcustom?'block':'none'}}>  
                              <input
                                type="number"
                                className="form-control"
                                max={this.state.maxNumber}
                                placeholder="Masukan angka"
                                onKeyUp={(e) =>
                                  this.handleChangeNumber(e.target.value)}
                                  onChange={(e) => {
                                    this.handleChangeNumber(e.target.value)
                                  }}
                              />
                            </Col>
                            <Col></Col>
                          </Row>
                        </Container>
                      </ModalBody>
                      <ModalFooter>
                          <button
                            className="btn btn-secondary"
                            type="button"
                            id="button-addon1"
                            onClick={this.handleReprintModal}
                          >
                              Cancel
                          </button>


                          <button
                              className="btn btn-primary"
                              type="button"
                              id="button-addon1"
                              onClick={() => this.reprintlable()}
                            >
                              Re-Print
                          </button>
                      </ModalFooter>
                  </Modal>
                    <div className="col-md-4 text-right">
                      <form onSubmit={this.handleSubmitKeyword}>
                        <div className="input-group mb-3">
                          <input
                            id="keyword"
                            type="text"
                            className="form-control"
                            placeholder=""
                            aria-label="Example text with button addon"
                            aria-describedby="button-addon1"
                            onChange={this.handleChangeKeyword}
                          />
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-secondary"
                              type="submit"
                              id="button-addon1"
                            >
                              <i className="mdi mdi-magnify" /> Search{" "}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-gruop">
                        <label className="mb-2">Filter by Date</label>
                        <div className="row">
                          <div className="col-md-2 mr-2">
                            <DatePicker
                              placeholderText="From"
                              selected={start_date}
                              onChange={(e) =>
                                this.handleChangeDate("start_date", e)
                              }
                            />
                          </div>
                          <div className="col-md-2">
                            <DatePicker
                              placeholderText="To"
                              selected={end_date}
                              onChange={(e) =>
                                this.handleChangeDate("end_date", e)
                              }
                            />
                          </div>
                          <div className="col-md-4">
                            <button
                              className="btn btn-primary mr-2"
                              onClick={this.handleSubmitKeyword}
                            >
                              <i className="mdi mdi-filter" /> Filter
                            </button>
                            {(start_date || end_date) && (
                              <button
                                className="btn btn-secondary"
                                onClick={this.handleClearKeyword}
                              >
                                <i className="mdi mdi-close" /> Clear Filter
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-4">
                      <div className="form-gruop">
                        <label className="mb-2">Filter by Part Number</label>
                        <div className="row">
                          {console.log(part_number, "part_number")}
                          <div className="col-md-2 mr-2">
                            <input
                              value={part_number}
                              onChange={(e) =>
                                this.handleChangeDate(
                                  "part_number",
                                  e.target.value
                                )
                              }
                              className="form-control"
                              placeholder="Enter part number"
                            />
                          </div>
                          <div className="col-md-4">
                            <button
                              className="btn btn-primary mr-2"
                              onClick={this.handleSubmitKeyword}
                            >
                              <i className="mdi mdi-filter" /> Filter
                            </button>
                            {part_number && (
                              <button
                                className="btn btn-secondary"
                                onClick={this.handleClearPartNumber}
                              >
                                <i className="mdi mdi-close" /> Clear Filter
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <Table
                    theads={theads}
                    ordering={ordering}
                    handleSorting={this.handleSorting}
                  >
                    {fetching ? (
                      <tr>
                        <td className="text-center" colSpan="14">
                          Loading...
                        </td>
                      </tr>
                    ) : labelProduct && labelProduct.data.length > 0 ? (
                      labelProduct && labelProducts
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="14">
                          Data not found
                        </td>
                      </tr>
                    )}
                  </Table>
                </div>

                <div className="col-md-12 py-3">
                  <div className="row">
                    <div className="col-md-10">
                      {labelProduct && labelProduct.total > 1 && (
                        <p>
                          Showing{" "}
                          {labelProduct && labelProduct.from.toLocaleString()}{" "}
                          to {labelProduct && labelProduct.to.toLocaleString()}{" "}
                          of{" "}
                          {labelProduct && labelProduct.total.toLocaleString()}{" "}
                          record(s)
                        </p>
                      )}

                      {labelProduct && labelProduct.total > 1 && (
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            {labelProduct && labelProduct.current_page > 1 && (
                              <li key="prev" className="page-item">
                                <button
                                  onClick={this.handleClickPage.bind(
                                    null,
                                    labelProduct &&
                                      labelProduct.current_page - 1
                                  )}
                                  className="page-link"
                                >
                                  Prev
                                </button>
                              </li>
                            )}

                            {labelProduct &&
                              labelProduct.pages.map((page, index) => {
                                return (
                                  <li
                                    key={index}
                                    className={`page-item ${
                                      page === "..." ? "disabled" : ""
                                    } ${
                                      page === labelProduct &&
                                      labelProduct.current_page
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <button
                                      onClick={this.handleClickPage.bind(
                                        null,
                                        page
                                      )}
                                      className="page-link"
                                    >
                                      {page}
                                    </button>
                                  </li>
                                );
                              })}

                            {labelProduct &&
                              labelProduct.current_page < labelProduct &&
                              labelProduct.last_page && (
                                <li key="next" className="page-item">
                                  <button
                                    onClick={this.handleClickPage.bind(
                                      null,
                                      labelProduct &&
                                        labelProduct.current_page + 1
                                    )}
                                    className="page-link"
                                  >
                                    Next
                                  </button>
                                </li>
                              )}
                          </ul>
                        </nav>
                      )}
                    </div>
                    <div className="col-md-2 text-right">
                      <div className="form-group">
                        <label className="control-label">
                          Showing per page{" "}
                        </label>
                        <select
                          defaultValue="10"
                          id="perpage"
                          className="form-control custom-select"
                          onChange={this.hanldeChangePage}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    labelProduct: state.labelProduct.labelProduct,
    error: state.labelProduct.error,
    fetching: state.loading.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLabelProduct: (filter) => dispatch(fetchLabelProduct(filter)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(LabelProd));
