import React, { Component, Fragment } from 'react'
import moment from 'moment'
import socketIOClient from 'socket.io-client'
import { Helmet } from 'react-helmet';
import { appName, url, socketurl } from '../global'
import Axios from 'axios'

export class Dashboard extends Component {
    state = {
        date: new Date(),
        parts: []
    }

    componentDidMount() {
        this.getPart()
        const socket = socketIOClient(socketurl)

        socket.on('update part', () => {
            this.getPart()
        })

        this.timerID = setInterval(
          () => this.tick(),
          1000
        );
    }

    getPart = () => {
        Axios.get(`${url}/dashboard/part`).then(res => {
            this.setState({
                ...this.state,
                parts: res.data.data
            })
        }).catch(error => {
            console.error(error)
        })
    }
    
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
          date: new Date()
        });
    }

    emptyTable = () => {
        let table = []

        // Outer loop to create parent
        for (let i = 0; i < (10 - this.state.parts.length); i++) {
          let children = []
          //Inner loop to create children
          for (let j = 0; j < 4; j++) {
            children.push(<td key={j}></td>)
          }
          //Create the parent and add the children
          table.push(<tr key={i}>{children}</tr>)
        }
        return table
    }

    render() {
        const { date } = this.state

        return (
            <Fragment>
                <Helmet>
					<title>Dashboard | {appName} </title>
				</Helmet>
                
                <div className="col-md-12">
                    <div className="row">
                        <table className="table table-dashboard m-0">
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: 'yellow' }} rowSpan="2" className="text-uppercase text-center align-middle"><h1 className="font-weight-bold">Monitoring stock finish good</h1></th>
                                    <th className="text-center" style={{ backgroundColor: 'orange', color: '#fff' }}>{ moment(date).format('DD MMM YYYY') }</th>
                                </tr>
                                <tr>
                                    <th className="text-center" style={{ backgroundColor: 'black', color: 'white' }}>{ moment(date).format('HH:mm:ss') }</th>
                                </tr>
                            </thead>
                        </table>
                        <table className="table table-bordered table-dashboard table-black m-0 text-uppercase">
                            <thead>
                                <tr>
                                    <th className="text-center" style={{ width: '25%' }}>Part Number</th>
                                    <th className="text-center">Part Name</th>
                                    <th className="text-center">Qty (Pcs)</th>
                                    <th className="text-center">Strength (Day)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.parts && this.state.parts.map(item => {
                                        return (
                                            <tr key={item._id}>
                                                <td>{item.code}</td>
                                                <td>{item.name}</td>
                                                <td className="text-right">{item.stock}</td>
                                                <td className="text-center">{item.strength}</td>
                                            </tr>
                                        )
                                    })
                                }
                                
                                {
                                    this.emptyTable()
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        )
    }
}


export default Dashboard
