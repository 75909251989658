import Axios from "axios";
import moment from "moment";
import { url } from "../../global";

export const fetchPreparationDelivery = (filter) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCHING_START",
    });
    const prepDel = await Axios.get(`${url}/prep-del`, {
      params: {
        page: filter.page,
        perpage: filter.perpage,
        keyword: filter.keyword,
        ordering: filter.ordering,
        start_date: filter.start_date
          ? moment(filter.start_date).format("YYYY-MM-DD")
          : null,
        end_date: filter.end_date
          ? moment(filter.end_date)
              .add(1, "day")
              .format("YYYY-MM-DD")
          : null,
        status: filter.status ? "1" : "0",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    console.log(prepDel.data.data, "res");

    dispatch({
      type: "FETCH_PREP_DEL",
      payload: prepDel.data.data,
    });

    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};

export const saveDn = (data, cb) => {
  return async (dispatch) => {
    dispatch({
      type: "FETCHING_START",
    });
    await Axios.put(`${url}/prep-del/${data._id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        cb(true);
      })
      .catch((err) => {
        cb(false);
      });

    dispatch({
      type: "FETCHING_FINISH",
    });
  };
};
