import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Card, CardBody, Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { appName, url } from "../global";
import { Chart as ChartJs } from "chart.js";
import { Bar } from "react-chartjs-2";
import Axios from "axios";

function DashboardProduction() {
  const [labelsData, setLabelsData] = useState([]);
  const getData = () => {
    Axios.get(`${url}/dashboard/label`, {
      params: {
        startDate: '2024-03-01',
        endDate: '2024-04-18'
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      setLabelsData(res.data)
    });
  };

  useEffect(() => {
    getData()
  }, [])
  return (
    <Container fluid className="main-content-container px-4">
      <Helmet>
        <title>Production Dashboard | {appName} </title>
      </Helmet>
      <Row noGutters className="page-header py-4">
        <div className="col-md-8">
          <PageTitle
            sm="12"
            title="Production Dashboard"
            className="text-sm-left"
          />
        </div>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardBody className="p-0 pb-3">
              {labelsData && labelsData.length > 0 ? (
                <Bar
                  data={{
                    labels: labelsData.map((data) => data.part_name),
                    datasets: [
                      {
                        label: "Total Label",
                        data: labelsData.map(
                          (data) => data.label.scan_percentage
                        ),
                        backgroundColor: "rgba(54, 162, 235, 0.6)",
                      },
                      {
                        label: "Remaining",
                        data: labelsData.map(
                          (data) => data.label.remaining
                        ),
                        backgroundColor: "rgba(255, 99, 132, 0.6)",
                      },
                    ],
                  }}
                  options={{
                    tooltips: {
                      callbacks: {
                        label: function(tooltipItem, data) {
                          console.log(data.datasets)
                          console.log(tooltipItem)
                          return (
                            "\nTotal Label: " +
                            data.datasets[1].data[tooltipItem.index] +
                            "\nTotal Scan: " +
                            data.datasets[0].data[tooltipItem.index] +
                            "\nRemaining: " +
                            data.datasets[1].data[tooltipItem.index]
                          );
                        },
                      },
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            max: 100,
                            callback: function(value) {
                              return value + "%";
                            },
                          },
                        },
                      ],
                    },
                  }}
                />
              ) : (
                <p>No Data</p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardProduction;
