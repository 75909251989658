const initState = {
    fetching: false,
    fetched: false,
    error: null,
    payload: {},
    logout: false,
    message: '',
    saved: false,
    menu: []
}
const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'LOGIN_REJECTED':
            return {
                ...state,
                error: action.payload.response,
                fetching: false
            }
        case 'LOGIN_FULFILLED':
            localStorage.setItem('token', action.payload.data.token);
            localStorage.setItem('name', action.payload.data.data.name);
            localStorage.setItem('_id', action.payload.data.data._id);
            localStorage.setItem('photo', action.payload.data.data.picture_url);
            localStorage.setItem('role', action.payload.data.data.role.name);
            localStorage.setItem('openLock', action.payload.data.permissions.some(p => p.name === "Open Kanban Lock"));
            let isADMLock = false;
            let isHPMLock = false;
            let isADMExportLock = false;
            let isADMProdLock = false;
            let isADMExportProdLock = false;
            if (action.payload.data.data && action.payload.data.data.kanban_lock) {
                isADMLock = action.payload.data.data.kanban_lock.lock;
                isHPMLock = action.payload.data.data.kanban_lock.hpm;
                isADMExportLock = action.payload.data.data.kanban_lock.admexport;
                isADMProdLock = action.payload.data.data.kanban_lock.admproduction;
                isADMExportProdLock = action.payload.data.data.kanban_lock.admexportproduction;
            }
            localStorage.setItem('isKanbanLock', isADMLock)
            localStorage.setItem('isKanbanHPMLock', isHPMLock)
            localStorage.setItem('isKanbanADMExportLock', isADMExportLock)
            localStorage.setItem('isKanbanADMExportProdLock', isADMExportProdLock)
            localStorage.setItem('isKanbanADMProdLock', isADMProdLock)

            return {
                ...state,
                fetching: false,
                fetched: true,
                payload: action.payload,
                error: null,
                menu: [
                    {
                        name: 'lorem'
                    },
                    {
                        name: 'ipsum'
                    },
                ]
            }
        case 'LOGOUT_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'LOGOUT_REJECTED':
            return {
                ...state,
                error: action.payload.response,
                fetching: false
            }
        case 'LOGOUT_FULFILLED':
            localStorage.clear()
            return {
                ...state,
                fetching: false,
                fetched: true,
                payload: action.payload,
                logout: true
            }
        default:
            return state
    }
}

export default authReducer;