import Axios from "axios";
import { url } from "../../global";

const fetchKanbanInternalDn = (filter) => {
  return (dispatch, getState) => {
    dispatch({
      type: "REPORT",
      payload: Axios.get(`${url}/kanban-internal-dn`, {
        params: {
          page: filter.page,
          perpage: filter.perpage,
          keyword: filter.keyword,
          ordering: filter.ordering,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    });
  };
};

const getKanbanInternalDnDetails = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: "GET_DETAIL",
      payload: Axios.get(`${url}/kanban-internal-dn/${id}/detail`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    });
  };
};

export { fetchKanbanInternalDn, getKanbanInternalDnDetails };
