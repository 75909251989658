import React from "react";
import Logo from "../images/logo.png";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../store/actions/authActions";
import { Helmet } from "react-helmet";
import { appName } from "../global";
import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import { withToastManager } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
  };

  handleChangeCreds = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleLoginSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.error !== this.props.error) {
      if (!this.props.fetched) {
        if (this.props.error) {
          const { toastManager } = this.props;
          toastManager.add(this.props.error.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    }
  };

  render() {
    const { error, fetching } = this.props;
    if (localStorage.getItem("token")) return <Redirect to="/delivery-note" />;
    // return window.location.replace('http://sso.dharmap.com');
    return (
      <main className="main-content col mt-5">
        <Loading show={fetching} color="blue" showSpinner={false} />
        <Helmet>
          <title>Login | {appName} </title>
        </Helmet>
        <div className="main-content-container container-fluid px-4 my-auto h-100">
          <div className="row no-gutters h-100">
            <div className="col-lg-3 col-md-5 auth-form mx-auto my-auto">
              <div className="card">
                <div className="card-body">
                  <img
                    className="auth-form__logo_custom d-table mx-auto mb-3"
                    src={Logo}
                    alt="Shards Dashboards - Register Template"
                  />
                  <h5 className="auth-form__title text-center mb-4">Login </h5>
                  <form onSubmit={this.handleLoginSubmit}>
                    <div className="form-group">
                      <label htmlFor="email">
                        Email address or NPK{" "}
                        <span
                          data-tip="NPK is registration number"
                          className="badge badge-pill badge-secondary"
                        >
                          ?
                        </span>
                      </label>
                      <ReactTooltip />
                      <input
                        type="text"
                        className={`form-control ${error &&
                          error.data.errors.email &&
                          "is-invalid"}`}
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Enter email or NPK"
                        onChange={this.handleChangeCreds}
                      />
                      {error && error.data.errors.email && (
                        <div className="invalid-feedback">
                          {error.data.errors.email[0]}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        className={`form-control ${error &&
                          error.data.errors.password &&
                          "is-invalid"}`}
                        id="password"
                        placeholder="Password"
                        onChange={this.handleChangeCreds}
                      />
                      {error && error.data.errors.password && (
                        <div className="invalid-feedback">
                          {error.data.errors.password[0]}
                        </div>
                      )}
                    </div>
                    {this.props.fetching ? (
                      <button
                        type="submit"
                        className="btn btn-pill btn-accent d-table mx-auto mt-4 btn-disabled"
                        disabled
                      >
                        <i className="mdi mdi-loading mdi-spin mr-2"></i>{" "}
                        Loading ...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-pill btn-accent d-table mx-auto mt-4"
                      >
                        <i className="mdi mdi-lock mr-2"></i> Login
                      </button>
                    )}
                  </form>
                </div>
                <div className="card-footer border-top"></div>
              </div>
              <div className="auth-form__meta text-center mt-4">
                <Link to="forgot-password">Forgot your password?</Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    payload: state.auth.payload,
    error: state.auth.error,
    fetching: state.auth.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(login(credentials)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(Login));
