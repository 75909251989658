import React from "react";
import { Container, Row, Col, Card, CardBody, DatePicker } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Redirect, Link } from "react-router-dom";
import { appName } from "../global";
import { Helmet } from "react-helmet";
import ScrollToTop from "../components/layout/ScrollToTop";
import { withToastManager } from "react-toast-notifications";
import { connect } from "react-redux";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import Table from "../components/table/Table";
import Error403 from "./Error403";
import { fetchTrace } from "../store/actions/traceAction";
import moment from "moment";

class Trace extends React.Component {
  state = {
    search: null,
    page: 1,
    perpage: 10,
    keyword: null,
    alert: true,
    alertMsgBox: false,
    deleteIdPlant: null,
    showMsgBox: false,
    isDeleted: false,
    ordering: {
      type: "plant",
      sort: "asc",
    },
    modal: false,
    file_import: null,
    import: "Choose file...",
    importing: false,
    checked: [],
    start_date: null,
    end_date: null,
  };

  handleSorting = (e) => {
    const type = e.target.id;
    const sort = this.state.ordering.sort;
    this.setState({
      ...this.state,
      ordering: {
        type: type,
        sort: sort === "asc" ? "desc" : "asc",
      },
    });
  };

  handleChangeKeyword = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  handleSubmitKeyword = (e) => {
    e.preventDefault();
    this.props.fetchTrace(this.state);
  };

  handleClearKeyword = (e) => {
    this.setState({
      ...this.state,
      start_date: null,
      end_date: null,
    });
    this.props.fetchTrace({
      ...this.state,
      start_date: null,
      end_date: null,
    });
  };

  handleClickPage = (e) => {
    this.setState({
      ...this.state,
      page: e,
    });
  };

  hanldeChangePage = (e) => {
    this.setState({
      ...this.state,
      perpage: e.target.value,
    });
  };

  handleClickDelete = (id) => {
    this.setState({
      ...this.state,
      deleteIdPlant: id,
      showMsgBox: true,
    });
  };

  handleClickYes = () => {
    this.setState({
      ...this.state,
      alertMsgBox: true,
      showMsgBox: false,
      isDeleted: true,
    });

    this.props.deletePlant(this.state.deleteIdPlant);
  };

  handleClickNo = () => {
    this.setState({
      ...this.state,
      showMsgBox: false,
      deleteIdPlant: null,
    });
  };

  componentWillUpdate(nextProps, nextState) {
    if (this.state.page !== nextState.page) {
      this.props.fetchTrace(nextState);
    }

    if (this.state.perpage !== nextState.perpage) {
      this.props.fetchTrace(nextState);
    }

    if (this.state.ordering !== nextState.ordering) {
      this.props.fetchTrace(nextState);
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.error !== this.props.error) {
      if (!this.props.fetched) {
        if (this.props.error) {
          const { toastManager } = this.props;
          toastManager.add(this.props.error.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    }

    if (prevProps.isDeleted !== this.props.isDeleted) {
      if (this.props.isDeleted) {
        const { toastManager } = this.props;
        toastManager.add(this.props.message, {
          appearance: "success",
          autoDismiss: true,
        });
        this.props.fetchTrace(this.state);
      }
    }
  };

  handleModal = () => {
    this.setState({
      ...this.state,
      modal: true,
    });
  };

  modalBackdropClicked = () => {
    this.setState({
      ...this.state,
      modal: false,
    });
  };

  handleChangeDate = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount = () => {
    this.props.fetchTrace(this.state);
  };

  render() {
    const { payload, error, fetching } = this.props;
    const { start_date, end_date } = this.state;

    if (!localStorage.getItem("token")) return <Redirect to="/login" />;
    if (error && error.status === 500)
      return <Error500 message={error.data.message} />;
    if (error && error.status === 403)
      return <Error403 message={error.data.message} />;

    const { ordering } = this.state;
    const theads = [
      { name: "delivery_note_no", value: "Delivery Note AHM", sortable: true },
      { name: "ahm_part_number", value: "Part", sortable: true },
      { name: "qty_dn", value: "Qty", sortable: true },
      { name: "prod_date", value: "Production Date", sortable: true },
      { name: "lot_id_coil", value: "ID Coil", sortable: true },
      { name: "part_name", value: "Part Number Coil", sortable: true },
      { name: "spec", value: "Spec", sortable: true },
      { name: "size", value: "Size", sortable: true },
      { name: "weight", value: "Weight", sortable: true },
      { name: "receive_date", value: "Receiving Date", sortable: true },
      { name: "dies_name", value: "Dies", sortable: true },
      { name: "machine_name", value: "Machine", sortable: true },
      { name: "maker_name", value: "Maker", sortable: true },
      { name: "qrcode", value: "Qr Code", sortable: true }
    ];
    console.log(payload)
    const traces = payload && payload.data.map((trace, index) => {
        return (
          <tr>
              <td className="no-wrap">{trace.header.delivery_note_no}</td>
              <td className="no-wrap">{trace.ahm_part_number}</td>
              <td className="no-wrap">{trace.qty_dn}</td>
              {/* <td className="no-wrap">
                {trace.delivery_note_date
                  ? moment(trace.delivery_note_date).format("DD/MM/YYYY")
                  : null}
              </td> */}
              <td className="no-wrap">{trace.prod_date}</td>
              <td className="no-wrap">{trace.scan_receiving ? trace.scan_receiving.lot_id_coil : '-' }</td>
              <td className="no-wrap">{trace.scan_receiving ? trace.scan_receiving.part_name : '-'}</td>
              <td className="no-wrap">{trace.scan_receiving ? trace.scan_receiving.spec : '-'}</td>
              <td className="no-wrap">{trace.scan_receiving ? trace.scan_receiving.size_1 + 'X' + trace.scan_receiving.size_2 : '-'}</td>
              <td className="no-wrap">{trace.scan_receiving ? trace.scan_receiving.weight : '-'}</td>
              <td className="no-wrap">{trace.scan_receiving ? trace.scan_receiving.receive_date : '-'}</td>
              <td className="no-wrap">{trace.label_prod ? trace.label_prod.dies_name : '-'}</td>
              <td className="no-wrap">{trace.label_prod ? trace.label_prod.machine_name : '-'}</td>
              <td className="no-wrap">{trace.label_prod ? trace.label_prod.maker_name : '-'}</td>
              <td className="no-wrap">{trace.qrcode}</td>
            </tr>
        );
      });

    return (
      <Container fluid className="main-content-container px-4">
        <Loading show={fetching} color="blue" showSpinner={false} />
        <Helmet>
          <title>Trace | {appName} </title>
        </Helmet>
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Traces AHM" className="text-sm-left" />
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="col-md-12 mt-4">
                  <div className="row">
                    <div className="col-md-8">
                      {/* <Link to="/receiving/scan" className="btn btn-secondary">
                        <i className="mdi mdi-plus"></i> Scan Receiving
                      </Link> */}
                    </div>
                    <div className="col-md-4 text-right">
                      <form onSubmit={this.handleSubmitKeyword}>
                        <div className="input-group mb-3">
                          <input
                            id="keyword"
                            type="text"
                            className="form-control"
                            placeholder=""
                            aria-label="Example text with button addon"
                            aria-describedby="button-addon1"
                            onChange={this.handleChangeKeyword}
                          />
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-secondary"
                              type="submit"
                              id="button-addon1"
                            >
                              <i className="mdi mdi-magnify" /> Search{" "}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-md-12">
                      <div className="form-gruop">
                        <label className="mb-2">Filter by Date</label>
                        <div className="row">
                          <div className="col-md-2 mr-2">
                            <DatePicker
                              placeholderText="From"
                              selected={start_date}
                              onChange={(e) =>
                                this.handleChangeDate("start_date", e)
                              }
                            />
                          </div>
                          <div className="col-md-2">
                            <DatePicker
                              placeholderText="To"
                              selected={end_date}
                              onChange={(e) =>
                                this.handleChangeDate("end_date", e)
                              }
                            />
                          </div>
                          <div className="col-md-4">
                            <button
                              className="btn btn-primary mr-2"
                              onClick={this.handleSubmitKeyword}
                            >
                              <i className="mdi mdi-filter" /> Filter
                            </button>
                            {(start_date || end_date) && (
                              <button
                                className="btn btn-secondary"
                                onClick={this.handleClearKeyword}
                              >
                                <i className="mdi mdi-close" /> Clear Filter
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <Table
                    theads={theads}
                    ordering={ordering}
                    handleSorting={this.handleSorting}
                  >
                    {fetching ? (
                      <tr>
                        <td className="text-center" colSpan="8">
                          Loading...
                        </td>
                      </tr>
                    ) : payload && payload.data.length > 0 ? (
                      traces
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="8">
                          Data not found
                        </td>
                      </tr>
                    )}
                  </Table>
                </div>

                <div className="col-md-12 py-3">
                  <div className="row">
                    <div className="col-md-10">
                      {payload && payload.total > 1 && (
                        <p>
                          Showing {payload && payload.from.toLocaleString()}{" "}
                          to {payload && payload.to.toLocaleString()} of{" "}
                          {payload && payload.total.toLocaleString()}{" "}
                          record(s)
                        </p>
                      )}

                      {payload && payload.total > 1 && (
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            {payload && payload.current_page > 1 && (
                              <li key="prev" className="page-item">
                                <button
                                  onClick={this.handleClickPage.bind(
                                    null,
                                    payload && payload.current_page - 1
                                  )}
                                  className="page-link"
                                >
                                  Prev
                                </button>
                              </li>
                            )}

                            {payload &&
                              payload.pages.map((page, index) => {
                                return (
                                  <li
                                    key={index}
                                    className={`page-item ${
                                      page === "..." ? "disabled" : ""
                                    } ${
                                      page === payload &&
                                      payload.current_page
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <button
                                      onClick={this.handleClickPage.bind(
                                        null,
                                        page
                                      )}
                                      className="page-link"
                                    >
                                      {page}
                                    </button>
                                  </li>
                                );
                              })}

                            {payload &&
                              payload.current_page < payload &&
                              payload.last_page && (
                                <li key="next" className="page-item">
                                  <button
                                    onClick={this.handleClickPage.bind(
                                      null,
                                      payload && payload.current_page + 1
                                    )}
                                    className="page-link"
                                  >
                                    Next
                                  </button>
                                </li>
                              )}
                          </ul>
                        </nav>
                      )}
                    </div>
                    <div className="col-md-2 text-right">
                      <div className="form-group">
                        <label className="control-label">
                          Showing per page{" "}
                        </label>
                        <select
                          defaultValue="10"
                          id="perpage"
                          className="form-control custom-select"
                          onChange={this.hanldeChangePage}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state)
  return {
    ...state,
    payload: state.trace.trace,
    error: state.trace.error,
    fetching: state.loading.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTrace: (filter) => dispatch(fetchTrace(filter)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(Trace));
