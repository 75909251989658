import React, { Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormCheckbox,
  FormRadio,
  FormSelect,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Redirect } from "react-router-dom";
import { appName, url } from "../global";
import { Helmet } from "react-helmet";
import { withToastManager } from "react-toast-notifications";
import { connect } from "react-redux";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import Table from "../components/table/Table";
import Axios from "axios";
import Error403 from "./Error403";
import {
  fetchKanbanInternalDn,
  getKanbanInternalDnDetails,
} from "../store/actions/kanbanInternalDnAction";
import Modal from "react-bootstrap4-modal";
import _ from "underscore";

class KanbanInternalDn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: null,
      page: 1,
      perpage: 10,
      keyword: null,
      alert: true,
      alertMsgBox: false,
      showMsgBox: false,
      ordering: {
        type: "created_at",
        sort: "desc",
      },
      module: "",
      modal: false,
      modalPrint: false,
      printParams: {
        isNew: false,
        paper: "A4",
        customer: "HPM",
        id: "",
      },
      checked: [],
      details: {
        module: "",
        data: [],
        loading: false,
        open: false,
        page: 1,
        perpage: 10,
        keyword: null,
        ordering: {
          type: "created_at",
          sort: "asc",
        },
        checked: [],
        print: false,
        printParams: {
          isNew: false,
          paper: "A4",
          customer: "HPM",
          id: "",
        },
        selectedDetailsPart: [],
      },
      totalDelete: 0,
      deleteData: [],
      detailsLoading: false,
      detailsOpen: false,
      child: {
        id: null,
        data: [],
        loading: false,
        open: false,
        page: 1,
        perpage: 10,
        keyword: null,
        ordering: {
          type: "created_at",
          sort: "asc",
        },
        checked: [],
        print: false,
        printParams: {
          isNew: false,
          paper: "A4",
          id: "",
        },
      },
    };
  }

  handleSorting = (e) => {
    const type = e.target.id;
    const sort = this.state.ordering.sort;
    this.setState({
      ...this.state,
      ordering: {
        type: type,
        sort: sort === "asc" ? "desc" : "asc",
      },
    });
  };

  handleChangeKeyword = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  handleSubmitKeyword = (e) => {
    e.preventDefault();
    this.props.fetchKanban(this.state);
  };

  handleClickPage = (e) => {
    this.setState({
      ...this.state,
      page: e,
    });
  };

  hanldeChangePage = (e) => {
    this.setState({
      ...this.state,
      perpage: e.target.value,
    });
  };

  componentWillUpdate(nextProps, nextState) {
    if (this.state.page !== nextState.page) {
      this.props.fetchKanban(nextState);
    }

    if (this.state.perpage !== nextState.perpage) {
      this.props.fetchKanban(nextState);
    }

    if (this.state.ordering !== nextState.ordering) {
      this.props.fetchKanban(nextState);
    }

    if (this.state.printed !== nextState.printed) {
      this.props.fetchKanban(nextState);
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.error !== this.props.error) {
      if (!this.props.fetched) {
        if (this.props.error) {
          const { toastManager } = this.props;
          toastManager.add(this.props.error.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    }

    if (prevProps.isDeleted !== this.props.isDeleted) {
      if (this.props.isDeleted) {
        const { toastManager } = this.props;
        toastManager.add(this.props.message, {
          appearance: "success",
          autoDismiss: true,
        });
        this.props.fetchKanban(this.state);
      }
    }

    if (prevProps.payload !== this.props.payload) {
      const data =
        this.props.payload.data &&
        this.props.payload.data.data.map((item) => {
          return {
            id: item.module,
            isChecked: false,
          };
        });

      this.setState({
        ...this.state,
        checked: data,
      });
    }
  };

  handleModal = () => {
    this.setState({
      ...this.state,
      modal: true,
    });
  };

  modalBackdropClicked = () => {
    this.setState({
      ...this.state,
      modal: false,
      modalPrint: false,
      isShowModalGenerate: false,
      generateParams: {
        ...this.state.generateParams,
        order_from: null,
        order_to: null,
      },
      printParams: {
        ...this.state.printParams,
        id: "",
      },
      details: {
        ...this.state.details,
        print: false,
        printParams: {
          ...this.state.details.printParams,
          id: "",
        },
      },
      child: {
        ...this.state.child,
        print: false,
        printParams: {
          ...this.state.child.printParams,
        },
      },
    });
  };

  componentDidMount = () => {
    this.props.fetchKanban(this.state);
  };

  handleCheckAll = () => {
    let checked = this.state.checked;
    checked.forEach((check) => (check.isChecked = true));
    this.setState({ checked: checked });
  };

  handleUnCheckAll = () => {
    let checked = this.state.checked;
    checked.forEach((check) => (check.isChecked = false));
    this.setState({ checked: checked });
  };

  handleToggleCheck = () => {
    let checked = this.state.checked;

    checked.forEach((checked) => {
      checked.isChecked = checked.isChecked ? false : true;
    });

    this.setState({ checked: checked });
  };

  handleUnCheckAll = () => {
    let checked = this.state.checked;
    checked.forEach((check) => (check.isChecked = false));
    this.setState({ checked: checked });
  };

  handlePopupPrint = (e, id = "") => {
    this.setState({
      ...this.state,
      modalPrint: true,
      ...(id && { printParams: { ...this.state.printParams, id } }),
    });
  };

  handlePopupPrintDetail = (e, id = "") => {
    const checkedDetail = this.state.details.checked.filter((detail) => detail.isChecked).map((item) => item.part_number)

    this.setState((prevState) => {
      const updatedDetails = {
        ...prevState.details, // Make sure to spread prevState.details
        print: true,
        selectedDetailsPart: checkedDetail,
      };

      if (id) {
        updatedDetails.printParams = { ...prevState.details.printParams, id };
      }
      return { details: updatedDetails }; // Update 'details' correctly
    });
  };

  handlePopupPrintChild = (e, part_number, id = "") => {
    const checkedDetail = this.state.child.checked.filter((detail) => detail.isChecked).map((item) => item.code)
    this.setState((prevState) => {
      const updatedChild = {
        ...prevState.child, // Make sure to spread prevState.details
        print: true,
        selectedCodes: checkedDetail,
      };

      return { child: updatedChild }; // Update 'details' correctly
    });
  };

  handlePrintSelect = (e) => {
    const { name, value } = e.target;
    const newState = {
      ...this.state,
      printParams: {
        ...this.state.printParams,
        [name]: value !== "" ? value : null,
      },
    };
    this.setState(newState);
  };

  handlePrintDetailSelect = (e) => {
    const { name, value } = e.target;
    const newState = {
      ...this.state,
      details: {
        ...this.state.details,
        printParams: {
          ...this.state.details.printParams,
          [name]: value !== "" ? value : null,
        },
      },
    };
    this.setState(newState);
  };

  handlePrintChildSelect = (e) => {
    const { name, value } = e.target;
    const newState = {
      ...this.state,
      child: {
        ...this.state.child,
        printParams: {
          ...this.state.child.printParams,
          [name]: value !== "" ? value : null,
        },
      },
    };
    this.setState(newState);
  };

  handlePrint = async () => {
    const { toastManager } = this.props;
    const { checked, printParams } = this.state;

    console.log("State before print:", this.state);
    console.log("Checked items before print:", checked);
    console.log("Print params before print:", printParams);

    this.setState({
      ...this.state,
      download: true,
    });

    const modules = checked
      .filter((item) => item.isChecked)
      .map((item) => item.id);
    console.log(modules, "modules");

    await Axios.post(
      `${url}/kanban-internal-dn/print`,
      {
        modules,
        isNew: printParams.isNew,
        customer: printParams.customer,
        paper: printParams.paper,
      },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => {
        this.modalBackdropClicked();
        this.setState({
          ...this.state,
          download: false,
        });

        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);

        this.setState({
          ...this.state,
          modalPrint: false,
        });

        this.props.fetchQrcode(this.state);
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          download: false,
        });

        if (error.response) {
          toastManager.add(error.response.statusText, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  handlePrintDetail = async () => {
    const { toastManager } = this.props;
    this.setState({
      ...this.state,
      download: true,
    });

    await Axios.post(
      `${url}/kanban-internal-dn/part/print`,
      {
        module: this.state.details.printParams.id,
        parts: this.state.details.selectedDetailsPart,
        paper: this.state.details.printParams.paper,
      },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => {
        this.modalBackdropClicked();
        this.setState({
          ...this.state,
          download: false,
        });
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.setState({
          ...this.state,
          detail: {
            ...this.state.details,
            print: false,
          },
        });
        this.props.fetchQrcode(this.state);
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          download: false,
        });
        if (error.response) {
          toastManager.add(error.response.statusText, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  handlePrintChild = async () => {
    const { toastManager } = this.props;
    console.log(this.state.child.printParams.paper);
    this.setState({
      ...this.state,
      download: true,
    });
    await Axios.post(
      `${url}/kanban-internal-dn/qrcode/print`,
      {
        qrcodes: this.state.child.selectedCodes,
        paper: this.state.child.printParams.paper,
      },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => {
        this.modalBackdropClicked();
        this.setState({
          ...this.state,
          download: false,
        });
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.setState({
          ...this.state,
          detail: {
            ...this.state.details,
            print: false,
          },
        });
        this.props.fetchQrcode(this.state);
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          download: false,
        });
        if (error.response) {
          toastManager.add(error.response.statusText, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  handleCheckBox = (event) => {
    let checked = this.state.checked;

    checked.forEach((checked) => {
      if (checked.id === event.target.value)
        checked.isChecked = event.target.checked;
    });

    this.setState({
      ...this.state,
      checked: checked,
    });
  };

  handleDetailCheckBox = (event, part_number) => {
    let checked = this.state.details.checked
		checked.forEach(c => {
			if (c.part_number === event.target.value)
				c.isChecked = event.target.checked
		})

		this.setState({
			...this.state,
			detail: {
				...this.state.details,
				checked
			}
		})
  };

  handleChildCheckBox = (event, part_number) => {
    let checked = this.state.child.checked
		checked.forEach(c => {
			if (c._id === event.target.value)
				c.isChecked = event.target.checked
		})

		this.setState({
			...this.state,
			child: {
				...this.state.child,
				checked
			}
		})
  };

  handleChangePrintCheckbox = (e, item) => {
    this.setState({
      ...this.state,
      printParams: {
        ...this.state.printParams,
        [item]: !this.state.printParams[item],
      },
    });
  };

  getDetails = (mod) => {
    this.setState({
      ...this.state,
      details: {
        ...this.state.details,
        loading: true,
        open: {
          [mod]: !this.state.details.open[mod] ? true : false,
        },
      },
    });

    Axios.get(`${url}/kanban-internal-dn/${mod}/detail`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      const data = { [mod]: res.data.data };
      const checked =
        res.data.data &&
        res.data.data.map((item) => {
          return {
            module: item.module,
            part_number: item.part_number,
            isChecked: false,
          };
        });

      this.setState({
        ...this.state,
        details: {
          ...this.state.details,
          loading: false,
          data,
          checked,
        },
      });
    });
  };

  getChildDetails = (part_number) => {
    this.setState({
      ...this.state,
      child: {
        ...this.state.child,
        loading: true,
        open: {
          [part_number]: !this.state.child.open[part_number] ? true : false,
        },
      },
    });

    const module = Object.keys(this.state.details.open)[0]
    Axios.get(`${url}/kanban-internal-dn/${module}/detail/${part_number}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      const data = { [part_number]: res.data.data };
      const checked =
        res.data.data &&
        res.data.data.map((item) => {
          return {
            ...item,
            isChecked: false,
          };
        });

      this.setState({
        ...this.state,
        child: {
          ...this.state.child,
          loading: false,
          data,
          checked,
        },
      });
    });
  };

  getChildDetails2 = async (id) => {
    this.setState({
      ...this.state,
      child: {
        ...this.state.child,
        loading: true,
        open: {
          [id]: !this.state.child.open[id],
        },
      },
    });

    try {
      const response = await Axios.get(
        `${url}/kanban-internal-dn/${this.state.currentModule}/detail/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Check if response.data.data is an array
      const dataArray = Array.isArray(response.data.data)
        ? response.data.data
        : [];
      const data = { [id]: dataArray };
      const checked = dataArray.map((item) => ({
        id: item._id,
        code: item.code,
        isChecked: false,
      }));

      this.setState((prevState) => {
        const newChecked = response.data.data.map((detail) => ({
          partNumber: detail.code,
          isChecked: false,
        }));
        return {
          ...prevState,
          child: {
            ...prevState.child,
            loading: false,
            data: data,
            [id]: response.data.data.map((item) => ({
              ...item,
              isChecked: false,
            })),
          },
          checked: checked,
        };
      });
    } catch (error) {
      console.error(`Error fetching child details for ID: ${id}`, error);
      this.setState({
        ...this.state,
        child: {
          ...this.state.child,
          loading: false,
        },
      });
    }
  };

  detailsTable = (id) => {
    const { checked, data } = this.state.details;
    const isSelected =
      checked && checked.filter((item) => item.isChecked === true).length > 0;
    return (
      <tr>
        <td colSpan="18" className="py-0">
          <div className="row">
            <div className="col-12">
              <button
                className="btn btn-secondary mx-2"
                disabled={!isSelected}
                onClick={(e) => {
                  this.handlePopupPrintDetail(e, id);
                }}
              >
                <i className="mdi mdi-printer" /> Print selected
              </button>
            </div>
            <div className="col-md-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <td>Part Number</td>
                    <td>Name</td>
                    <td>Qty Order</td>
                    <td>Total Kanban</td>
                    <td>Total Qty Scanned</td>
                    <td>Total Kanban Scanned</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.detailsLoading ? (
                    <tr>
                      <td className="text-center" colSpan="7">
                        <i className="mdi mdi-loading mdi-spin mr-2"></i>
                        Loading ...
                      </td>
                    </tr>
                  ) : this.state.details.data[id] &&
                    this.state.details.data[id].length > 0 ? (
                    this.state.details.data[id].map((detail, index) => {
                      // console.log(`Detail:`, detail);
                      // console.log(`Detail.part_number: ${detail.part_number}`);
                      // console.log(
                      //   `Detail checked state for ${detail.part_number}: ${
                      //     checked ? checked.isChecked : false
                      //   }`
                      // );
                      return (
                        <Fragment key={detail.part_number}>
                          <tr>
                            <td>
                              <FormCheckbox
                                checked={detail.isChecked}
                                value={detail.part_number}
                                onChange={(e) =>
                                  this.handleDetailCheckBox(e, id)
                                }
                              >
                                {detail.part_number}
                              </FormCheckbox>
                              <p className="text-center">
                                <button
                                  className="btn btn-link btn-sm text-info"
                                  type="button"
                                  onClick={() =>
                                    this.getChildDetails(detail.part_number)
                                  }
                                >
                                  <i
                                    className={`mdi ${
                                      this.state.child.open[detail.part_number]
                                        ? "mdi-chevron-down"
                                        : "mdi-chevron-right"
                                    } mr-2`}
                                  ></i>
                                  Show Child
                                </button>
                              </p>
                            </td>
                            <td>{detail.part_name}</td>
                            <td>{detail.qty_order}</td>
                            <td>{detail.total_kanban}</td>
                            <td>{detail.qty_order_scanned}</td>
                            <td>{detail.total_kanban_scanned}</td>
                            <td
                              className={`text-center text-white ${
                                detail.status === "Open"
                                  ? "bg-danger"
                                  : "bg-success"
                              }`}
                            >
                              {detail.status}
                            </td>
                          </tr>
                          {this.state.child.open[detail.part_number] &&
                            this.childTable(detail.part_number)}
                        </Fragment>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan="7">
                        Data not found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  childTable = (id) => {
    const theads = [
      { name: "code", value: `Qr Code`, sortable: true },
      { name: "generated_at", value: `Generated At`, sortable: false },
      { name: "scanned", value: `Scan Status`, sortable: false },
    ];

    const { ordering, data, loading, page, checked } = this.state.child;
    const isSelected = checked.filter((item) => item.isChecked === true).length > 0;

    const details =
      data[id] && data[id].length > 0 ? (
        checked.map((detail, index) => {
          return (
            <tr key={index}>
              <td>
                <FormCheckbox
                  checked={detail.isChecked}
                  value={detail._id}
                  onChange={(e) => this.handleChildCheckBox(e, id)}
                >
                  {detail.code}
                </FormCheckbox>
              </td>
              <td>{detail.created_at}</td>
              <td className={detail.scan_prep === true || detail.scanned === true ? "bg-success" : ""}>
                {detail.scan_prep === true || detail.scanned === true ? "Scanned" : ""}
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td className="text-center" colSpan="4">
            Data not found
          </td>
        </tr>
      );

    return (
      <tr>
        <td colSpan="18" className="py-0">
          <div className="row">
            <div className="col-12">
              <button
                className="btn btn-secondary mx-2"
                disabled={!isSelected}
                onClick={(e) => this.handlePopupPrintChild(e, id)}
              >
                <i className="mdi mdi-printer" /> Print Selected
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Table
                theads={theads}
                ordering={ordering}
                handleSorting={this.handleDetailSorting}
              >
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="4">
                      <i className="mdi mdi-loading mdi-spin mr-2"></i>
                      Loading ...
                    </td>
                  </tr>
                ) : (
                  details
                )}
              </Table>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const { payload, error, fetching } = this.props;
    if (!localStorage.getItem("token")) return <Redirect to="/login" />;
    if (error && error.status === 500)
      return <Error500 message={error.data.message} />;
    if (error && error.status === 403)
      return <Error403 message={error.data.message} />;

    const isSelected =
      this.state.checked &&
      this.state.checked.filter((item) => item.isChecked === true).length > 0;
    const isAllowedPrint = !(
      this.state.checked &&
      this.state.checked.filter(
        (item) => item.isChecked === true && item.print_status === false
      ).length > 0
    );
    const { ordering, dn_type, filter } = this.state;
    const theads = [
      { name: "module", value: `Module`, sortable: false },
      { name: "customer", value: `Customer`, sortable: false },
      { name: "plan", value: `Plan`, sortable: false },
      { name: "qty_order", value: `Qty Order`, sortable: false },
      { name: "total_kanban", value: `Total Kanban`, sortable: false },
      {
        name: "qty_order_scanned",
        value: `Total Qty Scanned`,
        sortable: false,
      },
      {
        name: "total_kanban_scanned",
        value: `Total Kanban Scanned`,
        sortable: false,
      },
      { name: "status", value: `Status`, sortable: false },
    ];

    const modules =
      payload.data &&
      payload.data.data.map((module) => {
        const checkedItem = this.state.checked.find(
          (item) => item.id === module.module
        );
        //console.log("Checked state for module:", module.module, checkedItem);

        return (
          <Fragment key={module.module}>
            <tr>
              <td>
                <FormCheckbox
                  checked={checkedItem ? checkedItem.isChecked : false}
                  value={module.module}
                  onChange={this.handleCheckBox}
                >
                  <strong>{module.module}</strong>
                </FormCheckbox>
                <p className="text-center">
                  <button
                    className="btn btn-link btn-sm text-info"
                    type="button"
                    onClick={() => this.getDetails(module.module)}
                  >
                    <i
                      className={`mdi ${
                        this.state.details.open[module.module]
                          ? "mdi-chevron-down"
                          : "mdi-chevron-right"
                      } mr-2`}
                    ></i>
                    Show Details
                  </button>
                </p>
              </td>
              <td>{module.customer}</td>
              <td>{module.plant}</td>
              <td>{module.qty_order}</td>
              <td>{module.total_kanban}</td>
              <td>{module.qty_order_scanned}</td>
              <td>{module.total_kanban_scanned}</td>
              <td
                className={`text-center text-white ${
                  module.status === "Open" ? "bg-danger" : "bg-success"
                }`}
              >
                {module.status}
              </td>
            </tr>
            {this.state.details.open[module.module] &&
              this.detailsTable(module.module)}
          </Fragment>
        );
      });

    return (
      <Container fluid className="main-content-container px-4">
        <Loading show={fetching} color="blue" showSpinner={false} />
        <Helmet>
          <title>Kanban Internal DN | {appName} </title>
        </Helmet>
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Kanban Internal DN"
            className="text-sm-left"
          />
        </Row>
        <Modal
          visible={this.state.details.print}
          onClickBackdrop={this.modalBackdropClicked}
        >
          <div className="modal-header">
            <h5 className="modal-title">Print QR Code</h5>
          </div>
          <div className="modal-body py-0 pt-2 px-6">
            <div className="form-group">
              <label htmlFor="paper">Paper</label>
              <FormSelect
                name="paper"
                id="paper"
                onChange={this.handlePrintDetailSelect}
                value={this.state.details.printParams.paper}
              >
                <option value="A4">A4</option>
                <option value="Thermal">Thermal</option>
              </FormSelect>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              onClick={this.modalBackdropClicked}
            >
              Close
            </button>
            {this.state.download ? (
              <button className="btn btn-secondary btn-disabled" disabled>
                <i className="mdi mdi-loading mdi-spin"></i> Loading...
              </button>
            ) : (
              <button
                className="btn btn-secondary"
                onClick={this.handlePrintDetail}
              >
                <i className="mdi mdi-printer"></i> Print
              </button>
            )}
          </div>
        </Modal>

        <Modal
          visible={this.state.modalPrint}
          onClickBackdrop={this.modalBackdropClicked}
        >
          <div className="modal-header">
            <h5 className="modal-title">Print QR Codes</h5>
          </div>
          <div className="modal-body py-0 pt-2 px-6">
            <div className="form-group">
              <label htmlFor="paper">Paper</label>
              <FormSelect
                name="paper"
                id="paper"
                onChange={this.handlePrintSelect}
                value={this.state.printParams.paper}
              >
                <option value="A4">A4</option>
                <option value="Thermal">Thermal</option>
              </FormSelect>
            </div>
            <div className="form-group">
              <FormCheckbox
                onChange={(e) => this.handleChangePrintCheckbox(e, "isNew")}
                checked={this.state.printParams.isNew}
              >
                Print New QR Code
              </FormCheckbox>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              onClick={this.modalBackdropClicked}
            >
              Close
            </button>
            {this.state.download ? (
              <button className="btn btn-secondary btn-disabled" disabled>
                <i className="mdi mdi-loading mdi-spin"></i> Loading...
              </button>
            ) : (
              <button className="btn btn-secondary" onClick={this.handlePrint}>
                <i className="mdi mdi-printer"></i> Print
              </button>
            )}
          </div>
        </Modal>

        {/* Modal Print Child */}
        <Modal
          visible={this.state.child.print}
          onClickBackdrop={this.modalBackdropClicked}
        >
          <div className="modal-header">
            <h5 className="modal-title">Print QR Code Child</h5>
          </div>
          <div className="modal-body py-0 pt-2 px-6">
            <div className="form-group">
              <label htmlFor="paper">Paper</label>
              <FormSelect
                name="paper"
                id="paper"
                onChange={this.handlePrintChildSelect}
                value={this.state.child.printParams.paper}
              >
                <option value="A4">A4</option>
                <option value="Thermal">Thermal</option>
              </FormSelect>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              onClick={this.modalBackdropClicked}
            >
              Close
            </button>
            {this.state.download ? (
              <button className="btn btn-secondary btn-disabled" disabled>
                <i className="mdi mdi-loading mdi-spin"></i> Loading...
              </button>
            ) : (
              <button
                className="btn btn-secondary"
                onClick={this.handlePrintChild}
              >
                <i className="mdi mdi-printer"></i> Print
              </button>
            )}
          </div>
        </Modal>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="col-md-12 mt-4">
                  <div className="row">
                    <div className="col-md-8">
                      <button
                        className="btn btn-secondary mx-2"
                        onClick={this.handleCheckAll}
                      >
                        <i className="mdi mdi-check-box-outline" /> Check all
                      </button>
                      <button
                        className="btn btn-secondary"
                        disabled={!isSelected}
                        onClick={this.handleUnCheckAll}
                      >
                        <i className="mdi mdi-checkbox-blank-outline" /> Uncheck
                        all
                      </button>
                      <button
                        className="btn btn-secondary mx-2"
                        onClick={this.handleToggleCheck}
                      >
                        <i className="mdi mdi-toggle-switch" /> Toggle Check
                      </button>
                      <button
                        className="btn btn-secondary mx-2"
                        disabled={!isSelected || !isAllowedPrint}
                        onClick={this.handlePopupPrint}
                      >
                        <i className="mdi mdi-printer" /> Print Header
                      </button>
                      <div className="row">
                        <div className="col-12"></div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <div
                            className="d-flex align-items-center"
                            style={{ gap: "1rem" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 text-right">
                      <form onSubmit={this.handleSubmitKeyword}>
                        <div className="input-group mb-3">
                          <input
                            id="keyword"
                            type="text"
                            className="form-control"
                            placeholder=""
                            aria-label="Example text with button addon"
                            aria-describedby="button-addon1"
                            onChange={this.handleChangeKeyword}
                          />
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-secondary"
                              type="submit"
                              id="button-addon1"
                            >
                              <i className="mdi mdi-magnify" /> Search{" "}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <Table
                    theads={theads}
                    ordering={ordering}
                    handleSorting={this.handleSorting}
                  >
                    {fetching ? (
                      <tr>
                        <td className="text-center" colSpan="16">
                          Loading...
                        </td>
                      </tr>
                    ) : payload.data && payload.data.data.length > 0 ? (
                      modules
                    ) : (
                      <tr>
                        <td className="text-center" colSpan="16">
                          Data not found
                        </td>
                      </tr>
                    )}
                  </Table>
                </div>
                <div className="col-md-12 py-3">
                  <div className="row">
                    <div className="col-md-10">
                      {payload.data && payload.data.total > 1 && (
                        <p>
                          Showing{" "}
                          {payload.data && payload.data.from.toLocaleString()}{" "}
                          to {payload.data && payload.data.to.toLocaleString()}{" "}
                          of{" "}
                          {payload.data && payload.data.total.toLocaleString()}{" "}
                          record(s)
                        </p>
                      )}

                      {payload.data && payload.data.total > 1 && (
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            {payload.data.current_page > 1 && (
                              <li key="prev" className="page-item">
                                <button
                                  onClick={this.handleClickPage.bind(
                                    null,
                                    payload.data.current_page - 1
                                  )}
                                  className="page-link"
                                >
                                  Prev
                                </button>
                              </li>
                            )}

                            {payload.data.pages.map((page, index) => {
                              return (
                                <li
                                  key={index}
                                  className={`page-item ${
                                    page === "..." ? "disabled" : ""
                                  } ${
                                    page === payload.data.current_page
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <button
                                    onClick={this.handleClickPage.bind(
                                      null,
                                      page
                                    )}
                                    className="page-link"
                                  >
                                    {page}
                                  </button>
                                </li>
                              );
                            })}

                            {payload.data.current_page <
                              payload.data.last_page && (
                              <li key="next" className="page-item">
                                <button
                                  onClick={this.handleClickPage.bind(
                                    null,
                                    payload.data.current_page + 1
                                  )}
                                  className="page-link"
                                >
                                  Next
                                </button>
                              </li>
                            )}
                          </ul>
                        </nav>
                      )}
                    </div>
                    <div className="col-md-2 text-right">
                      <div className="form-group">
                        <label className="control-label">
                          Showing per page{" "}
                        </label>
                        <select
                          defaultValue="10"
                          id="perpage"
                          className="form-control custom-select"
                          onChange={this.hanldeChangePage}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    payload: state.kanbanInternalDn.payload,
    details: state.kanbanInternalDn.details,
    error: state.kanbanInternalDn.error,
    fetching: state.kanbanInternalDn.fetching,
    message: state.kanbanInternalDn.message,
    saved: state.kanbanInternalDn.saved,
    isDeleted: state.kanbanInternalDn.isDeleted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchKanban: (filter) => dispatch(fetchKanbanInternalDn(filter)),
    fetchDetails: (module) => dispatch(getKanbanInternalDnDetails(module)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(KanbanInternalDn));
