import React from 'react';
import { Container, Row, Col, Card, CardBody, FormCheckbox } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { appName } from '../global';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { saveRack } from '../store/actions/rackAction';
import {connect} from 'react-redux';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Error403 from './Error403';
class AddRack extends React.Component {

    state = {
        code: '',
        name: '',
        available: false,
        warehouse_name: '',
        warehouse_id: '',
        warehouses: []
    };
    
    handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
    }
    
    handleChangeSelect = name => (value) => {
        this.setState({
			...this.state,
            [`${name}_id`]: value ? value.value : null,
            [`${name}_name`]: value ? value.label : null,
		});
    }

    handleCheckBox = (e) => {
        this.setState({
            ...this.state,
            available: !this.state.available ? true : false
        })
    }
    
	handleSubmit = (e) => {
		e.preventDefault();
        this.props.saveRack(this.state);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.saved !== this.props.saved) {

            const { toastManager } = this.props;
            toastManager.add(this.props.message, {
                appearance: 'success',
                autoDismiss: true
            });

            this.props.history.push('/rack');
        }

        if (prevProps.error !== this.props.error) {
            if (!this.props.fetched) {
                if (this.props.error) {
                    const { toastManager } = this.props;
                    toastManager.add(this.props.error.data.message, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
            }
        }
    }
    
	render() {      
        const { fetching, error } = this.props;
        const {
            code,
            name,
            available
        } = this.state;
        if (!localStorage.getItem('token')) return <Redirect to="/login" />
        if (error && error.status === 500) return <Error500 message={error.data.message} />
        if (error && error.status === 403) return <Error403 message={error.data.message} />
        return (
         
			<Container fluid className="main-content-container px-4">
                <Loading
						show={fetching}
						color="blue"
						showSpinner={false}
						/>
				<Helmet>
					<title>Add Rack | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
                <div className="col-md-8">
					    <PageTitle sm="4" title="Add Rack" className="text-sm-left" />
                    </div>
                    <div className="col-md-4 text-right">
                         <Link className="btn btn-secondary" to="/rack">Back</Link>
                    </div>
				</Row>
				<Row>
					<Col>
						<Card small className="mb-4">
							    <CardBody className="p-0 pb-3">
                                    <div className="col-md-12 mt-3">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="control-label">Code / ID <span className="text-danger">*</span></label>
                                                        <input value={code} type="text" id="code" className={`form-control ${ error && error.data.errors.code && 'is-invalid' }`} onChange={this.handleChange} placeholder="Rack Code" />
                                                        { 
                                                            error && error.data.errors.code && <div className="invalid-feedback">{ error.data.errors.code[0] }</div>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <FormCheckbox checked={ available } value={available} onChange={this.handleCheckBox}>
                                                            Available
                                                        </FormCheckbox>
                                                    </div>

                                                </div>

                                                <div className="col-md-6">

                                                    <div className="form-group">
                                                        <label className="control-label">Name<span className="text-danger">*</span></label>
                                                        <input value={name} type="text" id="name" className={`form-control ${ error && error.data.errors.name && 'is-invalid' }`} onChange={this.handleChange} placeholder="Rack Name" />
                                                        { 
                                                            error && error.data.errors.name && <div className="invalid-feedback">{ error.data.errors.name[0] }</div>
                                                        }
                                                    </div>

                                                </div>
                                                <div className="col-md-12 text-right">
                                                    <hr/>
                                                    {
                                                        this.props.fetching ? (
                                                            <button className="btn btn-secondary btn-disabled" type="submit" disabled><i className="mdi mdi-loading mdi-spin mr-2"></i>Loading...</button>
                                                        ) : (
                                                            <button className="btn btn-secondary" type="submit">Save</button>
                                                        )
                                                    }
                                                    <button className="btn btn-default" type="reset">Reset</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
            
		);
	}
}


const mapStateToProps = (state) => {
    return {
        ...state,
        saved: state.rack.saved,
        fetching: state.rack.fetching,
        fetched: state.rack.fetched,
        message: state.rack.message,
        error: state.rack.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveRack: data => dispatch(saveRack(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(AddRack));
