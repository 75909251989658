import Axios from "axios";
import { url } from "../../global";

const fetchPlant = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'PLANT',
            payload: Axios.get(`${url}/plant`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const savePlant = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SAVE_PLANT',
            payload: Axios.post(`${url}/plant`, {
                description: data.description,
                code: data.code,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const getPlant = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_PLANT',
            payload: Axios.get(`${url}/plant/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const updatePlant = (id, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_PLANT',
            payload: Axios.put(`${url}/plant/${id}`, {
                description: data.description,
                code: data.code,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}


const deletePlant = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_PLANT',
            payload: Axios.delete(`${url}/plant/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchPlant, savePlant, getPlant, updatePlant, deletePlant };