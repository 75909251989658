import React, { createRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Form,
  CardHeader,
  DatePicker,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { appName, customerStyles, url } from "../global";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import Error403 from "./Error403";
import Axios from "axios";
import { connect } from "react-redux";
import { saveKanban } from "../store/actions/kanbanActions";
import QrReader from "react-qr-reader";
import ScrollToTop from "../components/layout/ScrollToTop";
import {
  getLot,
  getLotAutocomplete,
  getPart,
  onChange,
  onChangeAutocomplete,
  onChangePartId,
  scan,
} from "../store/actions/receivingActions";
import { onChangeData, saveScanProd } from "../store/actions/labelProdActions";
import NumberFormat from "react-number-format";
import AsyncSelect from "react-select/async";
import Select from "react-select";

class ScanLabelProd extends React.Component {
  constructor(props) {
    super(props);
    this.lotRef = createRef();
    this.itemRef = createRef();
  }

  state = {
    qrmodal: false,
    partmodal: false,
    tab: "scan",
    loading: false,
  };

  handleKeyDown = (e, name, isSpecial = false) => {
    const {
      getLot,
      getPart,
      saveScanProd,
      selectedPart,
      selectedReceiving,
    } = this.props;

    if (e.key === "Enter") {
      if (name === "lot") {
        getLot(
          e.target.value,
          (isSuccess) => {
            if (isSuccess) {
              this.lotRef.current.setAttribute("disabled", "true");
              this.itemRef.current.removeAttribute("disabled");
              this.itemRef.current.focus();
            }
          },
          isSpecial
        );
      } else {
        getPart(e.target.value, (isSuccess, part) => {
          if (isSuccess) {
            this.itemRef.current.setAttribute("disabled", "true");
          }
        });
      }
    }
  };

  handleCloseModal = (name) => {
    this.setState({
      ...this.state,
      [name]: false,
    });
  };

  handleScan = (data) => {
    if (data) {
      getLot(data, (isSuccess) => {
        if (isSuccess) {
          this.lotRef.current.setAttribute("disabled", "true");
          this.itemRef.current.removeAttribute("disabled");
          this.itemRef.current.focus();
        }
      });

      this.setState({
        ...this.state,
        qrmodal: false,
      });
    }
  };

  handleScanPart = (data) => {
    const {
      selectedPart,
      selectedReceiving,
      getPart,
      saveScanProd,
    } = this.props;

    if (data) {
      getPart(data, (isSuccess, part) => {
        if (isSuccess) {
          this.itemRef.current.setAttribute("disabled", "true");
        }
      });

      this.setState({
        ...this.state,
        qrpart: false,
      });
    }
  };

  handleError = (err) => {
    console.error(err);
  };

  handleChangeLabel = (value) => {
    this.props.onChange(value);
  };

  handleChangePart = (value) => {
    this.props.onChangePartId(value);
  };

  handleOpenQr = (name) => {
    const { labelProductData, onChangeData } = this.props;
    onChangeData({
      ...labelProductData,
      [name]: true,
    });
  };

  handleChangeDate = (name, e) => {
    const { labelProductData, onChangeData } = this.props;
    onChangeData({
      ...labelProductData,
      [name]: new Date(e),
    });
  };

  handleChange = (e) => {
    const { labelProductData, onChangeData } = this.props;
    onChangeData({
      ...labelProductData,
      [e.target.id]: e.target.value,
    });
  };

  handleChangeSelect = (value, e) => {
    const { labelProductData, onChangeData } = this.props;
    onChangeData({
      ...labelProductData,
      [`${e.name}_id`]: value ? value.value : null,
      [`${e.name}_name`]: value ? value.label : null,
    });
  };

  handleChangeInput = (name, value) => {
    const { labelProductData, onChangeData } = this.props;
    onChangeData({
      ...labelProductData,
      [name]: value,
    });
  };

  handleClickLot = (lot) => {
    const { onChangeAutocomplete, getLot } = this.props;
    onChangeAutocomplete(lot);
    getLot(
      lot,
      (isSuccess) => {
        if (isSuccess) {
          this.lotRef.current.setAttribute("disabled", "true");
          this.itemRef.current.removeAttribute("disabled");
          this.itemRef.current.focus();
        }
      },
      true
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { saveScanProd, labelProductData } = this.props;
    saveScanProd(labelProductData, (isSuccess, message) => {
      if (isSuccess) {
        this.itemRef.current.setAttribute("disabled", "true");
        this.lotRef.current.removeAttribute("disabled");
        this.lotRef.current.focus();
        const { toastManager } = this.props;
        toastManager.add(message, {
          appearance: "success",
          autoDismiss: true,
        });
      }
    });
  };

  handleChangeTabe = (name) => {
    this.setState({
      ...this.state,
      tab: name,
    });
  };

  handleChangeAutoComplete = async (value) => {
    this.props.onChangeAutocomplete(value);
    // _.debounce(() => {
    this.props.getLotAutocomplete(value, (isSuccess) => {
      console.log(isSuccess, "sucess");
      if (isSuccess) {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    });
    // }, 2000);
  };

  wait = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  render() {
    const {
      fetching,
      error,
      selectedReceiving,
      selectedPart,
      label,
      labelAutocomplete,
      partId,
      labelProductData,
      partmodal,
      lotLots,
    } = this.props;
    const { qrmodal, tab, loading } = this.state;

    if (!localStorage.getItem("token")) return <Redirect to="/login" />;
    if (error && error.status === 500)
      return <Error500 message={error.data.message} />;
    if (error && error.status === 403)
      return <Error403 message={error.data.message} />;
    return (
      <Container fluid className="main-content-container px-4">
        <Loading show={fetching} color="blue" showSpinner={false} />
        <Helmet>
          <title>Scan Label Product | {appName} </title>
        </Helmet>
        <Row noGutters className="page-header py-4">
          <div className="col-md-8">
            <PageTitle
              sm="8"
              title="Scan Label Product"
              className="text-sm-left"
            />
          </div>
          <div className="col-md-4 text-right">
            <Link className="btn btn-secondary" to="/label-prod">
              Back
            </Link>
          </div>
        </Row>
        <Row>
          {qrmodal && (
            <ScrollToTop>
              <div className="messagebox msgbox-qr">
                <div className="modal-qr">
                  <button
                    onClick={() => this.handleCloseModal("qrmodal")}
                    className="btn btn-default btn-cicle btn-lg p-0 pull-right"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: "100%" }}
                    // facingMode={cameramode}
                  />
                </div>
              </div>
              <div className="backdrop"></div>
            </ScrollToTop>
          )}

          {partmodal && (
            <ScrollToTop>
              <div className="messagebox msgbox-qr">
                <div className="modal-qr">
                  <button
                    onClick={() => this.handleCloseModal("partmodal")}
                    className="btn btn-default btn-cicle btn-lg p-0 pull-right"
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                  <QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScanPart}
                    style={{ width: "100%" }}
                    // facingMode={cameramode}
                  />
                </div>
              </div>
              <div className="backdrop"></div>
            </ScrollToTop>
          )}

          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="control-label mb-2">
                          Scan QR or choose LOT ID Here
                        </label>

                        <ul className="nav nav-tabs mb-4">
                          <li
                            className="nav-item"
                            onClick={() => this.handleChangeTabe("scan")}
                          >
                            <a
                              className={`nav-link ${
                                tab === "scan" ? "active" : ""
                              }`}
                              href="#"
                            >
                              Scan QR
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            onClick={() => this.handleChangeTabe("choose")}
                          >
                            <a
                              className={`nav-link ${
                                tab === "choose" ? "active" : ""
                              }`}
                              href="#"
                            >
                              Choose LOT ID
                            </a>
                          </li>
                        </ul>

                        {tab === "scan" ? (
                          <div className="input-group mb-3">
                            <input
                              ref={this.lotRef}
                              autoFocus
                              onKeyDown={(e) => this.handleKeyDown(e, "lot")}
                              value={label}
                              type="text"
                              className={`form-control form-control-lg ${error &&
                                error.data.errors.lot_id_coil &&
                                "is-invalid"}`}
                              placeholder="Scan QR Here"
                              onChange={(e) =>
                                this.handleChangeLabel(e.target.value)
                              }
                            />
                            <div className="input-group-prepend">
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                id="button-addon1"
                                onClick={() => this.handleOpenQr("qrmodal")}
                              >
                                <i className="mdi mdi-camera mdi-24px"></i>
                              </button>
                            </div>
                            {error && error.data.errors.lot_id_coil && (
                              <div className="invalid-feedback">
                                {error.data.errors.lot_id_coil[0]}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="position-relative">
                            {loading && (
                              <div className="loading-autocomplete position-absolute">
                                Loading...
                              </div>
                            )}
                            <div className="input-group mb-3">
                              <input
                                ref={this.lotRef}
                                autoFocus
                                onKeyDown={(e) =>
                                  this.handleKeyDown(e, "lot", true)
                                }
                                value={labelAutocomplete}
                                type="text"
                                className={`form-control form-control-lg ${error &&
                                  error.data.errors.lot_id_coil &&
                                  "is-invalid"}`}
                                placeholder="Type something...."
                                onChange={(e) =>
                                  this.handleChangeAutoComplete(e.target.value)
                                }
                              />

                              {error && error.data.errors.lot_id_coil && (
                                <div className="invalid-feedback">
                                  {error.data.errors.lot_id_coil[0]}
                                </div>
                              )}
                            </div>
                            {lotLots.length > 0 && (
                              <div className="position-absolute autocomplete-wrapper">
                                <ul>
                                  {lotLots.map((lot) => {
                                    return (
                                      <li
                                        key={lot._id}
                                        onClick={() =>
                                          this.handleClickLot(lot.lot_id_coil)
                                        }
                                      >
                                        {lot.lot_id_coil}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="control-label">Scan Product ID</label>
                        <div className="input-group mb-3">
                          <input
                            ref={this.itemRef}
                            // autoFocus
                            onKeyDown={(e) =>
                              this.handleKeyDown(e, "productId")
                            }
                            value={partId}
                            type="text"
                            className={`form-control form-control-lg ${error &&
                              error.data.errors.part_id &&
                              "is-invalid"}`}
                            placeholder="Scan QR Here"
                            onChange={(e) =>
                              this.handleChangePart(e.target.value)
                            }
                            disabled
                          />
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              id="button-addon1"
                              onClick={() => this.handleOpenQr("partmodal")}
                            >
                              <i className="mdi mdi-camera mdi-24px"></i>
                            </button>
                          </div>
                          {error && error.data.errors.part_id && (
                            <div className="invalid-feedback">
                              {error.data.errors.part_id[0]}
                            </div>
                          )}
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            {selectedReceiving && selectedPart && (
              <Col lg="12" className="p-0">
                <Card small>
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Details Info</h6>
                  </CardHeader>
                  <Row>
                    <Col>
                      <Card small className="mb-4">
                        <CardBody className="p-0 pb-3">
                          <div className="col-md-12 mt-3">
                            <form onSubmit={this.handleSubmit}>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="control-label">
                                      Part Number
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      disabled
                                      value={labelProductData.part_number}
                                      type="text"
                                      id="part_number"
                                      className={`form-control ${error &&
                                        error.data.errors.part_number &&
                                        "is-invalid"}`}
                                      onChange={this.handleChange}
                                      placeholder="Part Number"
                                    />
                                    {error && error.data.errors.part_number && (
                                      <div className="invalid-feedback">
                                        {error.data.errors.part_number[0]}
                                      </div>
                                    )}
                                  </div>

                                  <div className="form-group">
                                    <label className="control-label">
                                      Part Name
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      disabled
                                      value={labelProductData.part_name}
                                      type="text"
                                      id="part_name"
                                      className={`form-control ${error &&
                                        error.data.errors.part_name &&
                                        "is-invalid"}`}
                                      onChange={this.handleChange}
                                      placeholder="Part Name"
                                    />
                                    {error && error.data.errors.part_name && (
                                      <div className="invalid-feedback">
                                        {error.data.errors.part_name[0]}
                                      </div>
                                    )}
                                  </div>

                                  <div className="form-group">
                                    <label className="control-label">
                                      Customer ID
                                      <span className="text-danger">*</span>
                                    </label>
                                    <AsyncSelect
                                      value={
                                        labelProductData.customer_id
                                          ? {
                                              label:
                                                labelProductData.customer_name,
                                              value:
                                                labelProductData.customer_id,
                                            }
                                          : null
                                      }
                                      isClearable={true}
                                      className={
                                        error &&
                                        error.data.errors.customer_id &&
                                        "is-invalid-select"
                                      }
                                      styles={customerStyles}
                                      loadOptions={customerOptions}
                                      name="customer"
                                      placeholder="Type to search ..."
                                      onChange={this.handleChangeSelect}
                                      cacheOptions
                                      defaultOptions
                                    />
                                    {error && error.data.errors.customer_id && (
                                      <small className="font-weight-bold text-danger">
                                        {error.data.errors.customer_id[0]}
                                      </small>
                                    )}
                                  </div>

                                  <div className="d-flex row">
                                    <div className="form-group col mr-2">
                                      <label className="control-label">
                                        Type Part
                                      </label>
                                      <input
                                        disabled
                                        value={labelProductData.part_type}
                                        type="text"
                                        id="type"
                                        className={`form-control ${error &&
                                          error.data.errors.type &&
                                          "is-invalid"}`}
                                        // onChange={this.handleChange}
                                        placeholder="Type Part"
                                      />
                                      {error && error.data.errors.type && (
                                        <div className="invalid-feedback">
                                          {error.data.errors.type[0]}
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group col">
                                      <label className="control-label">
                                        Model Part
                                      </label>
                                      <input
                                        disabled
                                        value={labelProductData.part_model}
                                        type="text"
                                        id="model"
                                        className={`form-control ${error &&
                                          error.data.errors.model &&
                                          "is-invalid"}`}
                                        // onChange={this.handleChange}
                                        placeholder="Model Part"
                                      />
                                      {error && error.data.errors.model && (
                                        <div className="invalid-feedback">
                                          {error.data.errors.type[0]}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label className="control-label">
                                      Maker
                                    </label>
                                    <Select
                                      value={
                                        labelProductData.maker_id
                                          ? {
                                              value: labelProductData.maker_id,
                                              label:
                                                labelProductData.maker_name,
                                            }
                                          : null
                                      }
                                      style={customerStyles}
                                      options={[
                                        { value: "POSCO", label: "POSCO" },
                                        { value: "JFE", label: "JFE" },
                                        { value: "KNSS", label: "KNSS" },
                                      ]}
                                      name="maker"
                                      onChange={this.handleChangeSelect}
                                    />
                                    {error && error.data.errors.maker_d && (
                                      <div className="invalid-feedback">
                                        {error.data.errors.maker_id[0]}
                                      </div>
                                    )}
                                  </div>

                                  <div className="d-flex row">
                                    <div className="form-group col mr-2">
                                      <label className="control-label">
                                        Machine ID
                                      </label>
                                      <Select
                                        options={[
                                          { value: "A", label: "Aida" },
                                          { value: "F", label: "Fukui" },
                                        ]}
                                        value={
                                          labelProductData.machine_id
                                            ? {
                                                label:
                                                  labelProductData.machine_name,
                                                value:
                                                  labelProductData.machine_id,
                                              }
                                            : null
                                        }
                                        style={customerStyles}
                                        name="machine"
                                        onChange={this.handleChangeSelect}
                                      />
                                      {error &&
                                        error.data.errors.machine_id && (
                                          <div className="invalid-feedback">
                                            {error.data.errors.machine_id[0]}
                                          </div>
                                        )}
                                    </div>
                                    <div className="form-group col">
                                      <label className="control-label">
                                        Dies ID
                                      </label>
                                      <Select
                                        options={[
                                          { value: "L", label: "DPM" },
                                          { value: "A", label: "AHM" },
                                        ]}
                                        value={
                                          labelProductData.dies_id
                                            ? {
                                                value: labelProductData.dies_id,
                                                label:
                                                  labelProductData.dies_name,
                                              }
                                            : null
                                        }
                                        style={customerStyles}
                                        name="dies"
                                        onChange={this.handleChangeSelect}
                                      />
                                      {error && error.data.errors.dies_id && (
                                        <div className="invalid-feedback">
                                          {error.data.errors.dies_id[0]}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label className="control-label">
                                      Thickness
                                    </label>
                                    <input
                                      value={labelProductData.thickness}
                                      type="text"
                                      id="thickness"
                                      className={`form-control ${error &&
                                        error.data.errors.thickness &&
                                        "is-invalid"}`}
                                      onChange={this.handleChange}
                                      placeholder="Thickness"
                                    />
                                    {error && error.data.errors.thickness && (
                                      <div className="invalid-feedback">
                                        {error.data.errors.thickness[0]}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="control-label">
                                      Shift
                                    </label>
                                    <Select
                                      value={
                                        labelProductData.shift_id
                                          ? {
                                              value: labelProductData.shift_id,
                                              label:
                                                labelProductData.shift_name,
                                            }
                                          : null
                                      }
                                      style={customerStyles}
                                      options={[
                                        { value: "1", label: "S1" },
                                        { value: "2", label: "S2" },
                                        { value: "3", label: "S3" },
                                      ]}
                                      name="shift"
                                      onChange={this.handleChangeSelect}
                                    />
                                    {error && error.data.errors.shift_id && (
                                      <div className="invalid-feedback">
                                        {error.data.errors.shift_id[0]}
                                      </div>
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <label className="control-label">
                                      Qty<span className="text-danger">*</span>
                                    </label>
                                    <NumberFormat
                                      decimalSeparator="."
                                      thousandSeparator=","
                                      value={labelProductData.qty}
                                      type="text"
                                      id="qty"
                                      className={`form-control ${error &&
                                        error.data.errors.qty &&
                                        "is-invalid"}`}
                                      onChange={this.handleChange}
                                      placeholder="Qty"
                                    />
                                    {error && error.data.errors.qty && (
                                      <div className="invalid-feedback">
                                        {error.data.errors.qty[0]}
                                      </div>
                                    )}
                                  </div>

                                  <div className="form-group">
                                    <label className="control-label">
                                      Lot ID
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      disabled
                                      value={labelProductData.lot_id}
                                      type="text"
                                      id="lot_id"
                                      className={`form-control ${error &&
                                        error.data.errors.lot_id &&
                                        "is-invalid"}`}
                                      // onChange={this.handleChange}
                                      placeholder="Lot ID"
                                      maxLength={30}
                                    />
                                    {error && error.data.errors.lot_id && (
                                      <div className="invalid-feedback">
                                        {error.data.errors.lot_id[0]}
                                      </div>
                                    )}
                                  </div>

                                  <div className="form-group">
                                    <label className="control-label">
                                      Inspect date
                                      <span className="text-danger">*</span>
                                    </label>
                                    <DatePicker
                                      className={`form-control ${error &&
                                        error.data.errors.inspec_date &&
                                        "is-invalid"}`}
                                      onChange={(e) =>
                                        this.handleChangeDate("inspec_date", e)
                                      }
                                      placeholderText="mm/dd/YYYY"
                                      selected={labelProductData.inspec_date}
                                      id="inspec_date"
                                    />
                                    {error && error.data.errors.inspec_date && (
                                      <div className="invalid-feedback">
                                        {error.data.errors.inspec_date[0]}
                                      </div>
                                    )}
                                  </div>

                                  <div className="form-group">
                                    <label className="control-label">
                                      Production date
                                      <span className="text-danger">*</span>
                                    </label>
                                    <DatePicker
                                      className={`form-control ${error &&
                                        error.data.errors.prod_date &&
                                        "is-invalid"}`}
                                      onChange={(e) =>
                                        this.handleChangeDate("prod_date", e)
                                      }
                                      placeholderText="mm/dd/YYYY"
                                      selected={labelProductData.prod_date}
                                      id="prod_date"
                                    />
                                    {error && error.data.errors.prod_date && (
                                      <div className="invalid-feedback">
                                        {error.data.errors.prod_date[0]}
                                      </div>
                                    )}
                                  </div>

                                  <div className="form-group">
                                    <label className="control-label">
                                      Copies
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      value={labelProductData.copies}
                                      type="number"
                                      id="copies"
                                      className={`form-control ${error &&
                                        error.data.errors.copies &&
                                        "is-invalid"}`}
                                      onChange={this.handleChange}
                                      placeholder="Copies"
                                    />
                                    {error && error.data.errors.copies && (
                                      <div className="invalid-feedback">
                                        {error.data.errors.copies[0]}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12 text-right">
                                  <hr />
                                  {this.props.fetching ? (
                                    <button
                                      className="btn btn-secondary btn-disabled"
                                      disabled
                                      type="button"
                                    >
                                      <i className="mdi mdi-loading mdi-spin mr-2"></i>
                                      Loading...
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-secondary"
                                      type="submit"
                                    >
                                      Save Changes
                                    </button>
                                  )}
                                  <button
                                    className="btn btn-default"
                                    type="reset"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const appendSupplier = (filteredOptions) => {
  return [...filteredOptions];
};

const filterSupplier = (customers) => {
  const options = customers.map((customer) => {
    return {
      label: `${customer.code} - ${customer.name}`,
      value: customer._id,
    };
  });

  return appendSupplier(options);
};

const customerOptions = (inputValue, callback) => {
  Axios.get(`${url}/customer/list`, {
    params: {
      name: inputValue,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => {
    callback(filterSupplier(response.data.data));
  });
};

const mapStateToProps = (state) => {
  return {
    ...state,
    fetching: state.loading.fetching,
    error: state.receiving.error,
    data: state.receiving.data,
    label: state.receiving.label,
    partId: state.receiving.partId,
    selectedReceiving: state.receiving.selectedReceiving,
    selectedPart: state.receiving.selectedPart,
    labelProductData: state.labelProduct.labelProductData,
    labelAutocomplete: state.receiving.labelAutocomplete,
    lotLots: state.receiving.lotLots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLot: (data, cb, isSpecial) => dispatch(getLot(data, cb, isSpecial)),
    getPart: (data, cb) => dispatch(getPart(data, cb)),
    onChange: (data) => dispatch(onChange(data)),
    onChangeAutocomplete: (data) => dispatch(onChangeAutocomplete(data)),
    onChangePartId: (data) => dispatch(onChangePartId(data)),
    saveScanProd: (data, cb) => dispatch(saveScanProd(data, cb)),
    onChangeData: (data) => dispatch(onChangeData(data)),
    getLotAutocomplete: (data, cb) => dispatch(getLotAutocomplete(data, cb)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(ScanLabelProd));
