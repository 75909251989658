let url;
let urlb;
let socketurl;

//if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // url = "http://192.168.43.80:8001/api";
  // urlb = "http://192.168.43.80:8001";
  // socketurl = "http://192.168.43.80:4001";
//} else {
  url = "https://api.adm.kelola.biz/api";
  //urlb = "https://api.adm.kelola.biz";
  //socketurl = "http://192.168.11.144:4001";
//}

const appName = "Dharma";
const customerStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "transparent",
    boxShadow: isFocused
      ? "0 0.313rem 0.719rem rgba(0,123,255,.1), 0 0.156rem 0.1>"
      : "",
  }),
};
export { url, appName, customerStyles, urlb, socketurl };
