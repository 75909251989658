// import { message } from 'antd';
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { appName, url } from "../global";
import PulseLoader from "react-spinners/PulseLoader";
import { Redirect } from "react-router-dom";
import { Alert } from "shards-react";
import { withToastManager } from "react-toast-notifications";
import { connect } from "react-redux";
import { login } from "../store/actions/authActions";

const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

function Sso(props) {
  let [loading, setLoading] = useState(true);
  const [urlSso, setUrlSso] = useState("");

  let query = useQuery();
  useEffect(() => {
    getDataApiSSO();
  }, []);

  const getDataApiSSO = async () => {
    await Axios.get(`${url}/settings-sso/find`, {
      params: {
        variable: "api_sso",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "error") {
          // message.error(res.data.msg);
        } else {
          setUrlSso(res.data.data[0].name);
          getUser(res.data.data[0].name);
        }
      })
      .catch((err) => {
        if (err.response) {
          props.toastManager.add(err.response.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getUser = async (apiUrl) => {
    const user = await Axios.get(`${apiUrl}/check-token`, {
      params: {
        token: query.get("token"),
      },
    }).then((res) => {
      return res.data.user;
    });
    loginSSO(user);
  };

  const loginSSO = (user) => {
    Axios.post(`${url}/login-sso`, {
      username: user.username,
      password: user.password,
      email: user.email,
      full_name: user.full_name,
      role_user_destination_id: user.role_user_destination_id,
      role_user_destination: user.role_user_destination,
      role_id: user.role_id,
      role_name: user.role_name,
      npk: user.npk,
      user_type: user.user_type,
    })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("name", res.data.data.name);
        localStorage.setItem("_id", res.data.data._id);
        localStorage.setItem("photo", res.data.data.picture_url);
        localStorage.setItem("role", res.data.data.role.name);
        let isADMLock = false;
        let isHPMLock = false;
        let isADMExportLock = false;
        let isADMProdLock = false;
        let isADMExportProdLock = false;
        if (res.data.data && res.data.data.kanban_lock) {
          isADMLock = res.data.data.kanban_lock.lock;
          isHPMLock = res.data.data.kanban_lock.hpm;
          isADMExportLock = res.data.data.kanban_lock.admexport;
          isADMProdLock = res.data.data.kanban_lock.admproduction;
          isADMExportProdLock = res.data.data.kanban_lock.admexportproduction;
        }

        localStorage.setItem(
          "openLock",
          res.data.permissions.some((p) => p.name === "Open Kanban Lock")
        );

        localStorage.setItem("isKanbanLock", isADMLock);
        localStorage.setItem("isKanbanHPMLock", isHPMLock);
        localStorage.setItem("isKanbanADMExportLock", isADMExportLock);
        localStorage.setItem("isKanbanADMExportProdLock", isADMExportProdLock);
        localStorage.setItem("isKanbanADMProdLock", isADMProdLock);

        if (res.data.token) {
          props.history.push("/delivery-note");
        }
      })
      .catch((err) => {
        if (err.response) {
          // props.history.push('/login');
          props.toastManager.add(err.response.data.message, {
            appearance: "error",
            autoDismiss: true,
            duration: 5000,
          });
        }
      });
  };
  return (
    <div style={stylewrap}>
      <div className="sweet-loading" style={loader}>
        <PulseLoader
          loading={loading}
          size={20}
          color={"#007bff"}
          style={{ opacity: "100% !important" }}
        />
      </div>
    </div>
  );
}
const stylewrap = {
  textAlign: "center",
  position: "fixed",
  top: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "#ffffff",
  zIndex: 1000,
  opacity: "0.5",
};
const loader = {
  position: "relative",
  top: "15rem",
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(login(credentials)),
  };
};

export default connect(mapDispatchToProps)(withToastManager(Sso));
