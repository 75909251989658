import Axios from "axios";
import { url } from "../../global";

const fetchCycle = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'CYCLE',
            payload: Axios.get(`${url}/cycle`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const saveCycle = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SAVE_CYCLE',
            payload: Axios.post(`${url}/cycle`, {
                code: data.code,
                name: data.name
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const getCycle = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_CYCLE',
            payload: Axios.get(`${url}/cycle/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const updateCycle = (id, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_CYCLE',
            payload: Axios.put(`${url}/cycle/${id}`, {
                code: data.code,
                name: data.name
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}


const deleteCycle = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_CYCLE',
            payload: Axios.delete(`${url}/cycle/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchCycle, saveCycle, getCycle, updateCycle, deleteCycle };