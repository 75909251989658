import Axios from "axios";
import { url } from "../../global";
import moment from "moment";

const fetchQrcodeProduction = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'QRCODE_PRODUCTION',
            payload: Axios.get(`${url}/qr-code-production`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering,
                    printed: filter.printed,
                    model: filter.model,
                    type: filter.type,
                    customer_id: filter.customer_id,
                    startDate: filter.startDate,
                    endDate: filter.endDate,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const getQrcodeProduction = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_QRCODE_PRODUCTION',
            payload: Axios.get(`${url}/qr-code-production/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const updateQrcodeProduction = (id, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_QRCODE_PRODUCTION',
            payload: Axios.put(`${url}/qr-code-production/${id}`, {
                part_number: data.part_number,
                description: data.description,
                supplier_id: data.supplier_id,
                supplier_name: data.supplier_name,
                qty: data.qty,
                lot_id: data.lot_id,
                type_part: data.type_part,
                coil_id: data.coil_id,
                plant_id: data.plant_id,
                packing_date: moment(data.packing_date).format('YYYY-MM-DD'),
                copies: data.copies
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}


const deleteQrcodeProduction = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_QRCODE_PRODUCTION',
            payload: Axios.delete(`${url}/qr-code-production/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const setQrProductionPayload = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SET_PAYLOAD_PRODUCTION',
            payload: data
        })
    }
}

const getFilterData = () => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_FILTER_DATA',
            payload: Axios.get(`${url}/qr-code-production/data/filters`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchQrcodeProduction, getQrcodeProduction, updateQrcodeProduction, deleteQrcodeProduction, setQrProductionPayload, getFilterData };