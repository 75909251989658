import Axios from "axios";
import { url } from "../../global";
import { filter } from "underscore";

const fetchOrderADMSystemReport = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'ORDERADMSYSTEMREPORT',
            payload: Axios.get(`${url}/monitoring-order/table`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering,
                    filter: filter.filter
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

const fetchSummary = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_SUMMARY',
            payload: Axios.get(`${url}/monitoring-order/summary`, {
                params: {
                    filter: filter.filter
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchOrderADMSystemReport, fetchSummary };