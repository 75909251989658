import Axios from "axios";
import {url} from '../global';

const getSidebarNavItems = async () => {
  return  await Axios.get(`${url}/menu/get`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }).then(res => {
    return res.data.data
  })
  // return setTimeout(() => {
  //     return [
  //       {
  //         title: 'QR Code',
  //         to: '/qr-code',
  //         htmlBefore: '<i class="mdi mdi-qrcode"></i>',
  //         htmlAfter: ''
  //       },
  //       {
  //         title: 'Supplier',
  //         to: '/supplier',
  //         htmlBefore: '<i class="mdi mdi-account-group"></i>',
  //         htmlAfter: ''
  //       },
  //       {
  //         title: 'User',
  //         to: '/user',
  //         htmlBefore: '<i class="mdi mdi-account"></i>',
  //         htmlAfter: ''
  //       },
  //       {
  //         title: 'Plant',
  //         to: '/plant',
  //         htmlBefore: '<i class="mdi mdi-tree"></i>',
  //         htmlAfter: ''
  //       },
  //       {
  //         title: 'Menu',
  //         to: '/menu',
  //         htmlBefore: '<i class="mdi mdi-menu"></i>',
  //         htmlAfter: ''
  //       },
  //       {
  //         title: 'Settings',
  //         htmlBefore: '<i class="mdi mdi-settings"></i>',
  //         htmlAfter: '',
  //         children: [
  //           {
  //             title: 'Role',
  //             to: '/role'
  //           },
  //           {
  //             title: 'Permission',
  //             to: '/permission'
  //           },
  //         ]
  //       },
  //     ];
  //   }, 3000)
}

export default getSidebarNavItems