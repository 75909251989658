const initialState = {
  error: null,
  data: null,
  label: "",
  labelAutocomplete: "",
  receiving: null,
  selectedReceiving: null,
  partId: "",
  selectedPart: null,
  lotLots: [],
};

const receivingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SCAN_RECEIVING":
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case "SCAN_RECEIVING_FAILED":
      return {
        ...state,
        error: action.payload,
      };
    case "CHANGE_LABEL":
      return {
        ...state,
        label: action.payload,
      };
    case "CHANGE_LABEL_AUTOCOMPLETE":
      return {
        ...state,
        labelAutocomplete: action.payload,
      };
    case "SET_LOT_AUTOCOMPLETE":
      return {
        ...state,
        lotLots: action.payload,
      };
    case "SET_RECEIVING":
      return {
        ...state,
        selectedReceiving: action.payload,
        error: null,
      };
    case "FETCH_RECEIVING":
      return {
        ...state,
        receiving: action.payload,
      };
    case "CHANGE_PART_ID":
      return {
        ...state,
        partId: action.payload,
      };
    case "SET_PART":
      return {
        ...state,
        selectedPart: action.payload,
      };
    default:
      return state;
  }
};

export default receivingReducer;
