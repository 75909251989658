import React, { createRef } from 'react';
import { Container, Row, Col, Card, CardBody, Modal, ModalHeader, Button, ModalBody, ModalFooter } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { appName, url } from '../global';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Error403 from './Error403';
import Axios from 'axios';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader';
import ScrollToTop from '../components/layout/ScrollToTop';

// sound
import scanKanbanOk from '../sounds/scanKanbanOk.mp3';
import doubleScanKanban from '../sounds/doubleScanKanban.mp3';
import labelTdkCocok from '../sounds/labelTdkCocok.mp3';
import kanbanDnClose from '../sounds/kanbanDnClose.mp3';
import dataSave from '../sounds/dataSave.mp3';
import kanbanDNTidakDitemukan from '../sounds/kanbanDNTidakDitemukan.mp3';
import Table from '../components/table/Table';
import { saveKanbanADMProd } from '../store/actions/kanbanADMProdActions';
class KanbanADMProd extends React.Component {

    state = {
        rack: '',
        kanban: '',
        kanban_dn: '',
        disabled: true,
        kanban_dn_disable: false,
        error: null,
        rack_id: '',
        parts: [],
        qrmodal: false,
        qrmodalrack: false,
        qrmodaldn: false,
        isAudioPlayed: false,
        isAdmin: localStorage.getItem('openLock') === "true",
        isKanbanLock: localStorage.getItem('isKanbanADMProdLock'),
        kanbanLockData: [],
        hasLockPage: false,
        lockpageOrdering: {
            type: 'locked_at',
            sort: 'desc'
        },
        password: '',
        passwordError: '',
    };

    source = Axios.CancelToken.source();

    btnSaveRef = createRef();

    handleChange = (e) => {
        const { id, value } = e.target;
        if (id === "kanban_dn") {
            if (value.length > 70) {
                e.target.value = value.slice(0, 70);
            }
        }

        this.setState({
            ...this.state,
            [id]: e.target.value
        });
    }

    handleRemovePart = (index) => {
        let parts = this.state.parts
        parts.splice(index, 1)

        this.setState({
            ...this.state,
            parts
        })
    }

    handleKeyDownKanban = (e) => {
        if (e.key === 'Enter') {
            this.checkPart(e.target.value)
        }
    }

    handleSave = () => {
        this.props.saveKanban(this.state)
    }

    handleReset = () => {
        this.setState({
            rack: '',
            kanban: '',
            kanban_dn: '',
            disabled: true,
            kanban_dn_disable: false,
            error: null,
            rack_id: '',
            parts: []
        });
    }

    handleScanPopup = () => {
        this.setState({
            ...this.state,
            qrmodal: true
        })
    }

    handleCloseModal = () => {
        this.setState({
            ...this.state,
            qrmodal: false
        })
    }

    handleScan = data => {
        if (data) {
            setTimeout(() => {
                this.checkPart(data)
                this.setState({
                    qrmodal: false
                })
            }, 1000)

        }
    }

    handleError = err => {
        console.error(err)
    }


    handleScanPopupRack = () => {
        this.setState({
            ...this.state,
            qrmodalrack: true
        })
    }

    handleCloseModalRack = () => {
        this.setState({
            ...this.state,
            qrmodalrack: false
        })
    }

    handleScanRack = data => {
        if (data) {
            setTimeout(() => {
                this.checkRack(data)
            }, 1000);

            this.setState({
                rack: data,
                qrmodalrack: false
            })
        }
    }

    handleKeyDownDn = (e) => {
        if (e.key === 'Enter') {
            this.checkDn(e.target.value)
        }
    }

    handleScanPopupDn = () => {
        this.setState({
            ...this.state,
            qrmodaldn: true
        })
    }

    handleCloseModalDn = () => {
        this.setState({
            ...this.state,
            qrmodaldn: false
        })
    }

    handleScanDn = data => {
        if (data) {
            this.checkDn(data)
        }
    }

    handleErrorDn = err => {
        console.error(err)
    }

    audioPlayer = (audio) => {
        new Audio(audio).play();
    }

    checkDn = (id) => {
        Axios.get(`${url}/kanban-adm-production/check-dn/${id}`, {

            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }

        }).then(res => {
            this.audioPlayer(scanKanbanOk);
            this.setState({
                kanban_dn: id,
                qrmodaldn: false,
                kanban_dn_disable: true,
                error: null,
                disabled: false
            });
            this.kanbanInput.focus();

        }).catch(error => {

            this.setState({
                ...this.state,
                error: error.response,
                kanban_dn: ''
            });

            if (this.state.error && this.state.error.data.errors.kanban_dn[0] === 'Delivery Note already inputed!') {
                this.audioPlayer(doubleScanKanban);
            }
            if (this.state.error && this.state.error.data.errors.kanban_dn[0] === 'Delivery note not available') {
                this.audioPlayer(kanbanDNTidakDitemukan);
            }
            if (this.state.error && this.state.error.data.errors.kanban_dn[0] === 'Kanban Closed!') {
                this.audioPlayer(kanbanDnClose);
            }

        })
    }

    checkPart = (kanban) => {
        Axios.get(`${url}/kanban-adm-production/part`, {
            params: {
                kanban,
                dn: this.state.kanban_dn
            }, headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {

            let parts = []
            parts.push(res.data.data)

            this.setState({
                error: null,
                parts: parts,
                kanban: '',
            })

            this.btnSaveRef.current.click();
            this.kanbanDnInput.focus();

        }).catch((err) => {
            this.audioPlayer(labelTdkCocok);
            this.setState({
                ...this.state,
                error: err.response,
                kanban: '',
                disabled: false
            });
            this.checkPage()
        })
    }

    checkPage = () => {
        Axios.get(`${url}/kanban-adm-production/check-page`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            const hasLockPage = res.data.length > 0 ? true : false;
            this.setState({
                ...this.state,
                isKanbanLock: this.state.isAdmin && hasLockPage ? false : true,
                kanbanLockData: {
                    data: res.data,
                    isModalOpen: hasLockPage
                },
                hasLockPage: hasLockPage
            });
            localStorage.setItem('isKanbanADMProdLock', !this.state.isAdmin && hasLockPage ? res.data[0].lock : false);
        }).catch((error) => {
            if (Axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error('Request failed', error);
            }
        });
    }

    handlePasswordInput = (e) => {
        if (e.key === 'Enter') {
            this.openPage(this.state.kanbanLockData.data[0].id);
        }
    }

    openPage = (id) => {
        Axios.post(`${url}/kanban-adm-production/open-page/${this.state.isAdmin ? id : this.state.kanbanLockData.data[0].id}`, {
            password: this.state.password,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            const hasLockPage = res.data.data.length > 0 ? true : false;
            this.setState({
                ...this.state,
                isKanbanLock: hasLockPage,
                kanbanLockData: {
                    data: this.state.kanbanLockData.data.filter(item => item.id !== id),
                    isModalOpen: hasLockPage
                },
                hasLockPage: hasLockPage,
                passwordError: '',
                error: null,
            });
            localStorage.setItem('isKanbanADMProdLock', !this.state.isAdmin && hasLockPage ? res.data[0].lock : false);
        }).catch(error => {
            this.setState({
                ...this.state,
                passwordError: error.response.data.message
            });
            if (!this.state.isAdmin) {
                this.passwordRef.focus();
            }
        })
    }

    openPageAll = () => {
        Axios.put(`${url}/kanban-adm-production/open-all`, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            this.checkPage();
        }).catch(error => {
            console.error(error.response);
        })
    }

    handleOpenPage = () => {
        if (this.state.isAdmin) {
            this.openPageAll();
        } else {
            this.openPage(this.state.kanbanLockData.data[0].id);
        }
    }

    handleCloseModalLockPage = () => {
        this.setState({
            ...this.state,
            kanbanLockData: {
                ...this.state.kanbanLockData,
                isModalOpen: !this.state.kanbanLockData.isModalOpen
            }
        })
    }

    handleSorting = (e) => {
        const type = e.target.id;
        const sort = this.state.lockpageOrdering.sort;
        this.setState({
            ...this.state,
            lockpageOrdering: {
                type: type,
                sort: sort === 'asc' ? 'desc' : 'asc'
            }
        });
    }

    componentDidMount = () => {
        this.checkPage();
    }

    componentWillUnmount = () => {
        this.source.cancel();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.fetching !== this.props.fetching && prevProps.saved !== this.props.saved) {
            if (this.props.message === 'Kanban Closed!') {
                this.audioPlayer(kanbanDnClose);
            } else {
                this.audioPlayer(dataSave);
            }
            if (this.props.saved) {
                const { toastManager } = this.props;
                toastManager.add(this.props.message, {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.handleReset()
            }
        }
    }

    render() {
        const { fetching } = this.props;
        const {
            kanban,
            kanban_dn,
            disabled,
            kanban_dn_disable,
            error,
            parts,
            qrmodal,
            qrmodalrack,
            qrmodaldn,
            kanbanLockData,
            isAdmin,
            passwordError,
            isKanbanLock,
            hasLockPage,
            lockpageOrdering
        } = this.state;

        const theads = [
            { name: 'locked_by', 'value': `Locked By`, sortable: true },
            { name: 'locked_at', 'value': 'Time', sortable: true },
            { name: "message", value: "Reason", sortable: false },
            { name: 'password', 'value': 'Password', sortable: false },
            { name: 'acton', 'value': '', sortable: false },
        ]

        const lockTable = hasLockPage && kanbanLockData.data.map((data, index) => {
            return (
                <tr key={index}>
                    <td>{data.locked_by}</td>
                    <td>{data.locked_at}</td>
                    <td>{data.message}</td>
                    <td>{data.password}</td>
                    <td><Button onClick={() => this.openPage(data.id)}>Open</Button></td>
                </tr>
            )
        })

        if (!localStorage.getItem('token')) return <Redirect to="/login" />
        if (error && error.status === 500) return <Error500 message={error.data.message} />
        if (error && error.status === 403) return <Error403 message={error.data.message} />
        return (

            <Container fluid className="main-content-container px-4">
                <Loading
                    show={fetching}
                    color="blue"
                    showSpinner={false}
                />
                <Helmet>
                    <title>Scan Kanban ADM Production | {appName} </title>
                </Helmet>
                <Row noGutters className="page-header py-4">
                    <div className="col-md-12">
                        <PageTitle sm="4" title="Scan Kanban ADM Production" className="text-sm-left" />
                    </div>
                </Row>
                <Row>
                    {
                        (isKanbanLock || kanbanLockData.isModalOpen) && (
                            <Modal open={kanbanLockData.isModalOpen} toggle={this.handleCloseModalLockPage} size={isAdmin ? 'lg' : 'md'}>
                                <ModalHeader>Page Lock!</ModalHeader>
                                <ModalBody>
                                    {!isAdmin ? (<>
                                        <p>Halaman ini dikunci. Hubungi admin untuk membuka kunci.</p>
                                        <div className="form-group">
                                            <label className="control-label">{isAdmin ? 'Ganti Password' : 'Masukkan Password'}</label>
                                            <div className="input-group mb-3">
                                                <input type="password" id="password" ref={(input) => this.passwordRef = input} className={`form-control form-control-lg ${passwordError && 'is-invalid'}`} onKeyDown={this.handlePasswordInput} onChange={this.handleChange} />
                                                {
                                                    passwordError && <div className="invalid-feedback">{passwordError}</div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                    ) : (
                                        <>
                                            <Table theads={theads} ordering={lockpageOrdering} handleSorting={this.handleSorting}>
                                                {lockTable}
                                            </Table>
                                        </>
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    <Button theme="primary" onClick={() => this.handleOpenPage()}>{isAdmin ? 'Open All' : 'Open'}</Button>
                                </ModalFooter>
                            </Modal>
                        )
                    }
                    {
                        qrmodal && (
                            <ScrollToTop>
                                <div className="messagebox msgbox-qr">
                                    <div className="modal-qr">
                                        <button onClick={this.handleCloseModal} className="btn btn-default btn-cicle btn-lg p-0 pull-right"><i className="mdi mdi-close"></i></button>
                                        <QrReader
                                            delay={500}
                                            onError={this.handleError}
                                            onScan={this.handleScan}
                                            style={{ width: '100%' }}
                                        // facingMode={cameramode}
                                        />
                                    </div>
                                </div>
                                <div className="backdrop"></div>
                            </ScrollToTop>
                        )
                    }

                    {
                        qrmodalrack && (
                            <ScrollToTop>
                                <div className="messagebox msgbox-qr">
                                    <div className="modal-qr">
                                        <button onClick={this.handleCloseModalRack} className="btn btn-default btn-cicle btn-lg p-0 pull-right"><i className="mdi mdi-close"></i></button>
                                        <QrReader
                                            delay={300}
                                            onError={this.handleErrorRack}
                                            onScan={this.handleScanRack}
                                            style={{ width: '100%' }}
                                        // facingMode={cameramode}
                                        />
                                    </div>
                                </div>
                                <div className="backdrop"></div>
                            </ScrollToTop>
                        )
                    }

                    {
                        qrmodaldn && (
                            <ScrollToTop>
                                <div className="messagebox msgbox-qr">
                                    <div className="modal-qr">
                                        <button onClick={this.handleCloseModalDn} className="btn btn-default btn-cicle btn-lg p-0 pull-right"><i className="mdi mdi-close"></i></button>
                                        <QrReader
                                            delay={300}
                                            onError={this.handleErrorDn}
                                            onScan={this.handleScanDn}
                                            style={{ width: '100%' }}
                                        // facingMode={cameramode}
                                        />
                                    </div>
                                </div>
                                <div className="backdrop"></div>
                            </ScrollToTop>
                        )
                    }

                    <Col>
                        <Card small className="mb-4">
                            <CardBody className="p-0 pb-3">
                                <div className="col-md-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {isAdmin && hasLockPage &&
                                                <Button theme="secondary" className="mb-3" onClick={() => this.setState({ ...this.state, kanbanLockData: { ...this.state.kanbanLockData, isModalOpen: true } })}><span className="mdi mdi-lock-open-outline"></span> Unlock Page</Button>}

                                            <div className="form-group">
                                                <label className="control-label">Kanban DN<span className="text-danger">*</span></label>
                                                <div className="input-group mb-3">
                                                    <input maxLength={70} onKeyDown={this.handleKeyDownDn} value={kanban_dn} ref={(input) => { this.kanbanDnInput = input; }} type="text" id="kanban_dn" className={`form-control form-control-lg ${error && error.data.errors.kanban_dn && 'is-invalid'}`} readOnly={kanban_dn_disable || (hasLockPage) ? true : false} onChange={this.handleChange} placeholder="Kanban DN" autoFocus />
                                                    {!hasLockPage &&
                                                        <div className="input-group-prepend">
                                                            <button className="btn btn-outline-secondary" onClick={this.handleScanPopupDn} type="button" id="button-addon1"><i className="mdi mdi-camera mdi-24px"></i></button>
                                                        </div>
                                                    }
                                                    {
                                                        error && error.data.errors.kanban_dn && <div className="invalid-feedback">{error.data.errors.kanban_dn[0]}</div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="control-label">Label Internal<span className="text-danger">*</span></label>
                                                <div className="input-group mb-3">
                                                    <input onKeyDown={this.handleKeyDownKanban} value={kanban} ref={(input) => { this.kanbanInput = input; }} type="text" id="kanban" className={`form-control form-control-lg ${error && error.data.errors.kanban && 'is-invalid'}`} onChange={this.handleChange} placeholder="Kanban" readOnly={(hasLockPage) || disabled ? true : false} />
                                                    {!hasLockPage &&
                                                        <div className="input-group-prepend">
                                                            <button className="btn btn-outline-secondary" onClick={this.handleScanPopup} type="button" id="button-addon1"><i className="mdi mdi-camera mdi-24px"></i></button>
                                                        </div>
                                                    }
                                                    {
                                                        error && error.data.errors.kanban && <div className="invalid-feedback">{error.data.errors.kanban[0]}</div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className={`control-label ${error && error.data.errors.parts}`}>Parts</label>
                                                {
                                                    error && error.data.errors.parts && <div className="text-danger">{error.data.errors.parts[0]}</div>
                                                }
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Part Number</th>
                                                            <th>Part Name</th>
                                                            <th>Qty</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            parts && parts.length > 0 ? parts.map((part, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {part.part_no}
                                                                        </td>
                                                                        <td>
                                                                            {part.part_name}
                                                                        </td>
                                                                        <td>
                                                                            {part.qty}
                                                                        </td>
                                                                        <td>
                                                                            <button onClick={(index) => this.handleRemovePart(index)} className="btn text-danger btn-link btn-sm"><i className="mdi mdi-close"></i></button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : (

                                                                <tr>
                                                                    <td colSpan="4" className="text-center">No data</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>


                                        <div className="col-md-12 text-right">
                                            <hr />
                                            {
                                                this.props.fetching ? (
                                                    <button className="btn btn-secondary btn-disabled" type="submit" disabled><i className="mdi mdi-loading mdi-spin mr-2"></i>Loading...</button>
                                                ) : (
                                                    <button className="btn btn-secondary" type="submit" ref={this.btnSaveRef} onClick={this.handleSave}>Save</button>
                                                )
                                            }
                                            <button className="btn btn-default" type="reset" onClick={this.handleReset}>Reset</button>
                                        </div>
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        saved: state.kanbanADMProd.saved,
        fetching: state.kanbanADMProd.fetching,
        fetched: state.kanbanADMProd.fetched,
        message: state.kanbanADMProd.message,
        error: state.kanbanADMProd.error,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveKanban: data => dispatch(saveKanbanADMProd(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(KanbanADMProd));
