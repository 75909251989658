import React from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { appName, customerStyles, url } from "../global";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import { updatePart, getPart } from "../store/actions/partAction";
import { connect } from "react-redux";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import Error403 from "./Error403";
import AsyncSelect from "react-select/async";
import Axios from "axios";
class EditPart extends React.Component {
  state = {
    code: "",
    name: "",
    stock: "",
    type: "",
    model: "",
    job_number: "",
    marking_id: "",
    position: "",
    std_packing: "",
    id_sap: "",
    customer_id: "",
    customer_name: "",
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  handleChangeSelect = (value, e) => {
    this.setState({
      ...this.state,
      [`${e.name}_id`]: value ? value.value : null,
      [`${e.name}_name`]: value ? value.label : null,
    });
  };

  customerOptions = (inputValue, callback) => {
    Axios.get(`${url}/customer/list`, {
      params: {
        name: inputValue,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((response) => {
      callback(this.filterSupplier(response.data.data));
    });
  };

  filterSupplier = (customers) => {
    const options = customers.map((customer) => {
      return {
        label: `${customer.code} - ${customer.name}`,
        value: customer._id,
      };
    });

    return [...options];
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.updatePart(this.props.match.params.id, this.state);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.saved !== this.props.saved) {
      const { toastManager } = this.props;
      toastManager.add(this.props.message, {
        appearance: "success",
        autoDismiss: true,
      });

      this.props.history.push("/part");
    }

    if (prevProps.error !== this.props.error) {
      if (!this.props.fetched) {
        if (this.props.error) {
          const { toastManager } = this.props;
          toastManager.add(this.props.error.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      }
    }
  };

  componentWillUpdate = (nextProps) => {
    if (nextProps !== this.props) {
      if (nextProps.data) {
        this.setState({
          ...this.state,
          id: nextProps.data._id ? nextProps.data._id : "",
          code: nextProps.data.code ? nextProps.data.code : "",
          name: nextProps.data.name ? nextProps.data.name : "",
          stock: nextProps.data.stock ? nextProps.data.stock : "",
          type: nextProps.data.type ? nextProps.data.type : "",
          model: nextProps.data.model ? nextProps.data.model : "",
          job_number: nextProps.data.job_number ? nextProps.data.job_number : "",
          marking_id: nextProps.data.marking_id ? nextProps.data.marking_id : "",
          position: nextProps.data.position ? nextProps.data.position : "",
          std_packing: nextProps.data.std_packing ? nextProps.data.std_packing : "",
          id_sap: nextProps.data.id_sap ? nextProps.data.id_sap : "",
          customer_id: nextProps.data.customer && nextProps.data.customer._id ? nextProps.data.customer._id : "",
          customer_name: nextProps.data.customer && nextProps.data.customer.name && nextProps.data.customer.code ? nextProps.data.customer.code + " - " + nextProps.data.customer.name : "",
        });
      }
    }
  };

  componentDidMount = () => {
    this.props.getPart(this.props.match.params.id);
  };

  render() {
    const { fetching, error } = this.props;
    const { code, name, stock, type, model, job_number, marking_id, position, id_sap, std_packing, customer_id, customer_name } = this.state;
    if (!localStorage.getItem("token")) return <Redirect to="/login" />;
    if (error && error.status === 500)
      return <Error500 message={error.data.message} />;
    if (error && error.status === 403)
      return <Error403 message={error.data.message} />;
    return (
      <Container fluid className="main-content-container px-4">
        <Loading show={fetching} color="blue" showSpinner={false} />
        <Helmet>
          <title>Edit Part | {appName} </title>
        </Helmet>
        <Row noGutters className="page-header py-4">
          <div className="col-md-8">
            <PageTitle sm="4" title="Edit Part" className="text-sm-left" />
          </div>
          <div className="col-md-4 text-right">
            <Link className="btn btn-secondary" to="/part">
              Back
            </Link>
          </div>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="col-md-12 mt-3">
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">
                            Code / ID <span className="text-danger">*</span>
                          </label>
                          <input
                            value={code}
                            type="text"
                            id="code"
                            className={`form-control ${error &&
                              error.data.errors.code &&
                              "is-invalid"}`}
                            onChange={this.handleChange}
                            placeholder="Part Code"
                          />
                          {error && error.data.errors.code && (
                            <div className="invalid-feedback">
                              {error.data.errors.code[0]}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Stock<span className="text-danger">*</span>
                          </label>
                          <input
                            value={stock}
                            type="text"
                            id="stock"
                            className={`form-control ${error &&
                              error.data.errors.stock &&
                              "is-invalid"}`}
                            onChange={this.handleChange}
                            placeholder="Stock"
                          />
                          {error && error.data.errors.stock && (
                            <div className="invalid-feedback">
                              {error.data.errors.stock[0]}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Model<span className="text-danger">*</span>
                          </label>
                          <input
                            value={model}
                            type="text"
                            id="model"
                            className={`form-control ${error &&
                              error.data.errors.model &&
                              "is-invalid"}`}
                            onChange={this.handleChange}
                            placeholder="Model"
                          />
                          {error && error.data.errors.model && (
                            <div className="invalid-feedback">
                              {error.data.errors.model[0]}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Name<span className="text-danger">*</span>
                          </label>
                          <input
                            value={name}
                            type="text"
                            id="name"
                            className={`form-control ${error &&
                              error.data.errors.name &&
                              "is-invalid"}`}
                            onChange={this.handleChange}
                            placeholder="Part Name"
                          />
                          {error && error.data.errors.name && (
                            <div className="invalid-feedback">
                              {error.data.errors.name[0]}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Type<span className="text-danger">*</span>
                          </label>
                          <input
                            value={type}
                            type="text"
                            id="type"
                            className={`form-control ${error &&
                              error.data.errors.type &&
                              "is-invalid"}`}
                            onChange={this.handleChange}
                            placeholder="Type"
                          />
                          {error && error.data.errors.type && (
                            <div className="invalid-feedback">
                              {error.data.errors.type[0]}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Job Number<span className="text-danger">*</span>
                          </label>
                          <input
                            value={job_number}
                            type="text"
                            id="job_number"
                            className={`form-control ${error &&
                              error.data.errors.job_number &&
                              "is-invalid"}`}
                            onChange={this.handleChange}
                            placeholder="Job Number"
                          />
                          {error && error.data.errors.job_number && (
                            <div className="invalid-feedback">
                              {error.data.errors.job_number[0]}
                            </div>
                          )}
                        </div>

                      </div>

                      <div className="col-md-6">

                        <div className="form-group">
                          <label className="control-label">
                            Marking Id<span className="text-danger">*</span>
                          </label>
                          <input
                            value={marking_id}
                            type="text"
                            id="marking_id"
                            className={`form-control ${error &&
                              error.data.errors.marking_id &&
                              "is-invalid"}`}
                            onChange={this.handleChange}
                            placeholder="Marking ID"
                          />
                          {error && error.data.errors.marking_id && (
                            <div className="invalid-feedback">
                              {error.data.errors.marking_id[0]}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Position
                          </label>
                          <input
                            value={position}
                            type="text"
                            id="position"
                            className={`form-control ${error &&
                              error.data.errors.position &&
                              "is-invalid"}`}
                            onChange={this.handleChange}
                            placeholder="LH/RH"
                          />
                          {error && error.data.errors.position && (
                            <div className="invalid-feedback">
                              {error.data.errors.position[0]}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Standard Packing
                          </label>
                          <input
                            value={std_packing}
                            type="text"
                            id="std_packing"
                            className={`form-control ${error &&
                              error.data.errors.std_packing &&
                              "is-invalid"}`}
                            onChange={this.handleChange}
                            placeholder="Standard Packing"
                          />
                          {error && error.data.errors.std_packing && (
                            <div className="invalid-feedback">
                              {error.data.errors.std_packing[0]}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            ID SAP
                          </label>
                          <input
                            value={id_sap}
                            type="text"
                            id="id_sap"
                            className={`form-control ${error &&
                              error.data.errors.id_sap &&
                              "is-invalid"}`}
                            onChange={this.handleChange}
                            placeholder="ID SAP"
                          />
                          {error && error.data.errors.id_sap && (
                            <div className="invalid-feedback">
                              {error.data.errors.id_sap[0]}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="control-label">
                            Customer
                          </label>
                          <AsyncSelect
                            value={
                              {
                                label: customer_name || "",
                                value: customer_id || ""
                              }
                            }
                            isClearable={true}
                            styles={customerStyles}
                            loadOptions={this.customerOptions}
                            name="customer"
                            placeholder="Type to search ..."
                            onChange={this.handleChangeSelect}
                            cacheOptions
                            defaultOptions
                          />
                          {error && error.data.errors.id_sap && (
                            <div className="invalid-feedback">
                              {error.data.errors.id_sap[0]}
                            </div>
                          )}
                        </div>

                      </div>
                      <div className="col-md-12 text-right">
                        <hr />
                        {this.props.fetching ? (
                          <button
                            className="btn btn-secondary btn-disabled"
                            type="submit"
                            disabled
                          >
                            <i className="mdi mdi-loading mdi-spin mr-2"></i>
                            Loading...
                          </button>
                        ) : (
                          <button className="btn btn-secondary" type="submit">
                            Save Changes
                          </button>
                        )}
                        <button className="btn btn-default" type="reset">
                          Reset
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    saved: state.part.saved,
    fetching: state.part.fetching,
    fetched: state.part.fetched,
    message: state.part.message,
    error: state.part.error,
    data: state.part.part.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePart: (id, data) => dispatch(updatePart(id, data)),
    getPart: (id) => dispatch(getPart(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(EditPart));
