import React, { Component, Fragment } from 'react'
import moment from 'moment'
import socketIOClient from 'socket.io-client'
import { connect } from 'react-redux'
import Table from '../components/table/Table'
import { fetchPart  } from '../store/actions/partAction'
import { Helmet } from 'react-helmet';
import { appName, url, socketurl } from '../global'
import Axios from 'axios'

export class Dashboard extends Component {
    state = {
        date: new Date(),
        parts: []
    }

    componentDidMount() {
        this.getDn()
        const socket = socketIOClient(socketurl)

        socket.on('update part', () => {
            this.getDn()
        })

        this.timerID = setInterval(
          () => this.tick(),
          1000
        );
    }

    getDn = () => {
        Axios.get(`${url}/dashboard/dn`).then(res => {
            this.setState({
                ...this.state,
                parts: res.data.data
            })
        }).catch(error => {
            console.error(error)
        })
    }
    
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
          date: new Date()
        });
    }

    emptyTable = () => {
        let table = []

        // Outer loop to create parent
        for (let i = 0; i < (10 - this.state.parts.length); i++) {
          let children = []
          //Inner loop to create children
          for (let j = 0; j < 7; j++) {
            children.push(<td key={j}></td>)
          }
          //Create the parent and add the children
          table.push(<tr key={i}>{children}</tr>)
        }
        return table
    }

    render() {
        const { date, ordering } = this.state
        const { payload, fetching } = this.props;

        return (
            <Fragment>
                <Helmet>
					<title>Dashboard | {appName} </title>
				</Helmet>
                
                <div className="col-md-12">
                    <div className="row">
                        <table className="table table-dashboard m-0">
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: 'black', borderRight: '1px solid #fff' }} rowSpan="2" className="text-uppercase text-center align-middle"><h1 className="font-weight-bold" style={{ color: '#fff' }}>Achievement rate delivery</h1></th>
                                    <th className="text-center" style={{ backgroundColor: 'black', color: '#fff' }}>{ moment(date).format('DD MMM YYYY') }</th>
                                </tr>
                                <tr>
                                    <th className="text-center" style={{ backgroundColor: 'black', color: 'white' }}>{ moment(date).format('HH:mm:ss') }</th>
                                </tr>
                            </thead>
                        </table>
                        <table className="table table-bordered table-dashboard table-blue m-0 text-uppercase">
                            <thead>
                                <tr>
                                    <th className="text-center">DN No</th>
                                    <th className="text-center" style={{ width: '25%' }}>Part</th>
                                    <th className="text-center">Order Total</th>
                                    <th className="text-center">Target</th>
                                    <th className="text-center">Cycle</th>
                                    <th className="text-center">Actual</th>
                                    <th className="text-center">Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.parts && this.state.parts.map(item => {
                                        return (
                                            <tr key={item._id}>
                                                <td>{item.dn_number}</td>
                                                <td>{item.part_name}</td>
                                                <td className="text-right">{item.order_total}</td>
                                                <td className="text-right">{item.target}</td>
                                                <td className="text-right">{item.cycle}</td>
                                                <td className="text-right">{item.actual}</td>
                                                <td className={`text-center ${item.class}`}>{item.rate}</td>
                                            </tr>
                                        )
                                    })
                                }
                                
                                {
                                    this.emptyTable()
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        payload: state.part.payload,
        error: state.part.error,
		fetching: state.part.fetching,
		message: state.part.message,
		saved: state.part.saved,
		isDeleted: state.part.isDeleted
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchPart : (filter) => dispatch(fetchPart (filter))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
