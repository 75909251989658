import React from "react";
import { Container, Row, Col, Card, CardBody, DatePicker } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { appName, customerStyles, url } from "../global";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import { updateQrcode, getQrcode } from "../store/actions/qrcodeAction";
import { connect } from "react-redux";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import AsyncSelect from "react-select/async";
import Axios from "axios";
import NumberFormat from "react-number-format";
import Error403 from "./Error403";
import {
  getLabelProd,
  onChangeData,
  updateLabelProd,
} from "../store/actions/labelProdActions";
import moment from "moment";
import Select from "react-select";

class EditLabelProd extends React.Component {
  handleChangeDate = (name, e) => {
    const { data, onChangeData } = this.props;
    onChangeData({
      ...data,
      [name]: new Date(e),
    });
  };

  handleChange = (e) => {
    const { data, onChangeData } = this.props;
    onChangeData({
      ...data,
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.updateLabelProd(
      this.props.match.params.id,
      this.props.data,
      (isSuccess, message) => {
        if (isSuccess) {
          this.props.history.push("/label-prod");
          const { toastManager } = this.props;
          toastManager.add(message, {
            appearance: "success",
            autoDismiss: true,
          });
        }
      }
    );
  };

  handleChangeSelect = (value, e) => {
    const { data, onChangeData } = this.props;
    onChangeData({
      ...data,
      [`${e.name}_id`]: value ? value.value : null,
      [`${e.name}_name`]: value ? value.label : null,
    });
  };

  componentDidMount() {
    this.props.getLabelProd(this.props.match.params.id);
  }

  //   componentDidUpdate = (prevProps, prevState) => {
  //     if (prevProps.saved !== this.props.saved) {
  //       const { toastManager } = this.props;
  //       toastManager.add(this.props.message, {
  //         appearance: "success",
  //         autoDismiss: true,
  //       });

  //       this.props.history.push("/qr-code");
  //     }

  //     if (prevProps.error !== this.props.error) {
  //       if (!this.props.fetched) {
  //         if (this.props.error) {
  //           const { toastManager } = this.props;
  //           toastManager.add(this.props.error.data.message, {
  //             appearance: "error",
  //             autoDismiss: true,
  //           });
  //         }
  //       }
  //     }
  //   };

  render() {
    const { fetching, error, data } = this.props;
    console.log(data, "data");
    if (!localStorage.getItem("token")) return <Redirect to="/login" />;
    if (error && error.status === 500)
      return <Error500 message={error.data.message} />;
    if (error && error.status === 403)
      return <Error403 message={error.data.message} />;
    return (
      data && (
        <Container fluid className="main-content-container px-4">
          <Loading show={fetching} color="blue" showSpinner={false} />
          <Helmet>
            <title>Edit Label Prod | {appName} </title>
          </Helmet>
          <Row noGutters className="page-header py-4">
            <div className="col-md-8">
              <PageTitle
                sm="4"
                title="Edit Label Prod"
                className="text-sm-left"
              />
            </div>
            <div className="col-md-4 text-right">
              <Link className="btn btn-secondary" to="/label-prod">
                Back
              </Link>
            </div>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardBody className="p-0 pb-3">
                  <div className="col-md-12 mt-3">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label">
                              Part Number<span className="text-danger">*</span>
                            </label>
                            <input
                              disabled
                              value={data.part_number}
                              type="text"
                              id="part_number"
                              className={`form-control ${error &&
                                error.data.errors.part_number &&
                                "is-invalid"}`}
                              // onChange={this.handleChange}
                              placeholder="Part Number"
                            />
                            {error && error.data.errors.part_number && (
                              <div className="invalid-feedback">
                                {error.data.errors.part_number[0]}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="control-label">
                              Part Name<span className="text-danger">*</span>
                            </label>
                            <input
                              disabled
                              value={data.part_name}
                              type="text"
                              id="part_name"
                              className={`form-control ${error &&
                                error.data.errors.part_name &&
                                "is-invalid"}`}
                              // onChange={this.handleChange}
                              placeholder="Part Name"
                            />
                            {error && error.data.errors.part_name && (
                              <div className="invalid-feedback">
                                {error.data.errors.part_name[0]}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="control-label">
                              Customer ID<span className="text-danger">*</span>
                            </label>
                            <AsyncSelect
                              value={
                                data.customer_id
                                  ? {
                                      label: data.customer_name,
                                      value: data.customer_id,
                                    }
                                  : null
                              }
                              isClearable={true}
                              className={
                                error &&
                                error.data.errors.customer_id &&
                                "is-invalid-select"
                              }
                              styles={customerStyles}
                              loadOptions={customerOptions}
                              name="customer"
                              placeholder="Type to search ..."
                              onChange={this.handleChangeSelect}
                              cacheOptions
                              defaultOptions
                            />
                            {error && error.data.errors.customer_id && (
                              <small className="font-weight-bold text-danger">
                                {error.data.errors.customer_id[0]}
                              </small>
                            )}
                          </div>

                          <div className="d-flex row">
                            <div className="form-group col mr-2">
                              <label className="control-label">Type Part</label>
                              <input
                                disabled
                                value={data.part_type}
                                type="text"
                                id="type"
                                className={`form-control ${error &&
                                  error.data.errors.type &&
                                  "is-invalid"}`}
                                // onChange={this.handleChange}
                                placeholder="Type Part"
                              />
                              {error && error.data.errors.type && (
                                <div className="invalid-feedback">
                                  {error.data.errors.type[0]}
                                </div>
                              )}
                            </div>
                            <div className="form-group col">
                              <label className="control-label">
                                Model Part
                              </label>
                              <input
                                disabled
                                value={data.part_model}
                                type="text"
                                id="model"
                                className={`form-control ${error &&
                                  error.data.errors.model &&
                                  "is-invalid"}`}
                                // onChange={this.handleChange}
                                placeholder="Model Part"
                              />
                              {error && error.data.errors.model && (
                                <div className="invalid-feedback">
                                  {error.data.errors.type[0]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="d-flex row">
                            <div className="form-group col mr-2">
                              <label className="control-label">
                                Machine ID
                              </label>
                              <Select
                                options={[
                                  { value: "A", label: "Aida" },
                                  { value: "F", label: "Fukui" },
                                ]}
                                value={
                                  data.machine_id
                                    ? {
                                        label: data.machine_name,
                                        value: data.machine_id,
                                      }
                                    : null
                                }
                                style={customerStyles}
                                name="machine"
                                onChange={this.handleChangeSelect}
                              />
                              {error && error.data.errors.machine_id && (
                                <div className="invalid-feedback">
                                  {error.data.errors.machine_id[0]}
                                </div>
                              )}
                            </div>
                            <div className="form-group col">
                              <label className="control-label">Dies ID</label>
                              <Select
                                options={[
                                  { value: "L", label: "DPM" },
                                  { value: "A", label: "AHM" },
                                ]}
                                value={
                                  data.dies_id
                                    ? {
                                        value: data.dies_id,
                                        label: data.dies_name,
                                      }
                                    : null
                                }
                                style={customerStyles}
                                name="dies"
                                onChange={this.handleChangeSelect}
                              />
                              {error && error.data.errors.dies_id && (
                                <div className="invalid-feedback">
                                  {error.data.errors.dies_id[0]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="control-label">Maker</label>
                            <Select
                              value={
                                data.maker_id
                                  ? {
                                      value: data.maker_id,
                                      label: data.maker_name,
                                    }
                                  : null
                              }
                              style={customerStyles}
                              options={[
                                { value: "POSCO", label: "POSCO" },
                                { value: "JFE", label: "JFE" },
                                { value: "KNSS", label: "KNSS" },
                              ]}
                              name="maker"
                              onChange={this.handleChangeSelect}
                            />
                            {error && error.data.errors.maker_d && (
                              <div className="invalid-feedback">
                                {error.data.errors.maker_id[0]}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="control-label">Thickness</label>
                            <input
                              value={data.thickness}
                              type="text"
                              id="thickness"
                              className={`form-control ${error &&
                                error.data.errors.thickness &&
                                "is-invalid"}`}
                              onChange={this.handleChange}
                              placeholder="Thickness"
                            />
                            {error && error.data.errors.thickness && (
                              <div className="invalid-feedback">
                                {error.data.errors.thickness[0]}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="control-label">Shift</label>
                            <Select
                              value={
                                data.shift_id
                                  ? {
                                      value: data.shift_id,
                                      label: data.shift_name,
                                    }
                                  : null
                              }
                              style={customerStyles}
                              options={[
                                { value: "1", label: "S1" },
                                { value: "2", label: "S2" },
                                { value: "3", label: "S3" },
                              ]}
                              name="shift"
                              onChange={this.handleChangeSelect}
                            />
                            {error && error.data.errors.shift_id && (
                              <div className="invalid-feedback">
                                {error.data.errors.shift_id[0]}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="control-label">
                              Qty<span className="text-danger">*</span>
                            </label>
                            <NumberFormat
                              decimalSeparator="."
                              thousandSeparator=","
                              value={data.qty}
                              type="text"
                              id="qty"
                              className={`form-control ${error &&
                                error.data.errors.qty &&
                                "is-invalid"}`}
                              onChange={this.handleChange}
                              placeholder="Qty"
                            />
                            {error && error.data.errors.qty && (
                              <div className="invalid-feedback">
                                {error.data.errors.qty[0]}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="control-label">
                              Lot ID<span className="text-danger">*</span>
                            </label>
                            <input
                              value={data.lot_id}
                              disabled
                              type="text"
                              id="lot_id"
                              className={`form-control ${error &&
                                error.data.errors.lot_id &&
                                "is-invalid"}`}
                              // onChange={this.handleChange}
                              placeholder="Lot ID"
                              maxLength={30}
                            />
                            {error && error.data.errors.lot_id && (
                              <div className="invalid-feedback">
                                {error.data.errors.lot_id[0]}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="control-label">
                              Inspect date<span className="text-danger">*</span>
                            </label>
                            <DatePicker
                              className={`form-control ${error &&
                                error.data.errors.inspec_date &&
                                "is-invalid"}`}
                              onChange={(e) =>
                                this.handleChangeDate("inspec_date", e)
                              }
                              placeholderText="mm/dd/YYYY"
                              selected={data.inspec_date}
                              id="inspec_date"
                            />
                            {error && error.data.errors.inspec_date && (
                              <div className="invalid-feedback">
                                {error.data.errors.inspec_date[0]}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="control-label">
                              Production date
                              <span className="text-danger">*</span>
                            </label>
                            <DatePicker
                              className={`form-control ${error &&
                                error.data.errors.prod_date &&
                                "is-invalid"}`}
                              onChange={(e) =>
                                this.handleChangeDate("prod_date", e)
                              }
                              placeholderText="mm/dd/YYYY"
                              selected={data.prod_date}
                              id="prod_date"
                            />
                            {error && error.data.errors.prod_date && (
                              <div className="invalid-feedback">
                                {error.data.errors.prod_date[0]}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="control-label">
                              Copies<span className="text-danger">*</span>
                            </label>
                            <input
                              value={data.copies}
                              type="number"
                              id="copies"
                              className={`form-control ${error &&
                                error.data.errors.copies &&
                                "is-invalid"}`}
                              onChange={this.handleChange}
                              placeholder="Copies"
                            />
                            {error && error.data.errors.copies && (
                              <div className="invalid-feedback">
                                {error.data.errors.copies[0]}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 text-right">
                          <hr />
                          {this.props.fetching ? (
                            <button
                              className="btn btn-secondary btn-disabled"
                              disabled
                              type="button"
                            >
                              <i className="mdi mdi-loading mdi-spin mr-2"></i>
                              Loading...
                            </button>
                          ) : (
                            <button className="btn btn-secondary" type="submit">
                              Save Changes
                            </button>
                          )}
                          <button className="btn btn-default" type="reset">
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    );
  }
}

const appendSupplier = (filteredOptions) => {
  return [...filteredOptions];
};

const filterSupplier = (customers) => {
  const options = customers.map((customer) => {
    return {
      label: `${customer.code} - ${customer.name}`,
      value: customer._id,
    };
  });

  return appendSupplier(options);
};

const customerOptions = (inputValue, callback) => {
  Axios.get(`${url}/customer/list`, {
    params: {
      name: inputValue,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => {
    callback(filterSupplier(response.data.data));
  });
};

const mapStateToProps = (state) => {
  return {
    ...state,
    fetching: state.loading.fetching,
    error: state.labelProduct.error,
    data: state.labelProduct.labelProductData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQrcode: (id, data) => dispatch(updateQrcode(id, data)),
    getLabelProd: (id) => dispatch(getLabelProd(id)),
    onChangeData: (data) => dispatch(onChangeData(data)),
    updateLabelProd: (id, data, cb) => dispatch(updateLabelProd(id, data, cb)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(EditLabelProd));
