import React, { createRef, Fragment } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { appName, url } from "../global";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import Loading from "react-loading-bar";
import Error500 from "./Error500";
import Error403 from "./Error403";
import Axios from "axios";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { saveDn } from "../store/actions/preparationDeliveryActions";
import { connect } from "react-redux";

class ScanPreparationDelivery extends React.Component {
  constructor(props) {
    super(props);
    this.dnRef = createRef();
    this.labelProdRef = createRef();
  }

  state = {
    code: "",
    delivery_note_details: "",
    label_prod: "",
    warehouse_name: null,
    warehouse_id: null,
    kanban_id: null,
    fetching: false,
    error: null,
    payload: {},
    parts: [],
    labelDetail: {},
    supplier: {}
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value,
    });
  };

  handleKeyDown = (e) => {
    const { toastManager } = this.props;
    
    if (e.key === "Enter") {
      if (e.target.id === "delivery_note_details") {
        this.getKanban(this.state.delivery_note_details);
        
      } else {
        // const value = this.state.label_prod;
        // const valueParsed = value.split("|");
        // console.log(valueParsed);
        // const part_number = valueParsed[0];
        // const supplier_id = valueParsed[1];
        // const qty = valueParsed[2];
        // const otherParsed = valueParsed[3].split("/");
        // const prod_date = moment(otherParsed[0], "DD/MM/YY");
        // const shift_name = otherParsed[1];
        // const lot_coil_id = otherParsed[2].replace("-", "");

        const value = this.state.label_prod;
        const valueParsed = value.split("/");
        console.log(valueParsed);
        const prod_date = moment(valueParsed[0], "DD/MM/YY");
        console.log(prod_date);
        const lot_coil_id = valueParsed[1].replace("-", "");
        console.log(lot_coil_id);
        const shift_id = valueParsed[2].slice(0,1);
        console.log(shift_id);
        const machine_id = valueParsed[2].slice(1,2);
        const dies_id = valueParsed[2].slice(2,3);
        const maker_id = valueParsed[2].slice(3,4);
        const sequence = valueParsed[2].slice(4);
        
        this.getLabelDetail(lot_coil_id);

        setTimeout(() => {
          if (valueParsed.length === 3 ) {
            const compare = this.state.payload.details.find(
              (detail) => detail.supplier_part_number === this.state.labelDetail.part_number
            );
            console.log(this.state.supplier)
            if (compare) {
              if (this.state.payload.supplier_id === this.state.supplier.code) {
                this.setState({
                  ...this.state,
                  label_prod: "",
                  parts: [
                    ...this.state.parts,
                    {
                      part_number: this.state.labelDetail.part_number,
                      qty: parseInt(this.state.labelDetail.qty),
                      qrcode: value,
                      prod_date: prod_date.format("YYYY-MM-DD"),
                      shift_name: this.state.labelDetail.shift_name,
                      lot_coil_id,
                    },
                  ],
                });
              } else {
                this.setState({
                  ...this.state,
                  label_prod: "",
                });
  
                toastManager.add("Supplier ID not match!", {
                  appearance: "error",
                  autoDismiss: true,
                });
                console.log("Supplier ID not match!");
              }
            } else {
              this.setState({
                ...this.state,
                label_prod: "",
              });
  
              toastManager.add("Item not match!", {
                appearance: "error",
                autoDismiss: true,
              });
              console.log("Item not match!");
            }
          } else {
            this.setState({
              ...this.state,
              label_prod: "",
            });
  
            toastManager.add("Bad QR Code!", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }, 1000);
        
        // setTimeout(function() {
        // }, 2000);


        // const part_number = valueParsed[0];
        // const supplier_id = valueParsed[1];
        // const qty = valueParsed[2];
        // const otherParsed = valueParsed[3].split("/");


        // if (valueParsed.length === 4 && otherParsed.length === 3) {
        //   const compare = this.state.payload.details.find(
        //     (detail) => detail.supplier_part_number === part_number
        //   );
        //   console.log(this.state.payload)
        //   console.log(compare)
        //   if (compare) {
        //     if (this.state.payload.supplier_id === supplier_id) {
        //       this.setState({
        //         ...this.state,
        //         label_prod: "",
        //         parts: [
        //           ...this.state.parts,
        //           {
        //             part_number,
        //             qty: parseInt(qty),
        //             qrcode: value,
        //             prod_date: prod_date.format("YYYY-MM-DD"),
        //             shift_name,
        //             lot_coil_id,
        //           },
        //         ],
        //       });
        //     } else {
        //       this.setState({
        //         ...this.state,
        //         label_prod: "",
        //       });

        //       toastManager.add("Supplier ID not match!", {
        //         appearance: "error",
        //         autoDismiss: true,
        //       });
        //       console.log("Supplier ID not match!");
        //     }
        //   } else {
        //     this.setState({
        //       ...this.state,
        //       label_prod: "",
        //     });

        //     toastManager.add("Item not match!", {
        //       appearance: "error",
        //       autoDismiss: true,
        //     });
        //     console.log("Item not match!");
        //   }
        // } else {
        //   this.setState({
        //     ...this.state,
        //     label_prod: "",
        //   });

        //   toastManager.add("Bad QR Code!", {
        //     appearance: "error",
        //     autoDismiss: true,
        //   });
        // }
      }
    }
  };


  getKanban = (delivery_note_details) => {
    Axios.get(`${url}/prep-del/get-dn`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        dn_no: delivery_note_details,
      },
    })
      .then((res) => {
        this.dnRef.current.setAttribute("disabled", true);
        this.labelProdRef.current.removeAttribute("disabled");
        this.labelProdRef.current.focus();
        this.setState({
          ...this.state,
          payload: res.data.data,
          error: null,
        });
      })
      .catch((err) => {
        this.props.toastManager.add(err.response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });

        this.setState({
          ...this.state,
          payload: {},
          delivery_note_details: "",
          error: err.response,
        });
      });
  };


  getLabelDetail = (lot_coil_id) => {
    Axios.get(`${url}/label-prod/get-detail/${lot_coil_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    })
    .then((res) => {
      this.setState({
        ...this.state,
        labelDetail: res.data.data,
        supplier: res.data.supplier,
      });
  
      console.log(this.state.labelDetail)
      
    })
      .catch((err) => {
        this.props.toastManager.add(err.response.data.message, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  findParts = (part_number) => {
    const findIt = this.state.parts.filter(
      (part) => part.part_number === part_number
    );

    if (findIt && findIt.length > 0) {
      return findIt.reduce((total, num) => num.qty + total, 0);
    } else {
      return 0;
    }
  };

  checkParts = (part_number) => {
    const findDetails = this.state.payload.details.find(
      (part) => part.supplier_part_number === part_number
    );
    const findPart = this.state.parts.filter(
      (part) => part.part_number === part_number
    );

    const sumPart = findPart.reduce((total, num) => total + num.qty, 0);

    if (findDetails && findPart) {
      if (parseInt(findDetails.qty_dn) === sumPart) {
        return <span className="text-success">OK</span>;
      } else {
        if (parseInt(findDetails.qty_dn) < sumPart) {
          return (
            <Fragment>
              <span
                className="text-danger"
                data-tip="Qty DN is less than Qty Kanban"
              >
                Not OK
              </span>
              <ReactTooltip />
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <span
                className="text-danger"
                data-tip="Qty DN is greather than Qty Kanban"
              >
                Not OK
              </span>
              <ReactTooltip />
            </Fragment>
          );
        }
      }
    } else {
      return (
        <Fragment>
          <span
            className="text-danger"
            data-tip="Qty DN is greather than Qty Kanban"
          >
            Not OK
          </span>
          <ReactTooltip />
        </Fragment>
      );
    }
  };

  handleReset = (part_number) => {
    const newParts = this.state.parts.filter(
      (part) => part.part_number !== part_number
    );

    this.setState({
      ...this.state,
      parts: newParts,
    });
  };

  handleResetAll = () => {
    this.dnRef.current.removeAttribute("disabled");
    this.dnRef.current.focus();
    this.labelProdRef.current.setAttribute("disabled", "true");

    this.setState({
      ...this.state,
      payload: {},
      parts: [],
      label_prod: "",
      delivery_note_details: "",
    });
  };

  handleSave = () => {
    const { delivery_note_details, parts, payload } = this.state;
    const { toastManager, history } = this.props;

    this.props.saveDn(
      {
        dn_number: delivery_note_details,
        details: parts,
        _id: payload._id,
      },
      (isSuccess) => {
        if (isSuccess) {
          toastManager.add("DN linked succesfully!", {
            appearance: "success",
            autoDismiss: true,
          });
          history.push("/preparation-delivery");
        }
      }
    );
  };

  render() {
    const {
      delivery_note_details,
      error,
      fetching,
      payload,
      label_prod,
      parts,
    } = this.state;

    console.log(parts, "parts");

    if (!localStorage.getItem("token")) return <Redirect to="/login" />;
    if (error && error.status === 500)
      return <Error500 message={error.data.message} />;
    if (error && error.status === 403)
      return <Error403 message={error.data.message} />;
    return (
      <Container fluid className="main-content-container px-4">
        <Loading show={fetching} color="blue" showSpinner={false} />
        <Helmet>
          <title>Scan Preparation Delivery | {appName} </title>
        </Helmet>
        <Row noGutters className="page-header py-4">
          <div className="col-md-10">
            <PageTitle
              sm="12"
              title="Scan Preparation Delivery"
              className="text-sm-left"
            />
          </div>
          <div className="col-md-2 text-right">
            <Link className="btn btn-secondary" to="/preparation-delivery">
              Back
            </Link>
          </div>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="" className="control-label">
                          DN Number
                        </label>
                        <input
                          ref={this.dnRef}
                          onKeyDown={this.handleKeyDown}
                          value={delivery_note_details}
                          onChange={this.handleChange}
                          id="delivery_note_details"
                          type="text"
                          className={`form-control form-control-lg ${error &&
                            error.data.errors.delivery_note_details_id &&
                            "is-invalid "}`}
                          placeholder="Scan DN here"
                        />
                        {error &&
                          error.data.errors.delivery_note_details_id && (
                            <div className="invalid-feedback">
                              {error.data.errors.delivery_note_details_id[0]}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="" className="control-label">
                          Scan Label Prod
                        </label>
                        <input
                          ref={this.labelProdRef}
                          onKeyDown={this.handleKeyDown}
                          value={label_prod}
                          onChange={this.handleChange}
                          id="label_prod"
                          type="text"
                          className={`form-control form-control-lg ${error &&
                            error.data.errors.label_prod &&
                            "is-invalid "}`}
                          placeholder="Scan Label Prod here"
                          disabled
                        />
                        {error && error.data.errors.label_prod && (
                          <div className="invalid-feedback">
                            {error.data.errors.label_prod[0]}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4 mb-5">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <td className="no-wrap">Part Number</td>
                          <td className="no-wrap">Part Name</td>
                          <td className="no-wrap">Qty DN</td>
                          <td className="no-wrap">PO Item</td>
                          <td className="no-wrap">Status</td>
                        </tr>
                      </thead>
                      <tbody>
                        {payload &&
                        payload.details &&
                        payload.details.length > 0 ? (
                          payload.details.map((detail) => {
                            return (
                              <tr key={detail._id}>
                                <td className="no-wrap">
                                  {detail.supplier_part_number}
                                </td>
                                <td className="no-wrap">
                                  {detail.part_number_desc}
                                </td>
                                <td className="no-wrap">
                                  {this.findParts(detail.supplier_part_number)}/
                                  {detail.qty_dn}
                                </td>
                                <td className="no-wrap">{detail.po_item}</td>
                                <td className="no-wrap">
                                  {this.checkParts(detail.supplier_part_number)}
                                  {this.findParts(detail.supplier_part_number) >
                                    1 && (
                                    <button
                                      className="btn btn-outline-primary ml-2 btn-sm"
                                      onClick={() =>
                                        this.handleReset(
                                          detail.supplier_part_number
                                        )
                                      }
                                    >
                                      <i className="mdi mdi-undo" />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={this.handleResetAll}
                  >
                    <i className="mdi mdi-undo" /> Reset
                  </button>
                  {parts &&
                  parts.length > 0 &&
                  parts.reduce((total, num) => num.qty + total, 0) ===
                    payload.details.reduce(
                      (total, num) => num.qty_dn + total,
                      0
                    ) ? (
                    <button
                      className="btn btn-secondary"
                      onClick={this.handleSave}
                      disabled={this.props.fetching ? true : false}
                    >
                      {fetching ? (
                        <Fragment>
                          <i className="mdi mdi-loading mdi-spin" /> Loading...{" "}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <i className="mdi mdi-save" /> Save DN{" "}
                        </Fragment>
                      )}
                    </button>
                  ) : (
                    <Fragment>
                      <span data-tip="Qty DN and Qty Kanban must be equal">
                        <button className="btn btn-secondary" disabled>
                          <i className="mdi mdi-save" /> Save DN
                        </button>
                      </span>
                      <ReactTooltip />
                    </Fragment>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    fetching: state.loading.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveDn: (data, cb) => dispatch(saveDn(data, cb)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(ScanPreparationDelivery));
